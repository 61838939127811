import _ from 'underscore';

export const dateToStartOfDay = (date) => {
    if (!_.isDate(date)) return date;

    date.setHours(0, 0, 0, 0);

    return date;
};

export const dateToEndOfDay = (date) => {
    if (!_.isDate(date)) return date;

    date.setHours(23, 59, 59, 0);

    return date;
};

export const dateToSecondEpoch = (date) => {
    if (!_.isDate(date)) return date;

    return parseInt(date.getTime() / 1000, 10);
};

export const addDay = (date, days = 1) => {
    if (!_.isDate(date) || !_.isNumber(days)) return date;

    const newDate = new Date(date.getTime());
    newDate.setDate(newDate.getDate() + days);

    return newDate;
};
