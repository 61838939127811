import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import { connect } from 'react-redux';
import { MeasureRequestHandler } from '@measure-iot/api';
import {
    Col,
    Row,
} from 'reactstrap';

import MeasurePropTypes from '../../../propTypes';
import * as Actions from '../../../actions';

import MCard from '../mcard_base';
import MSwitch from '../../mforms/mswitch';

class MContacts extends PureComponent {
    constructor(props) {
        super(props);

        this.cancelToken = new MeasureRequestHandler();
        this.handleSuscriptionUpdate = this.handleSuscriptionUpdate.bind(this);
    }

    componentDidMount() {
        this.triggerGetUserContacts();
    }

    componentDidUpdate(prevProps) {
        const { hubId } = this.props;

        if (hubId !== prevProps.hubId) {
            this.triggerGetUserContacts();
        }
    }

    async triggerGetUserContacts() {
        const {
            userId,
            hubId,
            triggerClearContacts,
            triggerGetUserContacts,
            triggerGetHubContacts,
        } = this.props;

        await triggerClearContacts();
        await triggerGetUserContacts(userId, this.cancelToken);
        await triggerGetHubContacts(hubId, this.cancelToken);
    }

    handleSuscriptionUpdate(event) {
        const {
            hubId,
            triggerUnsubscribeHubContact,
            triggerSubscribeHubContact,
        } = this.props;

        const { checked, id: contactId } = event.target;

        if (checked) {
            triggerSubscribeHubContact(hubId, contactId, this.cancelToken);
        } else {
            triggerUnsubscribeHubContact(hubId, contactId, this.cancelToken);
        }
    }

    renderSubscribedContacts() {
        const { userId, contacts } = this.props;

        return contacts.map((contact) => {
            const {
                id,
                channel,
                username,
                subscribed,
                userId: contactUserId,
            } = contact;

            return (
                <Col xs={6} key={id}>
                    <MSwitch
                        id={id}
                        label={username}
                        checked={subscribed}
                        disabled={contactUserId !== userId}
                        onChange={this.handleSuscriptionUpdate}
                        texts={channel.toUpperCase()}
                    />
                </Col>
            );
        });
    }

    render() {
        return (
            <MCard title="Notificaciones" subtitle="en caso de alertas" expandable>
                En caso de levantarse alguna alerta, los contactos activados serán notificados.<br /><br />
                <Row>{ this.renderSubscribedContacts() }</Row>
            </MCard>
        );
    }
}

const mapStateToProps = (state) => {
    const { currentUser } = state.users;
    const { contacts } = state.contacts;

    const newProps = {};

    newProps.userId = currentUser.id;

    if (_.isObject(contacts) && !_.isEmpty(contacts)) {
        newProps.contacts = Object.values(contacts);

        newProps.contacts.sort((lhs, rhs) => {
            return lhs.username.localeCompare(rhs.username);
        });
    }

    return newProps;
};

MContacts.propTypes = {
    hubId: PropTypes.string.isRequired,

    // Redux
    userId: PropTypes.string.isRequired,
    contacts: MeasurePropTypes.USER_CONTACTS,

    // Actions
    triggerClearContacts: PropTypes.func.isRequired,
    triggerGetUserContacts: PropTypes.func.isRequired,
    triggerGetHubContacts: PropTypes.func.isRequired,
    triggerSubscribeHubContact: PropTypes.func.isRequired,
    triggerUnsubscribeHubContact: PropTypes.func.isRequired,
};

MContacts.defaultProps = {
    contacts: [],
};

export default connect(mapStateToProps, Actions)(MContacts);
