import { isEmpty } from 'underscore';

import { INPUT_TYPES } from '../index';

const FIELDS = [
    {
        jsonKey: 'name',
        label: 'Sensor Name',
        type: INPUT_TYPES.text,
        defaultValue: 'My Great Sensor',
        onChangeErrorMessage: 'Invalid data',
        onChangeValidation: (value) => {
            return !isEmpty(value);
        },
        onBlurErrorMessage: 'OB Invalid data',
        onBlurValidation: (value) => {
            return !isEmpty(value);
        },
        onSubmitErrorMessage: 'OS Invalid data',
        onSubmitValidation: (value) => {
            return !isEmpty(value);
        },
    },
    {
        jsonKey: 'additional_info',
        label: 'Sensor Aditional Info',
        type: INPUT_TYPES.textarea,
    },
    {
        jsonKey: 'gate_type',
        label: 'Sensor Gate Type',
        type: INPUT_TYPES.select,
        options: [
            {
                label: 'Input',
                value: 'input',
            },
            {
                label: 'Output',
                value: 'output',
            },
        ],
        onChangeErrorMessage: 'Invalid data',
        onChangeValidation: (value) => {
            return !isEmpty(value);
        },
        onBlurErrorMessage: 'OB Invalid data',
        onBlurValidation: (value) => {
            return !isEmpty(value);
        },
        onSubmitErrorMessage: 'OS Invalid data',
        onSubmitValidation: (value) => {
            return !isEmpty(value);
        },
    },
    {
        jsonKey: 'gate_id',
        label: 'Sensor Gate ID',
        type: INPUT_TYPES.select,
        options: [
            {
                label: 'S01',
                value: 'S01',
            },
            {
                label: 'S02',
                value: 'S02',
            },
            {
                label: 'S03',
                value: 'S03',
            },
            {
                label: 'S04',
                value: 'S04',
            },
            {
                label: 'S05',
                value: 'S05',
            },
            {
                label: 'S06',
                value: 'S06',
            },
            {
                label: 'S07',
                value: 'S07',
            },
            {
                label: 'L01',
                value: 'L01',
            },
        ],
        onChangeErrorMessage: 'Invalid data',
        onChangeValidation: (value) => {
            return !isEmpty(value);
        },
        onBlurErrorMessage: 'OB Invalid data',
        onBlurValidation: (value) => {
            return !isEmpty(value);
        },
        onSubmitErrorMessage: 'OS Invalid data',
        onSubmitValidation: (value) => {
            return !isEmpty(value);
        },
    },
    {
        jsonKey: 'status',
        label: 'Sensor Status',
        type: INPUT_TYPES.select,
        options: [
            {
                label: 'Enabled',
                value: 'enabled',
            },
            {
                label: 'Disabled',
                value: 'disabled',
            },
        ],
        onChangeErrorMessage: 'Invalid data',
        onChangeValidation: (value) => {
            return !isEmpty(value);
        },
        onBlurErrorMessage: 'OB Invalid data',
        onBlurValidation: (value) => {
            return !isEmpty(value);
        },
        onSubmitErrorMessage: 'OS Invalid data',
        onSubmitValidation: (value) => {
            return !isEmpty(value);
        },
    },
    {
        label: 'Data Variable',
    },
    {
        jsonKey: 'data_variable->min',
        label: 'Min',
        defaultValue: '-1',
        help: 'The minimum value that you should receive from the sensor. Note: value after calibration.',
        type: INPUT_TYPES.number,
    },
    {
        jsonKey: 'data_variable->max',
        label: 'Max',
        defaultValue: '-1',
        help: 'The maxmimum value that you should receive from the sensor. Note: value after calibration.',
        type: INPUT_TYPES.number,
    },
    {
        jsonKey: 'data_variable->unit',
        label: 'Unit',
        defaultValue: ' ',
        help: 'The value\'s unit displayed in charts',
        type: INPUT_TYPES.text,
        onChangeErrorMessage: 'Invalid data',
        onChangeValidation: (value) => {
            return !isEmpty(value);
        },
        onBlurErrorMessage: 'OB Invalid data',
        onBlurValidation: (value) => {
            return !isEmpty(value);
        },
        onSubmitErrorMessage: 'OS Invalid data',
        onSubmitValidation: (value) => {
            return !isEmpty(value);
        },
    },
    {
        jsonKey: 'data_variable->name',
        label: 'Chart type',
        type: INPUT_TYPES.select,
        options: [
            {
                label: 'Posición',
                value: 'POSITION',
            },
            {
                label: 'Electricidad',
                value: 'ELECTRICITY',
            },
            {
                label: 'Horómetro',
                value: 'HOURMETER',
            },
            {
                label: 'Binario',
                value: 'BINARY',
            },
            {
                label: 'Temperatura',
                value: 'TEMPERATURE',
            },
            {
                label: 'Humedad',
                value: 'Humidity',
            },
            {
                label: 'Presión',
                value: 'PRESSURE',
            },
        ],
        onChangeErrorMessage: 'Invalid data',
        onChangeValidation: (value) => {
            return !isEmpty(value);
        },
        onBlurErrorMessage: 'OB Invalid data',
        onBlurValidation: (value) => {
            return !isEmpty(value);
        },
        onSubmitErrorMessage: 'OS Invalid data',
        onSubmitValidation: (value) => {
            return !isEmpty(value);
        },
    },
    {
        label: 'Alert Thresholds',
    },
    {
        jsonKey: 'thresholds->minValue',
        label: 'Min Value',
        help: 'If the sensor sends a value under this threshold, a notification will be sent.',
        type: INPUT_TYPES.number,
    },
    {
        jsonKey: 'thresholds->maxValue',
        label: 'Max Value',
        help: 'If the sensor sends a value over this threshold, a notification will be sent.',
        type: INPUT_TYPES.number,
    },
    {
        jsonKey: 'device_id',
        label: 'Device ID',
        type: INPUT_TYPES.text,
        onChangeErrorMessage: 'Invalid data',
        onChangeValidation: (value) => {
            return !isEmpty(value);
        },
        onBlurErrorMessage: 'OB Invalid data',
        onBlurValidation: (value) => {
            return !isEmpty(value);
        },
        onSubmitErrorMessage: 'OS Invalid data',
        onSubmitValidation: (value) => {
            return !isEmpty(value);
        },
    },
];

export default FIELDS;
