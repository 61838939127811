import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

class HeaderButton extends PureComponent {
    render() {
        const {
            children,
            tag,
            to,
            onClick,
            id,
            float,
            active,
        } = this.props;

        return (
            <Button
                id={id}
                onClick={onClick}
                color="primary"
                outline
                className={`float-${float}`}
                size="sm"
                tag={tag}
                to={to}
                style={{ marginLeft: '0.5rem' }}
                active={active}
            >
                {children}
            </Button>
        );
    }
}

HeaderButton.propTypes = {
    children: PropTypes.node,
    tag: PropTypes.elementType,
    to: PropTypes.string,
    onClick: PropTypes.func,
    id: PropTypes.string,
    float: PropTypes.oneOf([
        'left',
        'right',
    ]),
    active: PropTypes.bool,
};

HeaderButton.defaultProps = {
    children: null,
    tag: React.ReactType,
    onClick: React.ReactType,
    to: null,
    id: undefined,
    float: 'right',
    active: false,
};

export default HeaderButton;
