import TYPES from './types';

export const dispatchSensorUpdate = (dispatch, id, data) => {
    dispatch({
        type: TYPES.SET,
        payload: {
            sensorId: id,
            sensor: data,
        },
    });
};
