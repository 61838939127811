import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isString } from 'underscore';

import {
    FaClipboard,
    FaMinus,
    FaPlus,
} from 'react-icons/fa';

import './style.scss';

class MJsonTextarea extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            expanded: true,
            textCopied: false,
        };

        this.toggleButton = this.toggleButton.bind(this);
        this.copyCodeToClipboard = this.copyCodeToClipboard.bind(this);
    }

    async copyCodeToClipboard() {
        const { jsonPayload } = this.props;

        await navigator.clipboard.writeText(jsonPayload);

        this.setState({
            textCopied: true,
        });

        setTimeout(() => {
            this.setState({
                textCopied: false,
            });
        }, 4000);
    }

    toggleButton() {
        const { expanded } = this.state;

        this.setState({
            expanded: !expanded,
        });
    }

    renderExpandButton(expanded, rows) {
        if (expanded && rows <= 1) return null;

        return (
            <button
                type="button"
                className="expand-button"
                onClick={this.toggleButton}
            >
                {
                    expanded ? <FaMinus /> : <FaPlus />
                }
            </button>
        );
    }

    renderClipboardButton(rows) {
        const className = rows === 1 ? 'copy-button copy-button-single-row' : 'copy-button';
        return (
            <button
                type="button"
                className={className}
                onClick={this.copyCodeToClipboard}
            >
                <FaClipboard />
            </button>
        );
    }

    render() {
        const { jsonPayload, highlight } = this.props;
        const { expanded, textCopied } = this.state;

        const data = isEmpty(jsonPayload) ? 'No data' : jsonPayload;

        const stringJson = isString(data) ? data : JSON.stringify(data, null, 4);

        const rows = expanded ? (stringJson.match(/\n/g) || []).length + 1 : 2;

        let className = 'json-container';
        if (highlight) {
            className = `${className} json-container-highlight`;
        }

        return (
            <div className="json-wrapper">
                <textarea
                    disabled
                    className={className}
                    rows={rows}
                    value={stringJson}
                />
                {
                    this.renderExpandButton(expanded, rows)
                }
                {
                    this.renderClipboardButton(rows)
                }
                {
                    textCopied && <p className="clipboard-text">El texto se copió al portapapeles.</p>
                }
            </div>
        );
    }
}

MJsonTextarea.propTypes = {
    jsonPayload: PropTypes.string.isRequired,
    highlight: PropTypes.bool,
};

MJsonTextarea.defaultProps = {
    highlight: false,
};

export default MJsonTextarea;
