import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';

import MeasureVerticalSpacer from '../../general/vertical_spacer';

import StagesLarge from '../../../assets/img/LandingStages/StagesLarge.svg';

import './style_section_stages.sass';

class Section extends PureComponent {
    render() {
        const { id } = this.props;
        return (
            <div id={id} className="dig-section">
                <Row>
                    <Col>
                        <MeasureVerticalSpacer height="1rem" className="d-block d-md-none" />
                        <Row>
                            <Col xs={12} md={6}>
                                <div className="title-container">
                                    <h3 className="section-title text-center">Comienza a adoptar la Transformación Digital IoT</h3>
                                    <p className="section-paragraph text-center">
                                        Hasta alcanzar todo el potencial que <strong>Measure</strong> puede ofrecer.
                                    </p>
                                    <MeasureVerticalSpacer height="1rem" className="d-none d-md-block" />
                                </div>
                            </Col>
                            <Col xs={12} md={6} className="image-container">
                                <img src={StagesLarge} alt="Data Forwarding: carga datos a nuestra nube; Monitoreo Online: visualiza en tiempo real; Data Analytics: toma decisiones informadas; Modelamiento y predicción: reacciona rápidamente; Automatización y control: define procesos" />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}

Section.propTypes = {
    id: PropTypes.string,
    nextButtonId: PropTypes.string,
};

Section.defaultProps = {
    id: '',
    nextButtonId: '',
};

export default Section;
