import { isEmpty } from 'underscore';

import { INPUT_TYPES } from '../index';

const FIELDS = [
    {
        jsonKey: 'name',
        label: 'Name',
        type: INPUT_TYPES.text,
        onChangeErrorMessage: 'Invalid data',
        onChangeValidation: (value) => {
            return !isEmpty(value);
        },
        onBlurErrorMessage: 'OB Invalid data',
        onBlurValidation: (value) => {
            return !isEmpty(value);
        },
        onSubmitErrorMessage: 'OS Invalid data',
        onSubmitValidation: (value) => {
            return !isEmpty(value);
        },
    },
    {
        jsonKey: 'lastname',
        label: 'Lastname',
        type: INPUT_TYPES.text,
        onChangeErrorMessage: 'Invalid data',
        onChangeValidation: (value) => {
            return !isEmpty(value);
        },
        onBlurErrorMessage: 'OB Invalid data',
        onBlurValidation: (value) => {
            return !isEmpty(value);
        },
        onSubmitErrorMessage: 'OS Invalid data',
        onSubmitValidation: (value) => {
            return !isEmpty(value);
        },
    },
    {
        jsonKey: 'email',
        label: 'Email',
        type: INPUT_TYPES.email,
        onChangeErrorMessage: 'Invalid data',
        onChangeValidation: (value) => {
            return !isEmpty(value);
        },
        onBlurErrorMessage: 'OB Invalid data',
        onBlurValidation: (value) => {
            return !isEmpty(value);
        },
        onSubmitErrorMessage: 'OS Invalid data',
        onSubmitValidation: (value) => {
            return !isEmpty(value);
        },
    },
    {
        jsonKey: 'password',
        label: 'Password',
        type: INPUT_TYPES.password,
        onChangeErrorMessage: 'Invalid data',
        onChangeValidation: (value) => {
            return !isEmpty(value);
        },
        onBlurErrorMessage: 'OB Invalid data',
        onBlurValidation: (value) => {
            return !isEmpty(value);
        },
        onSubmitErrorMessage: 'OS Invalid data',
        onSubmitValidation: (value) => {
            return !isEmpty(value);
        },
    },
    {
        jsonKey: 'role',
        label: 'User Role',
        type: INPUT_TYPES.select,
        options: [
            {
                label: 'User',
                value: 'user',
            },
            {
                label: 'Admin',
                value: 'admin',
            },
        ],
        onChangeErrorMessage: 'Invalid data',
        onChangeValidation: (value) => {
            return !isEmpty(value);
        },
        onBlurErrorMessage: 'OB Invalid data',
        onBlurValidation: (value) => {
            return !isEmpty(value);
        },
        onSubmitErrorMessage: 'OS Invalid data',
        onSubmitValidation: (value) => {
            return !isEmpty(value);
        },
    },
    {
        jsonKey: 'organization_id',
        label: 'Organization ID',
        type: INPUT_TYPES.text,
        onChangeErrorMessage: 'Invalid data',
        onChangeValidation: (value) => {
            return !isEmpty(value);
        },
        onBlurErrorMessage: 'OB Invalid data',
        onBlurValidation: (value) => {
            return !isEmpty(value);
        },
        onSubmitErrorMessage: 'OS Invalid data',
        onSubmitValidation: (value) => {
            return !isEmpty(value);
        },
    },
];

export default FIELDS;
