import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import MeasureVerticalSpacer from './vertical_spacer';
import MeasureLoaderSvg from '../../assets/img/measure_loader.svg';

import './style_loader.sass';

class MeasureLoader extends PureComponent {

    static mapSize(size) {
        switch (size) {
        case 'xs':
            return { height: 30, width: 30, fontSize: 25 };
        case 'xl':
            return { height: '20vh', width: '20vh', fontSize: '15vh' };
        default:
            return { height: 60, width: 60, fontSize: 50 };
        }
    }

    render() {
        const { centered, size } = this.props;

        const { height, width } = MeasureLoader.mapSize(size);

        return (
            <div className={`measure-loader ${centered ? 'text-center' : ''}`}>
                <MeasureVerticalSpacer height={height} />
                <object
                    type="image/svg+xml"
                    data={MeasureLoaderSvg}
                    style={{ height, width }}
                >
                    measure-loader
                </object>
                <MeasureVerticalSpacer height={height} />
            </div>
        );
    }
}

MeasureLoader.propTypes = {
    centered: PropTypes.bool,
    size: PropTypes.oneOf([
        'xs',
        'md',
        'xl',
    ]),
    height: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    width: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};

MeasureLoader.defaultProps = {
    centered: true,
    height: 60,
    width: 60,
    size: 'md',
};

export default MeasureLoader;
