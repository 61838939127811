import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ScrollHelper, { Y_DIRECTION } from '../../../helpers/scroll_helper';

import BackImg from './assets/desktop/landing-desktop-01.png';
import BackImgTwo from './assets/desktop/landing-desktop-02.png';
import BackImgThree from './assets/desktop/landing-desktop-03.png';
import BackImgFour from './assets/desktop/landing-desktop-04.png';
import BackImgFive from './assets/desktop/landing-desktop-05.png';
import BackImgSix from './assets/desktop/landing-desktop-06.png';

import './style_image_section.sass';

class ImageSection extends Component {
    constructor(props) {
        super(props);
        this.setScrollListener = this.setScrollListener.bind(this);

        this.scrollHandlers = [];
    }

    componentDidMount() {
        this.setScrollListener();
        this.setWindowResize();
    }

    setWindowResize() {
        window.addEventListener('resize', this.setScrollListener);
    }

    setScrollListener() {
        const { id } = this.props;

        const section = document.getElementById(id);

        const sectionPosition = ScrollHelper.getPositionProperties(section);
        const sectionStartY = sectionPosition.start;
        const sectionHeight = sectionPosition.height;

        ScrollHelper.removeScrollListeners(this.scrollHandlers);

        let handler;

        handler = ScrollHelper.addScrollListener(ScrollHelper.scaleScrollHandler, {
            itemId: 'scroll-image-1',
            handlerStartValue: 2,
            handlerEndValue: 1,
            scrollStartPosition: sectionStartY,
            scrollEndPosition: sectionStartY + sectionHeight * 0.5,
            direction: Y_DIRECTION,
            additionalTransform: 'translateY(-50%) translateX(-50%)',
        });
        this.scrollHandlers.push(handler);

        handler = ScrollHelper.addScrollListener(ScrollHelper.opacityScrollHandler, {
            itemId: 'scroll-image-1-title',
            handlerStartValue: 1,
            handlerEndValue: 0,
            scrollStartPosition: sectionStartY + sectionHeight * 0.9,
            scrollEndPosition: sectionStartY + sectionHeight * 1.1,
            direction: Y_DIRECTION,
        });
        this.scrollHandlers.push(handler);

        handler = ScrollHelper.addScrollListener(ScrollHelper.opacityScrollHandler, {
            itemId: 'scroll-image-2',
            handlerStartValue: 0,
            handlerEndValue: 1,
            scrollStartPosition: sectionStartY + sectionHeight * 0.9,
            scrollEndPosition: sectionStartY + sectionHeight * 1.1,
            direction: Y_DIRECTION,
        });
        this.scrollHandlers.push(handler);

        handler = ScrollHelper.addScrollListener(ScrollHelper.opacityScrollHandler, {
            itemId: 'scroll-image-3',
            handlerStartValue: 0,
            handlerEndValue: 1,
            scrollStartPosition: sectionStartY + sectionHeight * 1.1,
            scrollEndPosition: sectionStartY + sectionHeight * 1.3,
            direction: Y_DIRECTION,
        });
        this.scrollHandlers.push(handler);

        handler = ScrollHelper.addScrollListener(ScrollHelper.opacityScrollHandler, {
            itemId: 'scroll-image-4',
            handlerStartValue: 0,
            handlerEndValue: 1,
            scrollStartPosition: sectionStartY + sectionHeight * 1.6,
            scrollEndPosition: sectionStartY + sectionHeight * 1.8,
            direction: Y_DIRECTION,
        });
        this.scrollHandlers.push(handler);

        handler = ScrollHelper.addScrollListener(ScrollHelper.opacityScrollHandler, {
            itemId: 'scroll-image-5',
            handlerStartValue: 0,
            handlerEndValue: 1,
            scrollStartPosition: sectionStartY + sectionHeight * 2.1,
            scrollEndPosition: sectionStartY + sectionHeight * 2.3,
            direction: Y_DIRECTION,
        });
        this.scrollHandlers.push(handler);

        handler = ScrollHelper.addScrollListener(ScrollHelper.opacityScrollHandler, {
            itemId: 'scroll-image-6',
            handlerStartValue: 0,
            handlerEndValue: 1,
            scrollStartPosition: sectionStartY + sectionHeight * 2.6,
            scrollEndPosition: sectionStartY + sectionHeight * 2.8,
            direction: Y_DIRECTION,
        });
        this.scrollHandlers.push(handler);

        handler = ScrollHelper.addScrollListener(ScrollHelper.opacityScrollHandler, {
            itemId: 'scroll-image-7',
            handlerStartValue: 0,
            handlerEndValue: 1,
            scrollStartPosition: sectionStartY + sectionHeight * 2.8,
            scrollEndPosition: sectionStartY + sectionHeight * 3.5,
            direction: Y_DIRECTION,
        });
        this.scrollHandlers.push(handler);
    }

    render() {
        const { id } = this.props;

        return (
            <div id={id} className="img-section">
                <img id="scroll-image-1" src={BackImg} alt="Comienza a adoptar la transformación digital iot" className="stage-image big" />
                <img id="scroll-image-2" src={BackImgTwo} alt="Automatizamos la recolección y el análisis de los datos generados en tus instalaciones" className="stage-image" />
                <img id="scroll-image-3" src={BackImgThree} alt="instalamos sensores sobre tu infraestructura existente" className="stage-image" />
                <img id="scroll-image-4" src={BackImgFour} alt="Informate en tiempo real con nuestro dashboard online desde donde estésmeasure-fields" className="stage-image" />
                <img id="scroll-image-5" src={BackImgFive} alt="Recibe alertas cuando las mediciones se salgan de los parámetros que definas" className="stage-image" />
                <img id="scroll-image-6" src={BackImgTwo} alt="Automatizamos la recolección y el análisis de los datos generados en tus instalaciones" className="stage-image" />
                <img id="scroll-image-7" src={BackImgSix} alt="Measure dashboard para ver todo lo que ocurre en tu empresa" className="stage-image" />
                <h1 id="scroll-image-1-title" className="image-title">
                    Comienza a adoptar la Transformación Digital IoT
                </h1>
            </div>
        );
    }
}

ImageSection.propTypes = {
    id: PropTypes.string,
    nextButtonId: PropTypes.string,
};

ImageSection.defaultProps = {
    id: '',
    nextButtonId: '',
};
export default ImageSection;
