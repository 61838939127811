import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    isEmpty,
    isBoolean,
    isUndefined,
    isFunction,
} from 'underscore';
import {
    IoIosWarning,
    IoIosCloseCircleOutline,
} from 'react-icons/io';
import {
    FaFolderOpen,
} from 'react-icons/fa';
import {
    RiVirusFill,
} from 'react-icons/ri';

import CardHeader from './card_header';

import './style.sass';

class MeasureCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: props.expanded,
        };

        this.setCardId();
        this.toggleExpand = this.toggleExpand.bind(this);
    }

    setCardId() {
        const { id } = this.props;

        if (!isUndefined(id)) {
            this.cardId = id;
            return;
        }

        const classId = `${Math.floor(Math.random() * 1000)}`;
        this.cardId = `metric-card-${classId}`;
    }

    toggleExpand() {
        const { expandCallback } = this.props;
        const { expanded } = this.state;

        const newExpandedState = !expanded;

        this.setState({
            expanded: newExpandedState,
        }, () => {
            if (isFunction(expandCallback)) {
                expandCallback(newExpandedState);
            }
        });
    }

    renderHeaderIcon() {
        const { highlight } = this.props;

        if (isBoolean(highlight)) return null;

        switch (highlight) {
        case 'warning':
        case 'warning-active':
            return <IoIosWarning className="measure-card-icon" />;

        case 'error':
        case 'error-active':
            return <IoIosCloseCircleOutline className="measure-card-icon" />;

        case 'quarantined':
            return <span className="measure-card-icon"><RiVirusFill /><span className="icon-text">&nbsp;Cuarentena&nbsp;</span></span>;

        case 'disabled':
            return <span className="measure-card-icon"><FaFolderOpen /><span className="icon-text">&nbsp;Desactivado&nbsp;</span></span>;

        default:
            break;
        }

        return null;
    }

    renderCardHeader() {
        const { title, subtitle, expandable, actionButton } = this.props;
        const { expanded } = this.state;

        const callback = expandable ? this.toggleExpand : null;

        if (isEmpty(title) && isEmpty(subtitle) && isEmpty(actionButton) && !expandable) return null;

        const headerIcon = this.renderHeaderIcon();

        return (
            <CardHeader
                title={title}
                subtitle={subtitle}
                toggleExpand={callback}
                expanded={expanded}
                actionButton={actionButton}
                icon={headerIcon}
            />
        );
    }

    renderSeparator(header, body) {
        const { expanded } = this.state;

        if (isEmpty(header) || isEmpty(body)) return null;

        if (expanded) {
            return (
                <span className="measure-card-separator">
                    <hr />
                </span>
            );
        }

        return null;
    }

    renderCardBody() {
        const { children } = this.props;
        const { expanded } = this.state;

        if (!expanded || isEmpty(children)) return null;

        return (
            <div className="measure-card-body">
                {children}
            </div>
        );
    }

    renderCardClass() {
        const { highlight } = this.props;

        let measureClass = 'measure-card';

        measureClass = isBoolean(highlight) && highlight ? `${measureClass} card-highlight` : measureClass;
        measureClass = !isBoolean(highlight) ? `${measureClass} card-highlight card-${highlight}` : measureClass;

        return measureClass;
    }

    render() {
        const header = this.renderCardHeader();
        const body = this.renderCardBody();

        const separator = this.renderSeparator(header, body);

        return (
            <div className={this.renderCardClass()} id={this.cardId}>
                {header}
                {separator}
                {body}
            </div>
        );
    }
}

MeasureCard.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    highlight: PropTypes.oneOf([
        true,
        false,
        'enabled',
        'attention-active',
        'error',
        'error-active',
        'warning',
        'warning-active',
        'quarantined',
    ]),
    expandable: PropTypes.bool,
    expanded: PropTypes.bool,
    actionButton: PropTypes.node,
    id: PropTypes.string,
    expandCallback: PropTypes.func,
};

MeasureCard.defaultProps = {
    id: undefined,
    children: undefined,
    title: '',
    subtitle: '',
    highlight: false,
    expandable: false,
    expanded: true,
    actionButton: React.ReactType,
    expandCallback: undefined,
};

export default MeasureCard;
