import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import BlueLogo from '../../assets/img/logo_blue.svg';
import WhiteLogo from '../../assets/img/logo_white.svg';
import BlueCloudLogo from '../../assets/img/logo_blue_cloud.svg';

class MeasureLogo extends PureComponent {
    static selectLogoColor(color) {
        switch (color) {
        case 'white':
            return WhiteLogo;
        case 'blueCloud':
            return BlueCloudLogo;
        default:
            return BlueLogo;
        }
    }

    render() {
        const {
            color, width, path, height, className,
        } = this.props;

        const selectedLogo = MeasureLogo.selectLogoColor(color);

        const image = <img className={className} src={selectedLogo} alt="Measure IoT - Automatizamos la recolección e inteligencia de los datos generados en tus instalaciones." style={{ width, height }} />;

        if (path !== false) {
            return (
                <Link to={path}>
                    {image}
                </Link>
            );
        }

        return image;
    }
}

MeasureLogo.propTypes = {
    path: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    color: PropTypes.oneOf([
        'blue',
        'white',
        'blueCloud',
    ]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    className: PropTypes.string,
};

MeasureLogo.defaultProps = {
    path: false,
    color: 'blue',
    width: 'auto',
    height: 'auto',
    className: '',
};

export default MeasureLogo;
