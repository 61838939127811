import React, { PureComponent } from 'react';
import { Row, Col } from 'reactstrap';

import MCardsHorizontalContainer from '../../mcomponents/mcards_horizontal_container';
import MCard from '../../mcomponents/mcards/mcard_base';

import MOrganizationAdmin from '../../mcomponents/mcards/morganization_admin';
import MHubAdmin from '../../mcomponents/mcards/mhub_admin';
import MUserAdmin from '../../mcomponents/mcards/muser_admin';
import MDeviceAdmin from '../../mcomponents/mcards/mdevice_admin';
import MSensorAdmin from '../../mcomponents/mcards/msensor_admin';
import MSensorCalibrationAdmin from '../../mcomponents/mcards/msensor_calibration_admin';

class MAdminPage extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            selectedOrganizationId: undefined,
            selectedHubId: undefined,
            selectedDeviceId: undefined,
            selectedSensorId: undefined,
            selectedCalibrationId: undefined,

            displayHubs: false,
            displayUsers: false,
            displayDevices: false,
            displaySensors: false,
            displayCalibrations: false,
        };

        this.handleOrganizationSelected = this.handleOrganizationSelected.bind(this);
        this.handleHubSelected = this.handleHubSelected.bind(this);
        this.handleDeviceSelected = this.handleDeviceSelected.bind(this);
        this.handleSensorSelected = this.handleSensorSelected.bind(this);
        this.handleCalibrationSelected = this.handleCalibrationSelected.bind(this);

        this.handleDetailSelected = this.handleDetailSelected.bind(this);
    }

    handleOrganizationSelected(organizationId) {
        this.setState({
            selectedOrganizationId: organizationId,
            selectedHubId: undefined,
            selectedDeviceId: undefined,
            selectedSensorId: undefined,
        }, MAdminPage.scrollToEnd);
    }

    handleHubSelected(hubId) {
        this.setState({
            selectedHubId: hubId,
            selectedDeviceId: undefined,
            selectedSensorId: undefined,

            displayUsers: false,
            displayHubs: true,
            displayDevices: true,
            displaySensors: false,
            displayCalibrations: false,
        }, MAdminPage.scrollToEnd);
    }

    handleDeviceSelected(deviceId) {
        this.setState({
            selectedDeviceId: deviceId,
            selectedSensorId: undefined,

            displayUsers: false,
            displayHubs: true,
            displayDevices: true,
            displaySensors: true,
            displayCalibrations: false,
        }, MAdminPage.scrollToEnd);
    }

    handleSensorSelected(sensorId) {
        this.setState({
            selectedSensorId: sensorId,

            displayUsers: false,
            displayHubs: true,
            displayDevices: true,
            displaySensors: true,
            displayCalibrations: true,
        }, MAdminPage.scrollToEnd);
    }

    handleCalibrationSelected(calibrationId) {
        this.setState({
            selectedCalibrationId: calibrationId,
        }, MAdminPage.scrollToEnd);
    }

    handleDetailSelected(id) {
        switch (id) {
        case 'users': {
            this.setState({
                displayUsers: true,
                displayHubs: false,
                displayDevices: false,
                displaySensors: false,
                displayCalibrations: false,
            });
            break;
        }
        case 'hubs': {
            this.setState({
                displayUsers: false,
                displayHubs: true,
                displayDevices: false,
                displaySensors: false,
                displayCalibrations: false,
            });
            break;
        }
        default:
            break;
        }
    }

    static scrollToEnd() {
        const element = document.getElementById('mcards-scroll-container');

        element.scrollTo({
            left: element.scrollWidth,
            behavior: 'smooth',
        });
    }

    render() {
        const {
            selectedOrganizationId,
            selectedHubId,
            selectedDeviceId,
            selectedSensorId,
            selectedCalibrationId,
            displayHubs,
            displayUsers,
            displayDevices,
            displaySensors,
            displayCalibrations,
        } = this.state;

        return (
            <div>
                <Row style={{ marginBottom: '1rem' }}>
                    <Col>
                        <MCard
                            title="Consola"
                            subtitle="Administradores"
                            expandable={false}
                            expanded={false}
                        />
                    </Col>
                </Row>
                <MCardsHorizontalContainer id="mcards-scroll-container">
                    <MOrganizationAdmin
                        selectedOrganizationId={selectedOrganizationId}
                        onExpand={this.handleOrganizationSelected}
                        onButtonTapped={this.handleDetailSelected}
                    />
                    {
                        displayUsers && (
                            <MUserAdmin
                                selectedOrganizationId={selectedOrganizationId}
                            />
                        )
                    }
                    {
                        displayHubs && (
                            <MHubAdmin
                                selectedOrganizationId={selectedOrganizationId}
                                selectedHubId={selectedHubId}
                                onSelect={this.handleHubSelected}
                            />
                        )
                    }
                    {
                        displayDevices && (
                            <MDeviceAdmin
                                selectedHubId={selectedHubId}
                                selectedDeviceId={selectedDeviceId}
                                onSelect={this.handleDeviceSelected}
                            />
                        )
                    }
                    {
                        displaySensors && (
                            <MSensorAdmin
                                selectedDeviceId={selectedDeviceId}
                                selectedSensorId={selectedSensorId}
                                onSelect={this.handleSensorSelected}
                            />
                        )
                    }
                    {
                        displayCalibrations && (
                            <MSensorCalibrationAdmin
                                selectedSensorId={selectedSensorId}
                                selectedCalibrationId={selectedCalibrationId}
                                onSelect={this.handleCalibrationSelected}
                            />
                        )
                    }
                </MCardsHorizontalContainer>
            </div>
        );
    }
}

export default MAdminPage;
