import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';

import {
    MinusIcon,
    PlusIcon,
} from 'react-line-awesome';

import HeaderButton from './header_button';

import './style.scss';

const CardHeader = (props) => {
    const {
        icon,
        title,
        subtitle,
        expandButtonOnClick,
        expanded,
        expandable,
        actionButtonText,
        actionButtonOnClick,
    } = props;

    if (_.isEmpty(icon) && _.isEmpty(title) && _.isEmpty(subtitle)) return null;

    let iconElement = null;
    if (!_.isEmpty(icon)) {
        iconElement = <span className="mcard-header-icon">{icon}</span>;
    }

    let titleElement = null;
    if (!_.isEmpty(title)) {
        titleElement = <span className="mcard-header-title">{title}</span>;
    }

    let subtitleElement = null;
    if (!_.isEmpty(subtitle)) {
        subtitleElement = <span className="mcard-header-subtitle">{subtitle}</span>;
    }

    let expandButtonElement = null;
    if (expandable && _.isFunction(expandButtonOnClick)) {
        const expandButtonIconElement = expanded ? <MinusIcon /> : <PlusIcon />;
        const expandButtonClass = expanded ? 'mcard-header-button mcard-header-button-active' : 'mcard-header-button';

        expandButtonElement = (
            <HeaderButton className={expandButtonClass} onClick={() => { expandButtonOnClick(!expanded); }}>
                {expandButtonIconElement}
            </HeaderButton>
        );
    }

    let actionButtonElement = null;
    if (_.isFunction(actionButtonOnClick) && !_.isEmpty(actionButtonText)) {
        actionButtonElement = (
            <HeaderButton className="mcard-header-button" onClick={actionButtonOnClick}>
                {actionButtonText}
            </HeaderButton>
        );
    }

    if (_.isEmpty(iconElement) && _.isEmpty(titleElement) && _.isEmpty(subtitleElement)) return null;

    return (
        <div className="mcard-header">
            {iconElement}{titleElement}{subtitleElement}{expandButtonElement}{actionButtonElement}
        </div>
    );
};

CardHeader.propTypes = {
    icon: PropTypes.node,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    expanded: PropTypes.bool,
    expandable: PropTypes.bool,
    expandButtonOnClick: PropTypes.func,
    actionButtonText: PropTypes.string,
    actionButtonOnClick: PropTypes.func,
};

CardHeader.defaultProps = {
    icon: undefined,
    title: undefined,
    subtitle: undefined,
    expanded: true,
    expandable: false,
    expandButtonOnClick: undefined,
    actionButtonText: undefined,
    actionButtonOnClick: undefined,
};

export default CardHeader;
