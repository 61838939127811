import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import _ from 'underscore';

import * as Actions from '../../actions';
import MeasurePropTypes from '../../propTypes';

import MLoader from '../../mcomponents/mloader';
import MCard from '../../mcomponents/mcards/mcard_base';

const MDashboardPage = (props) => {
    let body = null;

    const { hubs } = props;

    if (_.isUndefined(hubs)) {
        body = (
            <Col className="text-center">
                <MLoader size="md" centered />
            </Col>
        );
    } else if (_.isEmpty(hubs)) {
        body = (
            <Col className="text-center">
                <h4>Vaya! Aún no tienes ningún Hub...</h4>
            </Col>
        );
    } else {
        const history = useHistory();

        body = hubs.map((hub) => {
            const { id, name, additionalInfo } = hub;

            return (
                <Col xs={12} md={6} key={id}>
                    <MCard
                        title={name}
                        actionButtonText="Mediciones"
                        actionButtonOnClick={() => {
                            history.push(`/hubs/${id}`);
                        }}
                    >
                        {additionalInfo}
                    </MCard>
                </Col>
            );
        });
    }

    return (
        <div>
            <Row style={{ marginBottom: '1rem' }}>
                <Col>
                    <MCard
                        title="Selecciona un Hub"
                        subtitle="Para ver las mediciones"
                        expandable={false}
                        expanded={false}
                    />
                </Col>
            </Row>
            <Row>
                { body }
            </Row>
        </div>
    );
};

const mapStateToProps = (state) => {
    const { currentUser } = state.users;
    const { hubs } = state.hubs;

    const newProps = {};

    if (_.isObject(hubs) && !_.isEmpty(hubs)) {
        const { organizationId } = currentUser;

        newProps.hubs = Object.values(hubs).filter((hub) => {
            return hub.organizationId === organizationId;
        });

        newProps.hubs.sort((lhs, rhs) => {
            return lhs.name.localeCompare(rhs.name);
        });
    }

    return newProps;
};

MDashboardPage.propTypes = {
    hubs: MeasurePropTypes.HUBS,
};

MDashboardPage.defaultProps = {
    hubs: undefined,
};

export default connect(mapStateToProps, Actions)(MDashboardPage);
