import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
    MinusIcon,
    PlusIcon,
} from 'react-line-awesome';
import HeaderButton from './header_button';

class ExpandButton extends PureComponent {
    render() {
        const { expanded, onClick, float } = this.props;

        const icon = expanded ? <MinusIcon /> : <PlusIcon />;

        return (
            <HeaderButton
                onClick={onClick}
                float={float}
            >
                {icon}
            </HeaderButton>

        );
    }
}

ExpandButton.propTypes = {
    expanded: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    float: PropTypes.oneOf([
        'left',
        'right',
    ]),
};

ExpandButton.defaultProps = {
    expanded: true,
    float: 'right',
};

export default ExpandButton;
