import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'underscore';
import { MeasureRequestHandler } from '@measure-iot/api';

import MeasurePropTypes from '../../../propTypes';
import * as Actions from '../../../actions';

import MCard from '../mcard_base';
import MLoader from '../../mloader';
import MDataEmptyState from '../../mdata_charts/body_empty_state';

import JsonTextarea from '../../mjson_textarea';
import MForm from '../../mform';
import USER_FIELDS from '../../mform/fields/user';
import USER_PUT_FIELDS from '../../mform/fields/userPut';
import unwrapper from '../../../helpers/unwrapper';

import './style.scss';

class MUserAdmin extends PureComponent {
    constructor(props) {
        super(props);

        this.cancelToken = new MeasureRequestHandler();
        this.handleActionButtonOnClick = this.handleActionButtonOnClick.bind(this);
        this.triggerPostUser = this.triggerPostUser.bind(this);
        this.triggerPutUser = this.triggerPutUser.bind(this);
        this.triggerDeleteUser = this.triggerDeleteUser.bind(this);
    }

    componentDidMount() {
        this.triggerGetAllUsers();
    }

    componentDidUpdate(prevProps) {
        const { selectedOrganizationId } = this.props;

        if (selectedOrganizationId !== prevProps.selectedOrganizationId) {
            this.triggerGetAllUsers();
        }
    }

    triggerGetAllUsers() {
        const { selectedOrganizationId, triggerGetAllUsers } = this.props;

        triggerGetAllUsers(selectedOrganizationId, this.cancelToken);
    }

    triggerPostUser(json) {
        const { triggerPostUser } = this.props;

        return triggerPostUser(json, this.cancelToken);
    }

    async triggerPutUser(id, json) {
        const { triggerPutUser } = this.props;

        return triggerPutUser(id, json, this.cancelToken);
    }

    triggerDeleteUser(user) {
        const { triggerDeleteUser, onSelect } = this.props;

        const { id, name } = user;

        const accepted = window.confirm(`Estás por eliminar el usuario "${name}"\n\nEsta acción no puede deshacerse`);
        if (!accepted) return;

        triggerDeleteUser(id, this.cancelToken);

        if (!_.isFunction(onSelect)) return;

        onSelect();
    }

    handleActionButtonOnClick(id) {
        const { onSelect } = this.props;

        if (!_.isFunction(onSelect)) return;

        onSelect(id);
    }

    static renderLoader() {
        return (
            <MLoader size="md" centered />
        );
    }

    renderUsers() {
        const { users, selectedUserId } = this.props;

        if (_.isUndefined(users)) return MUserAdmin.renderLoader();

        if (_.isEmpty(users)) return <MDataEmptyState title="No hay usuarios" />;

        return users.map((user) => {
            const {
                id,
                name,
                lastname,
                email,
                role,
                organizationId,
            } = user;

            return (
                <MCard
                    key={id}
                    active={id === selectedUserId}
                    title={name}
                    subtitle="User"
                    expandable
                    expanded={false}
                    actionButtonOnClick={() => { this.handleActionButtonOnClick(id); }}
                    actionButtonText=""
                >
                    <ul className="muser-admin-list">
                        <li>Id <JsonTextarea jsonPayload={id} /></li>
                        <li>Role <JsonTextarea jsonPayload={unwrapper(role, 'name')} /></li>

                        <MForm
                            fields={USER_PUT_FIELDS}
                            defaults={{
                                id,
                                name,
                                lastname,
                                email,
                                organization_id: organizationId,
                            }}
                            onSubmit={(json) => { return this.triggerPutUser(id, json); }}
                            submitButtonText="Guardar"
                        />
                    </ul>

                    <button
                        type="button"
                        className="btn btn-danger btn-block"
                        onClick={() => { this.triggerDeleteUser(user); }}
                    >
                        Eliminar
                    </button>
                </MCard>
            );
        });
    }

    renderForm() {
        const { selectedOrganizationId } = this.props;

        return (
            <MCard title="Nuevo Usuario" expandable expanded={false}>
                <MForm
                    fields={USER_FIELDS}
                    defaults={{
                        organization_id: selectedOrganizationId,
                    }}
                    hiddenFields={[
                        'organization_id',
                    ]}
                    onSubmit={this.triggerPostUser}
                    submitButtonText="Crear"
                />
            </MCard>
        );
    }

    render() {
        return (
            <MCard title="Usuarios">
                { this.renderForm() }

                <br />

                { this.renderUsers() }
            </MCard>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const { selectedOrganizationId } = ownProps;
    const { users } = state.users;

    const newProps = {};

    if (_.isObject(users) && !_.isEmpty(users)) {
        newProps.users = Object.values(users).filter((user) => {
            return user.organizationId === selectedOrganizationId;
        });

        newProps.users.sort((lhs, rhs) => {
            return lhs.name.localeCompare(rhs.name);
        });
    }

    return newProps;
};

MUserAdmin.propTypes = {
    selectedOrganizationId: PropTypes.string.isRequired,
    selectedUserId: PropTypes.string,
    onSelect: PropTypes.func,

    // Redux
    users: MeasurePropTypes.USERS,

    // Actions
    triggerGetAllUsers: PropTypes.func.isRequired,
    triggerPostUser: PropTypes.func.isRequired,
    triggerPutUser: PropTypes.func.isRequired,
    triggerDeleteUser: PropTypes.func.isRequired,
};

MUserAdmin.defaultProps = {
    selectedUserId: undefined,
    onSelect: undefined,
    users: undefined,
};

export default connect(mapStateToProps, Actions)(MUserAdmin);
