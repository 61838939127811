import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { MeasureRequestHandler } from '@measure-iot/api';
import { GiSpyglass } from 'react-icons/gi';

import MeasurePropTypes from '../../propTypes';
import * as Actions from '../../actions';

import MCard from '../../mcomponents/mcards/mcard_base';
import MDeviceCard from '../../mcomponents/mcards/mdevice';
import MLoader from '../../mcomponents/mloader';
import MContacts from '../../mcomponents/mcards/mcontacts';
import MDatasetTimeSpan from '../../mcomponents/mdataset_time_span';
import MHubReports from '../../mcomponents/mcards/mhub_reports';

import './style.scss';

class MHubPage extends PureComponent {
    constructor(props) {
        super(props);

        this.cancelToken = new MeasureRequestHandler();
        this.triggerSetDatasetTimeSpan = this.triggerSetDatasetTimeSpan.bind(this);
    }

    componentDidMount() {
        this.triggerRequestSelectedHub();
    }

    componentDidUpdate(prevProps) {
        const { hubId: prevHubId } = prevProps;
        const { hubId } = this.props;

        if (prevHubId !== hubId) {
            this.triggerRequestSelectedHub();
        }
    }

    async triggerRequestSelectedHub() {
        const { triggerGetHub, triggerGetAllDevices, hubId } = this.props;

        await triggerGetHub(hubId, this.cancelToken);
        await triggerGetAllDevices(hubId, this.cancelToken);
    }

    async triggerSetDatasetTimeSpan(startEpoch, endEpoch) {
        const { triggerSetDatasetTimeSpan } = this.props;

        triggerSetDatasetTimeSpan(startEpoch, endEpoch);
    }

    static renderLoader() {
        return (
            <Col className="text-center" style={{ marginTop: '45vh' }}>
                <MLoader size="md" centered />
            </Col>
        );
    }

    static renderEmptyState() {
        return (
            <Col className="mhubs-page-center-container text-center">
                <GiSpyglass size="3rem" />
                <br />
                <br />
                <div className="mhubs-page-container-title">No encontramos lo que estás buscando</div>
                <div className="mhubs-page-container-subtitle">Si el problema persiste avísanos</div>
            </Col>
        );
    }

    renderHubSection(hub) {
        if (_.isUndefined(hub)) return MHubPage.renderLoader();
        if (_.isNull(hub)) return MHubPage.renderEmptyState();

        const { id, name, additionalInfo } = hub;

        return (
            <MCard key={id} title={name} subtitle="Hub" expandable>
                { additionalInfo }

                <Row>
                    <Col xs={12} md={6}>
                        <MContacts hubId={id} />
                    </Col>
                    <Col xs={12} md={6}>
                        <MHubReports hubId={id} />
                    </Col>
                    <Col xs={12} md={6}>
                        <MDatasetTimeSpan onChange={this.triggerSetDatasetTimeSpan} />
                    </Col>
                </Row>
            </MCard>
        );
    }

    static renderDevices(devices) {
        if (!_.isArray(devices)) return MHubPage.renderLoader();

        return devices.map((device) => {
            const { id } = device;

            return (
                <Col key={id} xs={12} style={{ marginTop: '1rem' }}>
                    <MDeviceCard deviceId={id} />
                </Col>
            );
        });
    }

    render() {
        const { hub, devices } = this.props;

        const hubElement = this.renderHubSection(hub);
        const deviceElements = MHubPage.renderDevices(devices);

        return (
            <Row>
                <Col>
                    <Row>
                        <Col>{hubElement}</Col>
                    </Row>
                    <Row>{deviceElements}</Row>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const { hubId } = ownProps;
    const { devices } = state.devices;
    const { hubs } = state.hubs;

    const newProps = {};

    if (_.isObject(hubs) && !_.isEmpty(hubs)) {
        newProps.hub = hubs[hubId];
    }

    if (_.isObject(devices) && !_.isEmpty(devices)) {
        newProps.devices = Object.values(devices).filter((device) => {
            return device.hubId === hubId;
        });

        newProps.devices.sort((lhs, rhs) => {
            if (lhs.status === rhs.status) {
                return lhs.name.localeCompare(rhs.name, undefined, { numeric: true, sensitivity: 'base' });
            }

            if (lhs.status === 'enabled' && rhs.status === 'quarantined') return -1;
            if (lhs.status === 'enabled' && rhs.status === 'disabled') return -1;

            if (lhs.status === 'quarantined' && rhs.status === 'enabled') return 1;
            if (lhs.status === 'quarantined' && rhs.status === 'disabled') return -1;

            if (lhs.status === 'disabled' && rhs.status === 'enabled') return 1;
            if (lhs.status === 'disabled' && rhs.status === 'quarantined') return 1;

            return 0;
        });
    }

    return newProps;
};

MHubPage.propTypes = {
    hubId: PropTypes.string.isRequired,

    // Redux
    hub: MeasurePropTypes.HUB,
    devices: MeasurePropTypes.DEVICES,

    // Actions
    triggerGetHub: PropTypes.func.isRequired,
    triggerGetAllDevices: PropTypes.func.isRequired,
    triggerSetDatasetTimeSpan: PropTypes.func.isRequired,
};

MHubPage.defaultProps = {
    hub: undefined,
    devices: undefined,
};

export default connect(mapStateToProps, Actions)(MHubPage);
