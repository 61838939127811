import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Form,
    FormGroup,
    FormFeedback,
    Input,
} from 'reactstrap';
import {
    isUndefined,
} from 'underscore';
import { CheckCircleIcon, SendIcon } from 'react-line-awesome';

import MeasureVerticalSpacer from '../general/vertical_spacer';

import MeasureRegex from '../../helpers/regex_helper';

class MeasureFormLogIn extends Component {
    constructor(props) {
        super(props);

        this.state = {
            contactField: {
                value: undefined,
                isValid: false,
                error: '',
            },
            formSubmitted: false,
            submitSuccess: false,
            handleSubmit: props.handleSubmit,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateContactField = this.validateContactField.bind(this);
        this.currentValidation = null;
    }

    handleSubmit(event) {
        event.preventDefault();

        const { contactField, handleSubmit } = this.state;

        if (contactField.isValid) {
            this.setState({
                formSubmitted: true,
            });

            const body = {
                contactInfo: contactField.value,
                dateSent: (new Date()).toISOString(),
            };

            handleSubmit(body).then(() => {
                this.setState({
                    submitSuccess: true,
                });
            }).catch((error) => {
                this.setState({
                    formSubmitted: false,
                    submitSuccess: false,
                    contactField: {
                        ...contactField,
                        isValid: false,
                        error,
                    },
                });
            });
        }
    }

    validateContactField(event) {
        const { value } = event.target;

        const isValidPhone = MeasureRegex.isValidPhone(value);
        const isValidMail = MeasureRegex.isValidMail(value);

        if (this.currentValidation !== null) {
            window.clearTimeout(this.currentValidation);

            if (!isValidMail && !isValidPhone) {
                this.setState({
                    contactField: {
                        value,
                        isValid: undefined,
                    },
                });
            }
        }

        const timeout = isValidMail || isValidPhone ? 0 : 1000;

        this.currentValidation = window.setTimeout((newVal) => {
            this.validateContactFieldValue(newVal);
        }, timeout, value);
    }

    validateContactFieldValue(value) {
        const isValidPhone = MeasureRegex.isValidPhone(value);
        const isValidMail = MeasureRegex.isValidMail(value);

        let error = '';
        if (!isValidPhone && !isValidMail) {
            if (value === '') {
                error = '';
            } else if (MeasureRegex.isPhone(value)) {
                error = 'Debe seguir el formato teléfono.';
            } else if (MeasureRegex.isMail(value)) {
                error = 'Debe seguir el formato mail.';
            } else {
                error = 'Debe seguir el formato de teléfono o mail.';
            }
        }

        document.getElementById('loginContactField').value = value;

        this.setState({
            contactField: {
                value,
                isValid: isValidPhone || isValidMail,
                error,
            },
        });
    }

    renderContactFieldField() {
        const { contactField, formSubmitted } = this.state;

        return (
            <FormGroup>
                <Input
                    type="text"
                    name="loginContactField"
                    id="loginContactField"
                    placeholder="Déjanos tu mail o teléfono"
                    className="text-center"
                    onKeyUp={this.validateContactField}
                    onChange={this.validateContactField}
                    onInput={this.validateContactField}
                    disabled={formSubmitted}
                    valid={contactField.isValid}
                    invalid={!contactField.isValid && !isUndefined(contactField.value)}
                />
                <FormFeedback>{contactField.error}</FormFeedback>
            </FormGroup>
        );
    }


    renderSubmitButton() {
        const { contactField } = this.state;

        const submitDisabled = !contactField.isValid;
        if (submitDisabled) {
            return (
                <MeasureVerticalSpacer height="4rem" />
            );
        };

        return (
            <div className="text-center">
                <MeasureVerticalSpacer height="1rem" />
                <Button color="primary" outline style={{ minWidth: '60%' }}>Enviar</Button>
                <MeasureVerticalSpacer height="1rem" />
            </div>
        );
    }

    render() {
        const { formSubmitted, submitSuccess } = this.state;

        if (formSubmitted && !submitSuccess) {
            return (
                <div className="text-center success-message">
                    <h1><SendIcon /></h1>
                    <h4>Enviando...</h4>
                </div>
            );
        }

        if (formSubmitted && submitSuccess) {
            return (
                <div className="text-center success-message">
                    <h1><CheckCircleIcon /></h1>
                    <h4>Te contactaremos lo antes posible!</h4>
                </div>
            );
        }

        return (
            <Form onSubmit={this.handleSubmit} autoComplete="off">
                {
                    this.renderContactFieldField()
                }
                {
                    this.renderSubmitButton()
                }
            </Form>
        );
    }
}

MeasureFormLogIn.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
};

export default MeasureFormLogIn;
