import React, { Component } from 'react';
import PropTypes from 'prop-types';

class DynamicHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentHeaderIndex: Math.floor(Math.random() * props.headers.length),
        };

        this.startDynamicTitle();
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    startDynamicTitle() {
        const { duration, headers } = this.props;

        this.interval = window.setInterval(() => {
            const { currentHeaderIndex } = this.state;
            let newIndex = currentHeaderIndex + 1;

            if (newIndex === headers.length) {
                newIndex = 0;
            }

            this.setState({
                currentHeaderIndex: newIndex,
            });
        }, duration);
    }

    render() {
        const { className, headers } = this.props;
        const { currentHeaderIndex } = this.state;

        const currentPhrase = headers[currentHeaderIndex];

        return (
            <h1 className={className}>{ currentPhrase }</h1>
        );
    }
}

DynamicHeader.propTypes = {
    className: PropTypes.string,
    duration: PropTypes.number,
    headers: PropTypes.arrayOf(PropTypes.string),
};

DynamicHeader.defaultProps = {
    className: '',
    duration: 5500,
    headers: [],
};

export default DynamicHeader;
