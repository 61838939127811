import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';

const MTimeButton = (props) => {
    const {
        value,
        text,
        onClick,
        disabled,
        className,
    } = props;

    return (
        <button
            type="button"
            className={`mdataset-time-span-button ${className} ${_.isEmpty(value) ? '' : 'active'}`}
            onClick={onClick}
            disabled={disabled}
        >
            { value || text }
        </button>
    );
};

MTimeButton.propTypes = {
    value: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    text: PropTypes.string,
    className: PropTypes.string,
};

MTimeButton.defaultProps = {
    value: undefined,
    disabled: false,
    onClick: undefined,
    text: undefined,
    className: undefined,
};

export default MTimeButton;
