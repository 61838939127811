import _ from 'underscore';

import { INPUT_TYPES } from '../index';

const FIELDS = [
    {
        jsonKey: 'raw',
        label: 'Raw Measurement',
        type: INPUT_TYPES.number,
        onChangeErrorMessage: 'Invalid data',
        onChangeValidation: (value) => {
            return _.isNumber(value);
        },
        onBlurErrorMessage: 'OB Invalid data',
        onBlurValidation: (value) => {
            return _.isNumber(value);
        },
        onSubmitErrorMessage: 'OS Invalid data',
        onSubmitValidation: (value) => {
            return _.isNumber(value);
        },
    },
    {
        jsonKey: 'ref',
        label: 'Ref Measurement',
        type: INPUT_TYPES.number,
        onChangeErrorMessage: 'Invalid data',
        onChangeValidation: (value) => {
            return _.isNumber(value);
        },
        onBlurErrorMessage: 'OB Invalid data',
        onBlurValidation: (value) => {
            return _.isNumber(value);
        },
        onSubmitErrorMessage: 'OS Invalid data',
        onSubmitValidation: (value) => {
            return _.isNumber(value);
        },
    },
    {
        jsonKey: 'sensor_id',
        label: 'Sensor ID',
        type: INPUT_TYPES.text,
        onChangeErrorMessage: 'Invalid data',
        onChangeValidation: (value) => {
            return !_.isEmpty(value);
        },
        onBlurErrorMessage: 'OB Invalid data',
        onBlurValidation: (value) => {
            return !_.isEmpty(value);
        },
        onSubmitErrorMessage: 'OS Invalid data',
        onSubmitValidation: (value) => {
            return !_.isEmpty(value);
        },
    },
];

export default FIELDS;
