import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'underscore';
import { MeasureRequestHandler } from '@measure-iot/api';

import MeasurePropTypes from '../../../propTypes';
import * as Actions from '../../../actions';

import MCard from '../mcard_base';
import MLoader from '../../mloader';
import MDataEmptyState from '../../mdata_charts/body_empty_state';

import JsonTextarea from '../../mjson_textarea';
import MForm from '../../mform';
import CALIBRATION_FIELDS from '../../mform/fields/sensorCalibration';

import './style.scss';

class MSensorCalibrationAdmin extends PureComponent {
    constructor(props) {
        super(props);

        this.cancelToken = new MeasureRequestHandler();
        this.triggerPostSensorCalibration = this.triggerPostSensorCalibration.bind(this);
        this.triggerPutSensorCalibration = this.triggerPutSensorCalibration.bind(this);
        this.triggerDeleteSensorCalibration = this.triggerDeleteSensorCalibration.bind(this);
    }

    componentDidMount() {
        this.triggerGetSensor();
    }

    componentDidUpdate(prevProps) {
        const { selectedSensorId } = this.props;

        if (selectedSensorId !== prevProps.selectedSensorId) {
            this.triggerGetSensor();
        }
    }

    triggerGetSensor() {
        const { selectedSensorId, triggerGetSensor } = this.props;

        triggerGetSensor(selectedSensorId, this.cancelToken);
    }

    async triggerPostSensorCalibration(json) {
        const { triggerPostSensorCalibration } = this.props;

        const error = await triggerPostSensorCalibration(json, this.cancelToken);

        if (_.isUndefined(error)) {
            this.triggerGetSensor();
        }

        return error;
    }

    async triggerPutSensorCalibration(id, json) {
        const { triggerPutSensorCalibration } = this.props;

        const error = await triggerPutSensorCalibration(id, json, this.cancelToken);

        if (_.isUndefined(error)) {
            this.triggerGetSensor();
        }

        return error;
    }

    async triggerDeleteSensorCalibration(calibration) {
        const { triggerDeleteSensorCalibration, onSelect } = this.props;

        const { id, ref } = calibration;

        const accepted = window.confirm(`Estás por eliminar la calibración ref="${ref}"\n\nEsta acción no puede deshacerse`);
        if (!accepted) return;

        const error = await triggerDeleteSensorCalibration(id, this.cancelToken);

        if (_.isUndefined(error)) {
            this.triggerGetSensor();
        }

        if (!_.isFunction(onSelect)) return;

        onSelect();
    }

    static renderLoader() {
        return (
            <MLoader size="md" centered />
        );
    }

    renderCalibrations() {
        const { calibrations, selectedCalibrationId } = this.props;

        if (_.isUndefined(calibrations)) return MSensorCalibrationAdmin.renderLoader();

        if (_.isEmpty(calibrations)) return <MDataEmptyState title="No hay calibraciones" />;

        return calibrations.map((calibration) => {
            const {
                id,
                raw,
                ref,
                sensorId,
            } = calibration;

            return (
                <MCard
                    key={id}
                    active={id === selectedCalibrationId}
                    title={`Ref ${ref}`}
                    subtitle="Calibración"
                    expandable
                    expanded={false}
                >
                    <ul className="msensor-admin-list">
                        <li>Id <JsonTextarea jsonPayload={id} /></li>

                        <MForm
                            fields={CALIBRATION_FIELDS}
                            defaults={{
                                id,
                                raw,
                                ref,
                                sensor_id: sensorId,
                            }}
                            onSubmit={(json) => { return this.triggerPutSensorCalibration(id, json); }}
                            submitButtonText="Guardar"
                        />
                    </ul>

                    <button
                        type="button"
                        className="btn btn-danger btn-block"
                        onClick={() => { this.triggerDeleteSensorCalibration(calibration); }}
                    >
                        Eliminar
                    </button>
                </MCard>
            );
        });
    }

    renderForm() {
        const { selectedSensorId } = this.props;

        return (
            <MCard title="Nueva Calibración" expandable expanded={false}>
                <MForm
                    fields={CALIBRATION_FIELDS}
                    defaults={{
                        sensor_id: selectedSensorId,
                    }}
                    hiddenFields={[
                        'sensor_id',
                    ]}
                    onSubmit={this.triggerPostSensorCalibration}
                    submitButtonText="Crear"
                />
            </MCard>
        );
    }

    render() {
        return (
            <MCard title="Sensores">
                { this.renderForm() }

                <br />

                { this.renderCalibrations() }
            </MCard>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const { selectedSensorId } = ownProps;
    const { sensors } = state.sensors;

    const newProps = {};

    if (_.isObject(sensors) && !_.isEmpty(sensors)) {
        newProps.calibrations = Object.values(sensors).filter((sensor) => {
            return sensor.id === selectedSensorId;
        }).reduce((total, sensor) => {
            if (!_.isArray(sensor.calibrations)) return total;

            return [
                ...total,
                ...sensor.calibrations,
            ];
        }, []);
    }

    return newProps;
};

MSensorCalibrationAdmin.propTypes = {
    selectedSensorId: PropTypes.string.isRequired,
    selectedCalibrationId: PropTypes.string,
    onSelect: PropTypes.func,

    // Redux
    calibrations: MeasurePropTypes.CALIBRATIONS,

    // Actions
    triggerGetSensor: PropTypes.func.isRequired,
    triggerPostSensorCalibration: PropTypes.func.isRequired,
    triggerPutSensorCalibration: PropTypes.func.isRequired,
    triggerDeleteSensorCalibration: PropTypes.func.isRequired,
};

MSensorCalibrationAdmin.defaultProps = {
    selectedCalibrationId: undefined,
    onSelect: undefined,
    calibrations: undefined,
};

export default connect(mapStateToProps, Actions)(MSensorCalibrationAdmin);
