import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Col } from 'reactstrap';
import { isEmpty } from 'underscore';
import { Redirect, withRouter } from 'react-router-dom';

import * as Actions from '../../../actions';
import NetworkBackground from '../../general/network_background';
import MeasureLogo from '../../general/measure_logo';
import MeasureCardContainer from '../../general/measure_card/cards_container';
import MeasureCard from '../../general/measure_card';
import MeasureFormLogIn from '../../forms/login';
import MeasureVerticalSpacer from '../../general/vertical_spacer';

import SadCloud from '../../../assets/img/sad_cloud.svg';
import './style.sass';

const offSetColumnDef = { size: 4, order: 2, offset: 4 };

class MeasureLogin extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            message: undefined,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleTryAgain = this.handleTryAgain.bind(this);
    }

    async handleSubmit(event) {
        const { handleSubmit } = this.props;

        const error = await handleSubmit(event.email, event.password);

        if (error !== undefined) {
            this.setState({
                message: error.message,
            });
        }
    }

    handleTryAgain() {
        this.setState({
            message: undefined,
        });
    }

    static renderBody(loginMessage, handleSubmit, handleTryAgain) {
        if (isEmpty(loginMessage)) {
            return (
                <div>
                    <MeasureLogo className="login-image" color="blueCloud" />
                    <MeasureVerticalSpacer height="2rem" />
                    <MeasureFormLogIn handleSubmit={handleSubmit} />
                </div>
            );
        }

        if (loginMessage === 'session_expired') {
            return (
                <div className="text-center">
                    <MeasureVerticalSpacer height="2rem" />
                    <h3>Sesión expirada</h3>
                    <MeasureVerticalSpacer height="2rem" />
                    <img src={SadCloud} alt="User not found" style={{ width: '60%' }} />
                    <MeasureVerticalSpacer height="2rem" />
                    <Button color="primary" block outline onClick={handleTryAgain}>
                        Iniciar Sesión
                    </Button>
                </div>
            );
        }

        return (
            <div className="text-center">
                <MeasureVerticalSpacer height="2rem" />
                <h3>No te logramos encontrar</h3>
                <MeasureVerticalSpacer height="2rem" />
                <img src={SadCloud} alt="User not found" style={{ width: '60%' }} />
                <MeasureVerticalSpacer height="2rem" />
                <Button color="primary" block outline onClick={handleTryAgain}>
                    Reintentar
                </Button>
            </div>
        );
    }

    render() {
        const { loggedIn } = this.props;
        const { message } = this.state;

        if (loggedIn) {
            return <Redirect to={{ pathname: '/' }} />;
        }

        return (
            <div>
                <NetworkBackground />
                <MeasureCardContainer>
                    <Col md={offSetColumnDef} lg={offSetColumnDef} xl={offSetColumnDef}>
                        <MeasureVerticalSpacer height="10vh" />
                        <MeasureCard>{MeasureLogin.renderBody(message, this.handleSubmit, this.handleTryAgain)}</MeasureCard>
                    </Col>
                </MeasureCardContainer>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { currentUser } = state.users;
    return {
        loggedIn: !isEmpty(currentUser),
    };
};

MeasureLogin.propTypes = {
    loggedIn: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
};

MeasureLogin.defaultProps = {
    loggedIn: false,
};

const MeasureLogInRouter = withRouter(MeasureLogin);
export default connect(mapStateToProps, Actions)(MeasureLogInRouter);
