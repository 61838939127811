import TYPES from './types';

export const dispatchDeviceUpdate = (dispatch, id, data) => {
    dispatch({
        type: TYPES.SET,
        payload: {
            deviceId: id,
            device: data,
        },
    });
};

export const dispatchDeviceDatasetUpdate = (dispatch, id, data) => {
    dispatch({
        type: TYPES.DATASETS.SET,
        payload: {
            deviceId: id,
            dataset: data,
        },
    });
};

export const dispatchDeviceSignatureUpdate = (dispatch, id, data) => {
    dispatch({
        type: TYPES.SET_SIGNATURE,
        payload: {
            deviceId: id,
            signature: data,
        },
    });
};

export const dispatchDeviceDatasetTimeSpanUpdate = (dispatch, startEpoch, endEpoch) => {
    dispatch({
        type: TYPES.SET_DATASETS_TIME_SPAN,
        payload: {
            startEpoch,
            endEpoch,
        },
    });
};
