import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';
import _ from 'underscore';

const MSwitch = (props) => {
    const {
        id,
        label,
        texts,
        checked,
        disabled,
        onChange,
    } = props;

    let switchText = texts;
    if (_.isObject(texts)) {
        switchText = checked ? texts.checked : texts.unchecked;
    }

    return (
        <div>
            <div className="mform-switch-label">
                { label }
            </div>
            <div>
                <label
                    className="mform-switch"
                    htmlFor={id}
                >
                    <input
                        id={id}
                        type="checkbox"
                        disabled={disabled}
                        checked={checked}
                        onChange={onChange}
                    />
                    <i />
                    { switchText }
                </label>
            </div>
        </div>
    );
};

MSwitch.propTypes = {
    id: PropTypes.string,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    label: PropTypes.string,
    texts: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            checked: PropTypes.string,
            unchecked: PropTypes.string,
        }),
    ]),
};

MSwitch.defaultProps = {
    id: undefined,
    checked: false,
    disabled: false,
    onChange: undefined,
    label: undefined,
    texts: {
        checked: 'Encendido',
        unchecked: 'Apagado',
    },
};

export default MSwitch;
