import TYPES from './types';

export const dispatchOrganizationUpdate = (dispatch, id, data) => {
    dispatch({
        type: TYPES.SET,
        payload: {
            organizationId: id,
            organization: data,
        },
    });
};
