import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';
import { isEmpty } from 'underscore';
import { Col, Row } from 'reactstrap';

import * as Actions from '../../actions';
import MeasurePropTypes from '../../propTypes';

import * as Authenticator from '../../helpers/authenticator';

import MeasureLoader from '../general/loader';
import MeasureVerticalSpacer from '../general/vertical_spacer';

class Middleware extends PureComponent {
    componentDidMount() {
        this.validateLogin();
        this.checkPermission();
    }

    componentDidUpdate(prevProps) {
        const { currentUser } = this.props;

        if (prevProps.currentUser !== currentUser) {
            this.checkPermission();
        }
    }

    validateLogin() {
        const { history, failurePath } = this.props;

        if (Authenticator.isAuthenticated()) return;

        history.push(failurePath);
    }

    checkPermission() {
        const {
            history,
            failurePath,
            requiredRoles,
            currentUser,
        } = this.props;

        if (isEmpty(currentUser)) return;

        if (Middleware.hasRole(requiredRoles, currentUser)) {
            return;
        }

        history.push(failurePath);
    }

    static hasRole(requiredRoles, currentUser) {
        if (isEmpty(requiredRoles)) return true;

        const { role } = currentUser;
        if (isEmpty(role)) return false;

        return requiredRoles.includes(role.name);
    }

    static renderLoadingCard() {
        return (
            <Row>
                <Col className="text-center">
                    <MeasureVerticalSpacer height="20vh" />
                    <MeasureLoader size="xl" centered />
                </Col>
            </Row>
        );
    }

    render() {
        const { children, exact, path, currentUser } = this.props;

        if (isEmpty(currentUser)) {
            return Middleware.renderLoadingCard();
        }

        return (
            <Route exact={exact} path={path}>
                {
                    children
                }
            </Route>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.users.currentUser,
    };
};

Middleware.propTypes = {
    currentUser: MeasurePropTypes.USER,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    exact: PropTypes.bool,
    history: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
    path: PropTypes.string,
    failurePath: PropTypes.string,
    requiredRoles: PropTypes.arrayOf(PropTypes.string),
};

Middleware.defaultProps = {
    currentUser: {},
    exact: false,
    path: '/',
    failurePath: '/',
    requiredRoles: [],
};

export default connect(mapStateToProps, Actions)(withRouter(Middleware));
