/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';

import MetricInteger from './MetricInteger';
import MeasureLogo from '../../general/measure_logo';

import './MeasureWallboard.sass';

class MeasureWallboard extends Component {
    render() {
        return (
            <Container fluid className="wallboard-container">
                <Row className="no-gutters wallboard-row">
                    <Col className="measure-logo">
                        <MeasureLogo height="100%" color="white" />
                    </Col>
                </Row>
                <Row className="no-gutters wallboard-row row-half">
                    <Col>
                        <MetricInteger value="20" unit="ºC" title="Hornos" subtitle="min temp" highlight="warning-active" />
                    </Col>
                    <Col>
                        <MetricInteger value="655" unit="ºC" title="Hornos" subtitle="max temp" highlight="error-active" />
                    </Col>
                    <Col>
                        <MetricInteger value="15" unit="mins" title="Ultima" subtitle="medición" highlight="warning" />
                    </Col>
                    <Col>
                        <MetricInteger value="4" title="Sensores" subtitle="inactivos" highlight="error" />
                    </Col>
                </Row>
                <Row className="no-gutters wallboard-row row-half">
                    <Col>
                        <MetricInteger value="55" title="Hello" subtitle="world" />
                    </Col>
                    <Col>
                        <MetricInteger value="" title="Incidentes" subtitle="registrados" />
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default MeasureWallboard;
