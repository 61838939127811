import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { MeasureRequestHandler } from '@measure-iot/api';
import { Col, Row } from 'reactstrap';

import * as Actions from '../../../actions';
import MeasureLoader from '../../general/loader';
import MeasureVerticalSpacer from '../../general/vertical_spacer';

class LogoutPage extends Component {
    constructor(props) {
        super(props);

        this.cancelToken = new MeasureRequestHandler();
    }

    componentDidMount() {
        const { triggerLogout, history } = this.props;

        triggerLogout(this.cancelToken).then(() => {
            history.replace('/');
        });
    }

    componentWillUnmount() {
        this.cancelToken.cancel();
    }

    render() {
        return (
            <Row>
                <Col className="text-center">
                    <MeasureVerticalSpacer height="20vh" />
                    <MeasureLoader size="xl" centered />
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedIn: state.loggedIn,
        currentUser: state.users.currentUser,
    };
};

LogoutPage.propTypes = {
    triggerLogout: PropTypes.func.isRequired,
    history: PropTypes.shape({
        replace: PropTypes.func,
    }).isRequired,
};

export default withRouter(connect(mapStateToProps, Actions)(LogoutPage));
