import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';

import MeasurePropTypes from '../../../propTypes';
import SensorDatasetParser from '../../../helpers/sensor_dataset_parser';

import MDataEmptyState from '../body_empty_state';
import MBodyLoader from '../body_loader';

import './style.scss';

const MHourmeter = (props) => {
    const {
        height,
        dataset,
        dataVariable,
    } = props;

    if (_.isUndefined(dataset)) return <MBodyLoader height={height} />;
    if (!_.isArray(dataset) || _.isEmpty(dataset)) return <MDataEmptyState height={height} />;

    const threshold = (dataVariable.max - dataVariable.min) / 2;
    const aggregatedDataset = SensorDatasetParser.aggregateHours(dataset, threshold);

    const hours = parseInt(aggregatedDataset / 3600, 10).toString().padStart(2, '0');
    const minutes = parseInt((aggregatedDataset - hours * 3600) / 60, 10).toString().padStart(2, '0');

    const turnOnCount = SensorDatasetParser.countTurnOn(dataset, threshold);

    return (
        <div style={{ height }}>
            <h1 className="hour-display">
                {hours}<small>hrs</small>&nbsp;{minutes}<small>mins</small>
            </h1>
            <p className="text-center">
                Se ha encendido {turnOnCount === 1 ? `${turnOnCount} vez` : `${turnOnCount} veces`} en el periodo seleccionado.
            </p>
        </div>
    );
};

MHourmeter.propTypes = {
    dataVariable: MeasurePropTypes.SENSOR_DATA_VARIABLE,
    dataset: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.shape({
                lng: PropTypes.number,
                lat: PropTypes.number,
                alt: PropTypes.number,
            }),
        ]),
    })),
    height: PropTypes.number,
};

MHourmeter.defaultProps = {
    dataVariable: {},
    dataset: undefined,
    height: 300,
};

export default MHourmeter;
