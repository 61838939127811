import TYPES from './types';

export const dispatchHubUpdate = (dispatch, id, data) => {
    dispatch({
        type: TYPES.SET,
        payload: {
            hubId: id,
            hub: data,
        },
    });
};

export const dispatchHubReportUpdate = (dispatch, id, data) => {
    dispatch({
        type: TYPES.REPORTS.SET,
        payload: {
            reportId: id,
            report: data,
        },
    });
};

export const dispatchHubReportClearAll = (dispatch) => {
    dispatch({
        type: TYPES.REPORTS.CLEAR_ALL,
    });
};
