import { isEmpty, isString } from 'underscore';

import { INPUT_TYPES } from '../index';

const FIELDS = [
    {
        jsonKey: 'name',
        label: 'Device Name',
        placeholder: 'Measure Board G-5',
        type: INPUT_TYPES.text,
        onChangeErrorMessage: 'Invalid data',
        onChangeValidation: (value) => {
            return !isEmpty(value);
        },
        onBlurErrorMessage: 'OB Invalid data',
        onBlurValidation: (value) => {
            return !isEmpty(value);
        },
        onSubmitErrorMessage: 'OS Invalid data',
        onSubmitValidation: (value) => {
            return !isEmpty(value);
        },
    },
    {
        jsonKey: 'mac',
        label: 'Device MAC',
        type: INPUT_TYPES.text,
        textTransform: 'uppercase',
        onChangeErrorMessage: 'Invalid data',
        placeholder: 'bc:dd:c2:d8:1a:24',
        onChangeTransform: (value) => {
            if (isString(value)) {
                let newValue = value.toUpperCase();
                newValue = newValue.replace(/[^a-fA-F0-9]/g, '');
                newValue = newValue.replace(/(..)/g, '$1:');
                newValue = newValue.slice(0, 17);

                return newValue;
            }
            return value;
        },
        onChangeValidation: (value) => {
            return !isEmpty(value);
        },
        onBlurErrorMessage: 'OB Invalid data',
        onBlurValidation: (value) => {
            return !isEmpty(value);
        },
        onSubmitErrorMessage: 'OS Invalid data',
        onSubmitValidation: (value) => {
            return !isEmpty(value);
        },
    },
    {
        jsonKey: 'status',
        label: 'Device Status',
        type: INPUT_TYPES.select,
        options: [
            {
                label: 'Enabled',
                value: 'enabled',
            },
            {
                label: 'Quarantined',
                value: 'quarantined',
            },
            {
                label: 'Disabled',
                value: 'disabled',
            },
        ],
        onChangeErrorMessage: 'Invalid data',
        onChangeValidation: (value) => {
            return !isEmpty(value);
        },
        onBlurErrorMessage: 'OB Invalid data',
        onBlurValidation: (value) => {
            return !isEmpty(value);
        },
        onSubmitErrorMessage: 'OS Invalid data',
        onSubmitValidation: (value) => {
            return !isEmpty(value);
        },
    },
    {
        jsonKey: 'additional_info',
        label: 'Device Aditional Info',
        type: INPUT_TYPES.textarea,
    },
    {
        jsonKey: 'hub_id',
        label: 'Hub ID',
        type: INPUT_TYPES.text,
        onChangeErrorMessage: 'Invalid data',
        onChangeValidation: (value) => {
            return !isEmpty(value);
        },
        onBlurErrorMessage: 'OB Invalid data',
        onBlurValidation: (value) => {
            return !isEmpty(value);
        },
        onSubmitErrorMessage: 'OS Invalid data',
        onSubmitValidation: (value) => {
            return !isEmpty(value);
        },
    },
];

export default FIELDS;
