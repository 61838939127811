import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'underscore';
import { MeasureRequestHandler } from '@measure-iot/api';

import MeasurePropTypes from '../../../propTypes';
import * as Actions from '../../../actions';

import MCard from '../mcard_base';
import MLoader from '../../mloader';
import MDataEmptyState from '../../mdata_charts/body_empty_state';

import JsonTextarea from '../../mjson_textarea';
import MForm from '../../mform';
import HUB_FIELDS from '../../mform/fields/hub';

import './style.scss';

class MHubAdmin extends PureComponent {
    constructor(props) {
        super(props);

        this.cancelToken = new MeasureRequestHandler();
        this.handleActionButtonOnClick = this.handleActionButtonOnClick.bind(this);
        this.triggerPostHub = this.triggerPostHub.bind(this);
        this.triggerPutHub = this.triggerPutHub.bind(this);
        this.triggerDeleteHub = this.triggerDeleteHub.bind(this);
    }

    componentDidMount() {
        this.triggerGetAllHubs();
    }

    componentDidUpdate(prevProps) {
        const { selectedOrganizationId } = this.props;

        if (selectedOrganizationId !== prevProps.selectedOrganizationId) {
            this.triggerGetAllHubs();
        }
    }

    triggerGetAllHubs() {
        const { selectedOrganizationId, triggerGetAllHubs } = this.props;

        triggerGetAllHubs(selectedOrganizationId, this.cancelToken);
    }

    triggerPostHub(json) {
        const { triggerPostHub } = this.props;

        return triggerPostHub(json, this.cancelToken);
    }

    async triggerPutHub(id, json) {
        const { triggerPutHub } = this.props;

        return triggerPutHub(id, json, this.cancelToken);
    }

    triggerDeleteHub(hub) {
        const { triggerDeleteHub, onSelect } = this.props;

        const { id, name } = hub;

        const accepted = window.confirm(`Estás por eliminar el hub "${name}"\n\nEsta acción no puede deshacerse`);
        if (!accepted) return;

        triggerDeleteHub(id, this.cancelToken);

        if (!_.isFunction(onSelect)) return;

        onSelect();
    }

    handleActionButtonOnClick(id) {
        const { onSelect } = this.props;

        if (!_.isFunction(onSelect)) return;

        onSelect(id);
    }

    static renderLoader() {
        return (
            <MLoader size="md" centered />
        );
    }

    renderHubs() {
        const { hubs, selectedHubId } = this.props;

        if (_.isUndefined(hubs)) return MHubAdmin.renderLoader();

        if (_.isEmpty(hubs)) return <MDataEmptyState title="No hay hubs" />;

        return hubs.map((hub) => {
            const {
                id,
                name,
                additionalInfo,
                organizationId,
            } = hub;

            return (
                <MCard
                    key={id}
                    active={id === selectedHubId}
                    title={name}
                    subtitle="Hub"
                    expandable
                    expanded={false}
                    actionButtonOnClick={() => { this.handleActionButtonOnClick(id); }}
                    actionButtonText="Dispositivos"
                >
                    <ul className="mhub-admin-list">
                        <li>Id <JsonTextarea jsonPayload={id} /></li>

                        <MForm
                            fields={HUB_FIELDS}
                            defaults={{
                                id,
                                name,
                                additional_info: additionalInfo,
                                organization_id: organizationId,
                            }}
                            onSubmit={(json) => { return this.triggerPutHub(id, json); }}
                            submitButtonText="Guardar"
                        />
                    </ul>

                    <button
                        type="button"
                        className="btn btn-danger btn-block"
                        onClick={() => { this.triggerDeleteHub(hub); }}
                    >
                        Eliminar
                    </button>
                </MCard>
            );
        });
    }

    renderForm() {
        const { selectedOrganizationId } = this.props;

        return (
            <MCard title="Nuevo Hub" expandable expanded={false}>
                <MForm
                    fields={HUB_FIELDS}
                    defaults={{
                        organization_id: selectedOrganizationId,
                    }}
                    hiddenFields={[
                        'organization_id',
                    ]}
                    onSubmit={this.triggerPostHub}
                    submitButtonText="Crear"
                />
            </MCard>
        );
    }

    render() {
        return (
            <MCard title="Hubs">
                { this.renderForm() }

                <br />

                { this.renderHubs() }
            </MCard>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const { selectedOrganizationId } = ownProps;
    const { hubs } = state.hubs;

    const newProps = {};

    if (_.isObject(hubs) && !_.isEmpty(hubs)) {
        newProps.hubs = Object.values(hubs).filter((hub) => {
            return hub.organizationId === selectedOrganizationId;
        });

        newProps.hubs.sort((lhs, rhs) => {
            return lhs.name.localeCompare(rhs.name);
        });
    }

    return newProps;
};

MHubAdmin.propTypes = {
    selectedOrganizationId: PropTypes.string.isRequired,
    selectedHubId: PropTypes.string,
    onSelect: PropTypes.func,

    // Redux
    hubs: MeasurePropTypes.HUBS,

    // Actions
    triggerGetAllHubs: PropTypes.func.isRequired,
    triggerPostHub: PropTypes.func.isRequired,
    triggerPutHub: PropTypes.func.isRequired,
    triggerDeleteHub: PropTypes.func.isRequired,
};

MHubAdmin.defaultProps = {
    selectedHubId: undefined,
    onSelect: undefined,
    hubs: undefined,
};

export default connect(mapStateToProps, Actions)(MHubAdmin);
