import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { MeasureRequestHandler } from '@measure-iot/api';
import { Row, Col } from 'reactstrap';
import {
    FaLinkedin,
    FaGithub,
} from 'react-icons/fa';

import * as Actions from '../../../actions';
import MeasureVerticalSpacer from '../../general/vertical_spacer';
import MeasureFormContact from '../../forms/contact';
import Logo from '../../general/measure_logo';


import './style_section_contact.sass';

class Section extends Component {
    constructor(props) {
        super(props);

        this.cancelToken = new MeasureRequestHandler();
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(body) {
        const { triggerPostContactForm } = this.props;
        return triggerPostContactForm(body.contactInfo, this.cancelToken).catch(() => {
            const error = new Error('Encontramos un error al enviar tus datos. Puedes contactarnos en measure@measure.cl');
            return Promise.reject(error);
        });
    }

    static renderStaticContact() {
        return (
            <Row>
                <Col className="text-center text-white">
                    <Logo color="white" height="4rem" />
                    <MeasureVerticalSpacer height="2rem" />
                    contacto@measure.cl

                    <MeasureVerticalSpacer height="1rem" />
                    <a
                        className="contact-link"
                        href="https://linkedin.com/company/measure"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Linkedin"
                    >
                        <FaLinkedin />
                    </a>
                    <a
                        className="contact-link"
                        href="https://github.com/measure-cl"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Github"
                    >
                        <FaGithub />
                    </a>
                    <MeasureVerticalSpacer height="2rem" className="d-block" />
                </Col>
            </Row>
        );
    }

    render() {
        const { id } = this.props;

        return (
            <div className="contact-section" id={id}>
                <Row>
                    <Col>
                        <MeasureVerticalSpacer height="2rem" className="d-block" />
                        <Row>
                            <Col xs={{ size: 12, order: 2, offset: 0 }} md={{ size: 6, order: 1, offset: 0 }}>
                                {
                                    Section.renderStaticContact()
                                }
                            </Col>
                            <Col xs={{ size: 12, order: 1, offset: 0 }} md={{ size: 6, order: 2, offset: 0 }}>
                                <Row>
                                    <Col xs={12} md={{ size: 12, order: 1, offset: 0 }}>
                                        <MeasureVerticalSpacer height="1.5rem" className="d-none d-md-block" />
                                        <h3 className="section-title text-center">¡Nosotros te hablamos!</h3>
                                        <MeasureVerticalSpacer height="3rem" className="d-none d-md-block" />
                                        <MeasureVerticalSpacer height="2rem" className="d-block d-md-none" />
                                    </Col>
                                    <Col xs={{ size: 10, order: 1, offset: 1 }} md={{ size: 8, order: 1, offset: 2 }}>
                                        <MeasureFormContact handleSubmit={this.handleSubmit} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
};

Section.propTypes = {
    id: PropTypes.string,
    triggerPostContactForm: PropTypes.func.isRequired,
};

Section.defaultProps = {
    id: '',
};

export default connect(mapStateToProps, Actions)(Section);
