import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
    isEmpty,
    isFunction,
} from 'underscore';

import ExpandButton from './expand_button';

class CardHeader extends PureComponent {
    renderTitles() {
        const { title, subtitle, icon } = this.props;

        if (isEmpty(title) && isEmpty(subtitle) && isEmpty(icon)) return null;

        return (
            <span className="measure-card-title">
                {icon}{title}&nbsp;<small className="measure-card-subtitle">{subtitle}</small>
            </span>
        );
    }

    renderExpandButton() {
        const { toggleExpand, expanded } = this.props;

        if (isFunction(toggleExpand)) {
            return <ExpandButton onClick={toggleExpand} expanded={expanded} />;
        }

        return null;
    }

    render() {
        const { actionButton } = this.props;

        const titles = this.renderTitles();
        const expandButton = this.renderExpandButton();

        return (
            <div className="measure-card-header">
                {titles}
                {expandButton}
                {actionButton}
            </div>
        );
    }
}

CardHeader.propTypes = {
    icon: PropTypes.node,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    expanded: PropTypes.bool,
    toggleExpand: PropTypes.func,
    actionButton: PropTypes.node,
};

CardHeader.defaultProps = {
    icon: undefined,
    title: '',
    subtitle: '',
    expanded: true,
    toggleExpand: undefined,
    actionButton: null,
};

export default CardHeader;
