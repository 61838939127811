import _ from 'underscore';
import TYPES from './types';

import { isValidUUID } from '../../helpers/regex_helper';

const defaultState = {
    organizations: {},
};

const Reducer = (oldState = defaultState, dispatchedAction) => {
    const { type, payload } = dispatchedAction;

    const newState = { ...oldState };

    switch (type) {
    case TYPES.SET: {
        const { organizationId, organization } = payload;
        if (!isValidUUID(organizationId)) break;

        if (_.isUndefined(organization)) {
            delete newState.organizations[organizationId];
            break;
        }

        newState.organizations[organizationId] = organization;
        break;
    }

    default: {
        break;
    }
    }

    return newState;
};

export default Reducer;
