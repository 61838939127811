import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
    Row,
    Col,
} from 'reactstrap';

import LandingNavbar from './navbar';
import DynamicHeader from './dynamic_header';
import MeasureVerticalSpacer from '../../general/vertical_spacer';
import MeasureSliderBar from '../../general/slider_bar';

import MeasureChartVideo from '../../../assets/videos/chart_video.mp4';
import MeasureChartVideoWebm from '../../../assets/videos/chart_video.webm';
import MeasureChartImage from '../../../assets/img/chart_img.png';
import './style_section_first.sass';

const firstSectionLeftCol = {
    size: 5,
    order: 1,
    offset: 1,
};
const firstSectionRightCol = {
    size: 5,
    order: 2,
};

const headers = [
    '¿Están gastando mucha agua en los baños?',
    '¿Cuántos cargamentos de fruta se te han congelado?',
    '¿Quedó cerrada la válvula de riego?',
    '¿Tu frigorífico está a la temperatura correcta?',
    '¿Cuánto tiempo demoras en detectar una falla?',
    '¿Puedes monitorear tu fábrica desde una reunión?',
    '¿Cuánto tiempo gastas digitalizando planillas?',
    '¿Tu plantación se está regando como debería?',
];

class Section extends PureComponent {
    async componentDidMount() {
        const videoId = 'measure-chart-v';
        const video = document.getElementById(videoId);

        video.addEventListener('ended', () => {
            setTimeout(() => {
                video.play();
            }, 5000);
        }, false);
    }

    renderNextSectionButton() {
        const { nextButtonId } = this.props;

        if (nextButtonId === '') return null;

        return (
            <Row className="text-center">
                <Col>
                    <MeasureVerticalSpacer height="5vh" className="d-none d-md-block" />
                    <MeasureVerticalSpacer height="8vh" />
                    <MeasureVerticalSpacer height="2vh" />
                    <MeasureSliderBar className="d-none d-md-block" text="Haz scroll para saber más" />
                    <MeasureSliderBar className="d-block d-md-none" text="Desliza para saber más" />
                    <MeasureVerticalSpacer height="10vh" />
                </Col>
            </Row>
        );
    }

    render() {
        const { id } = this.props;
        return (
            <div className="first-section" id={id}>
                <LandingNavbar />
                <MeasureVerticalSpacer height="5vh" className="d-none d-md-block" />
                <Row>
                    <Col md={firstSectionLeftCol}>
                        <Row>
                            <Col className="first-section-title-col">
                                <DynamicHeader className="landing-title" headers={headers} />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="first-section-subtitle-col">
                                <MeasureVerticalSpacer height="5vh" className="d-md-none" />
                                <p className="landing-subtitle">Concentra tu tiempo en tus productos, nosotros nos encargamos de medir.</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={firstSectionRightCol} className="d-none d-md-block">
                        <Row>
                            <Col>
                                <MeasureVerticalSpacer height="5vh" />
                            </Col>
                        </Row>
                        <Row>
                            <Col id="first-section-image-col" className="first-section-image-col">
                                <video
                                    className="first-section-image d-none d-md-block"
                                    id="measure-chart-v"
                                    muted
                                    preload="auto"
                                    autoPlay
                                >
                                    <source src={MeasureChartVideo} type="video/mp4" />
                                    <source src={MeasureChartVideoWebm} type="video/webm" />
                                    <img src={MeasureChartImage} alt="Reduce el tiempo detectando fallas o incidentes con Measure" />
                                </video>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {
                    this.renderNextSectionButton()
                }
            </div>
        );
    }
}

Section.propTypes = {
    id: PropTypes.string,
    nextButtonId: PropTypes.string,
};

Section.defaultProps = {
    id: '',
    nextButtonId: '',
};

export default Section;
