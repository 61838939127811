export const ENTITY_SET_HUB_REPORTS = 'entity.setHubReports';

export const ENTITY_SET_DEVICE_LAST_MESSAGE = 'entity.setDeviceLastMessage';
export const ENTITY_SET_DEVICE_HARDWARE = 'entity.setDeviceHardware';

export const ENTITY_SET_CONTACTS = 'entity.setContacts';

export const USER_SET_CURRENT = 'users.setCurrentUser';

export const APP_CLEAR_STORE = 'app.clearStore';
