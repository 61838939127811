import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';

import CardHeader from './header';

import './style.scss';

const MCard = (props) => {
    const {
        active,
        icon,
        title,
        subtitle,
        expanded,
        expandable,
        children,
        actionButtonText,
        actionButtonOnClick,
        onExpand,
    } = props;

    const [isExpanded, toggleExpanded] = useState(expanded);

    const onExpandCallback = () => {
        const newState = !isExpanded;
        if (expandable) toggleExpanded(newState);

        if (_.isFunction(onExpand)) onExpand(newState);
    };

    const header = (
        <CardHeader
            icon={icon}
            title={title}
            subtitle={subtitle}
            expanded={expandable && isExpanded}
            expandable={expandable}
            expandButtonOnClick={onExpandCallback}
            actionButtonText={actionButtonText}
            actionButtonOnClick={actionButtonOnClick}
        />
    );

    const showSeparator = isExpanded && (!_.isEmpty(icon) || !_.isEmpty(title) || !_.isEmpty(subtitle)) && !_.isEmpty(children);
    const showBody = isExpanded && !_.isEmpty(children);

    return (
        <div className={`mcard-card ${active ? 'active' : ''}`}>
            { header }
            { showSeparator && <div className="mcard-separator" /> }
            { showBody && <span className="mcard-body">{children}</span> }
        </div>
    );
};

MCard.propTypes = {
    active: PropTypes.bool,
    icon: PropTypes.node,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    expanded: PropTypes.bool,
    expandable: PropTypes.bool,
    actionButtonText: PropTypes.string,
    actionButtonOnClick: PropTypes.func,
    onExpand: PropTypes.func,
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
    ]),
};

MCard.defaultProps = {
    active: false,
    icon: undefined,
    title: undefined,
    subtitle: undefined,
    expanded: true,
    expandable: false,
    actionButtonText: undefined,
    actionButtonOnClick: undefined,
    onExpand: undefined,
    children: undefined,
};

export default MCard;
