import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'underscore';
import { BiTachometer, BiLogOut } from 'react-icons/bi';
import { FiUser } from 'react-icons/fi';
import { RiDashboardLine } from 'react-icons/ri';

import * as Actions from '../../actions';
import ThemeHelper from '../../helpers/theme_helper';

import MSideMenu from './base';
import MButton from '../mbutton';

const quickLinksSection = {
    title: 'Acceso rápido',
    isLoading: false,
    links: [
        { url: '/', text: 'Dashboard', icon: <RiDashboardLine /> },
        { url: '/account', text: 'Mi Cuenta', icon: <FiUser /> },
        { url: '/logout', text: 'Cerrar Sesión', icon: <BiLogOut /> },
    ],
};

class MSideMenuData extends PureComponent {
    constructor(props) {
        super(props);

        const currentTheme = ThemeHelper.getGlobalTheme();

        this.state = {
            loadingHubs: false,
            themeMode: currentTheme,
        };

        this.triggerSwitchThemeMode = this.triggerSwitchThemeMode.bind(this);
    }

    componentDidMount() {
        this.triggerRequestHubsList();
    }

    triggerRequestHubsList() {
        const { currentUser, triggerGetAllHubs } = this.props;
        const { loadingHubs } = this.state;

        if (loadingHubs) return;

        this.setState(
            {
                loadingHubs: true,
            },
            async () => {
                await triggerGetAllHubs(currentUser.organizationId, this.cancelToken);

                this.setState({ loadingHubs: false });
            },
        );
    }

    triggerSwitchThemeMode() {
        const { themeMode } = this.state;

        const newTheme = themeMode === ThemeHelper.THEME_MODES.light ? ThemeHelper.THEME_MODES.dark : ThemeHelper.THEME_MODES.light;
        this.setState({ themeMode: newTheme });

        ThemeHelper.setGlobalTheme(newTheme);
    }

    renderHubLinkSection() {
        const { hubs } = this.props;
        const { loadingHubs } = this.state;

        const baseLinkSet = {
            title: 'Hubs',
            isLoading: loadingHubs,
        };

        if (!_.isArray(hubs)) return baseLinkSet;

        return {
            ...baseLinkSet,
            links: hubs.map((hub) => {
                return { url: `/hubs/${hub.id}`, text: hub.name };
            }),
        };
    }

    renderConfigLinkSection() {
        const { currentUser } = this.props;
        const { themeMode } = this.state;

        return {
            title: 'Administración',
            isLoading: false,
            links: [
                currentUser.isAdmin && {
                    url: '/admin',
                    text: 'Configuración',
                    icon: <BiTachometer />,
                },
                {
                    element: (
                        <MButton
                            text={themeMode === ThemeHelper.THEME_MODES.light ? 'Activar modo oscuro' : 'Desactivar modo oscuro'}
                            onClick={this.triggerSwitchThemeMode}
                        />
                    ),
                },
            ],
        };
    }

    render() {
        const sections = [this.renderHubLinkSection(), quickLinksSection, this.renderConfigLinkSection()];

        return <MSideMenu sections={sections} />;
    }
}

const mapStateToProps = (state) => {
    const { currentUser } = state.users;
    const { hubs } = state.hubs;

    const newProps = {};

    newProps.currentUser = {
        ...currentUser,
        isAdmin: currentUser.role.name === 'admin',
    };

    if (_.isObject(hubs) && !_.isEmpty(hubs)) {
        const { organizationId } = currentUser;

        newProps.hubs = Object.values(hubs).filter((hub) => {
            return hub.organizationId === organizationId;
        });

        newProps.hubs.sort((lhs, rhs) => {
            return lhs.name.localeCompare(rhs.name);
        });
    }

    return newProps;
};

MSideMenuData.propTypes = {
    currentUser: PropTypes.shape({
        organizationId: PropTypes.string,
        role: PropTypes.shape({
            name: PropTypes.string,
        }),
        isAdmin: PropTypes.bool,
    }).isRequired,
    hubs: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
        }),
    ),

    // Actions
    triggerGetAllHubs: PropTypes.func.isRequired,
};

MSideMenuData.defaultProps = {
    hubs: undefined,
};

export default connect(mapStateToProps, Actions)(MSideMenuData);
