import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import { HiBadgeCheck } from 'react-icons/hi';

import MDataEmptyState from '../body_empty_state';
import MBodyLoader from '../body_loader';

import SensorDatasetParser from '../../../helpers/sensor_dataset_parser';

const MBinaryChart = (props) => {
    const {
        dataset,
        unit,
        displayValue,
        height,
        labelFormatter,
    } = props;

    if (_.isUndefined(dataset)) return <MBodyLoader height={height} />;
    if (!_.isArray(dataset) || _.isEmpty(dataset)) return <MDataEmptyState height={height} />;

    const filteredDataset = dataset.filter((data) => {
        if (_.isUndefined(displayValue)) return true;

        return data.value === displayValue;
    });

    if (_.isEmpty(filteredDataset)) {
        return (
            <MDataEmptyState
                title="¡Todo se encuentra en orden!"
                subtitle="Cuando hayan alertas aparecerán aquí"
                height={height}
                icon={<HiBadgeCheck />}
            />
        );
    }

    const tableRows = filteredDataset.map((data) => {
        return (
            <tr>
                <td>
                    { unit }
                </td>
                <td style={{ paddingLeft: '1rem' }}>
                    { labelFormatter(data.label) }
                </td>
            </tr>
        );
    });

    return (
        <div style={{ height, overflowY: 'scroll' }}>
            <table>
                <thead>
                    <tr>
                        <th>Evento</th>
                        <th>Fecha</th>
                    </tr>
                </thead>
                <tbody>
                    { tableRows }
                </tbody>
            </table>
        </div>
    );
};

MBinaryChart.propTypes = {
    dataset: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.number,
    })),
    unit: PropTypes.string,
    displayValue: PropTypes.number,
    height: PropTypes.number,
    labelFormatter: PropTypes.func,
};

MBinaryChart.defaultProps = {
    dataset: undefined,
    unit: undefined,
    displayValue: undefined,
    height: 300,
    labelFormatter: SensorDatasetParser.labelFormatter,
};

export default MBinaryChart;
