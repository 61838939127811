import React, { Component } from 'react';

// Adapted from https://codepen.io/pawelqcm/pen/oxPYox

const canvas_style = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: '#fff',
    overflow: 'hidden'
}

// Settings

// sensitivity: how close next node must be to activate connection (in px)
// max_siblings: note that siblings limit is not 'accurate' as the node can actually have more connections than this value that's because the node accepts sibling nodes with no regard to their current connections this is acceptable because potential fix would not result in significant visual difference
// nodes_margin: start margin in pixels between nodes
// mouse_radius: radius in pixels of displayed nodes with the mouse as center.
// max_spread: maximus distance (in px) between nodes


class NetworkBackground extends Component {

    constructor(props) {
      super(props);

      this.state = {
        canvas_id: 'background-auth-canvas',
        settings: {
          sensitivity: 100,
          max_siblings: 10,
          nodes_margin: 50,
          max_spread: 20,
          mouse_radius: 300
        }
      }
    }

    componentDidMount() {
      const { canvas_id, settings } = this.state;
        this.setupBackgroundCanvas(canvas_id, settings);
    }

    setupBackgroundCanvas(canvas_id, settings) {
      
        class Node {
            constructor(x, y, max_siblings, sensitivity, max_spread) {
                this.anchorX = x;
                this.anchorY = y;
                this.max_spread = max_spread;
                this.x = x + Math.random() * max_spread;
                this.y = y + Math.random() * max_spread;
                this.vx = Math.random() * 2 - 1;
                this.vy = Math.random() * 2 - 1;
                this.energy = Math.random() * 100;
                this.radius = Math.random();
                this.siblings = [];
                this.max_siblings = max_siblings;
                this.farthest_sibling_index = 0;
                this.longest_distance = 0;
                this.alpha = 0;
                this.sensitivity = sensitivity;
            }
            drawNode(canvas_context) {
                var color = "rgba(136,152,170, " + this.alpha + ")";
                canvas_context.beginPath();
                canvas_context.arc(this.x, this.y, 2 * this.radius + 2 * this.siblings.length / this.max_siblings, 0, 2 * Math.PI);
                canvas_context.fillStyle = color;
                canvas_context.fill();
            }
            drawConnections(canvas_context) {
              this.siblings.forEach(([sibling, distance]) => {
                var color = "rgba(136,152,170, " + this.alpha + ")";

                canvas_context.beginPath();
                canvas_context.moveTo(this.x, this.y);
                canvas_context.lineTo(sibling.x, sibling.y);
                canvas_context.lineWidth = 1 - distance / this.sensitivity;
                canvas_context.strokeStyle = color;
                canvas_context.stroke();
              })
            }
            moveNode() {
                this.energy -= 2;
                if (this.energy < 1) {
                    this.energy = Math.random() * 100;
                    if (this.x - this.anchorX < -this.max_spread) {
                        this.vx = Math.random() * 2;
                    }
                    else if (this.x - this.anchorX > this.max_spread) {
                        this.vx = Math.random() * -2;
                    }
                    else {
                        this.vx = Math.random() * -2;
                    }
                    if (this.y - this.anchorY < -this.max_spread) {
                        this.vy = Math.random() * 2;
                    }
                    else if (this.y - this.anchorY > this.max_spread) {
                        this.vy = Math.random() * -2;
                    }
                    else {
                        this.vy = Math.random() * 4 - 2;
                    }
                }
                this.x += this.vx * this.energy / 100;
                this.y += this.vy * this.energy / 100;
            }
            addSibling(new_sibling, distance) {

              if (this.siblings.length < this.max_siblings) {
                this.siblings.push([new_sibling, distance]);
  
                if (this.longest_distance < distance) {
                  this.longest_distance = distance;
                  this.farthest_sibling_index = this.siblings.length - 1;
                }
              } else if (this.longest_distance > distance) {
                this.siblings.splice(this.farthest_sibling_index, 1);
                this.siblings.push([new_sibling, distance]);
              }
            }
            deleteSiblings() {
              this.siblings = []
            }
        }
      
        function initNodes(canvas, canvas_context, settings) {
          canvas_context.clearRect(0, 0, canvas.width, canvas.height);
          nodes = [];
          const { nodes_margin, max_siblings, sensitivity, max_spread } = settings;

          for (var i = nodes_margin; i < canvas.width; i += nodes_margin) {
            for (var j = nodes_margin; j < canvas.height; j += nodes_margin) {
              nodes.push(new Node(i, j, max_siblings, sensitivity, max_spread));
            }
          }
        }
      
        function redrawScene(canvas, canvas_context, mouse, settings) {
          setCanvasFullScreen();
          canvas_context.clearRect(0, 0, canvas.width, canvas.height);
          findSiblings(settings.sensitivity);

          nodes.forEach(node => {
            var distance = calcDistance(mouse, node);
            if (distance < mouse.radius) {
              node.alpha = 1 - distance / mouse.radius;
              node.drawNode(canvas_context);
              node.drawConnections(canvas_context);
            } else {
              node.alpha = 0;
            }
            node.moveNode();
          })
          
          window.requestAnimationFrame(() => {
            redrawScene(canvas, canvas_context, mouse, settings);
          });
        }
      
        function calcDistance(node1, node2) {
          return Math.sqrt(Math.pow(node1.x - node2.x, 2) + (Math.pow(node1.y - node2.y, 2)));
        }
      
        function findSiblings(sensitivity) {

          nodes.forEach(node1 => {
            node1.deleteSiblings();

            nodes.forEach(node2 => {
              if (node1 === node2) return;

              const distance = calcDistance(node1, node2);

              if (distance >= sensitivity) return;

              node1.addSibling(node2, distance);
            })
          });
        }
      
        function initHandlers(canvas) {
          document.addEventListener('resize', setCanvasFullScreen, false);
          canvas.addEventListener('mousemove', mousemoveHandler, false);
        }
      
        function setCanvasFullScreen() {
          canvas.width = window.innerWidth;
          canvas.height = window.innerHeight;
        }
      
        function mousemoveHandler(e) {
          mouse.x = e.clientX;
          mouse.y = e.clientY;
        }
      
        var nodes = [];
      
        var canvas = document.getElementById(canvas_id);
        if (!canvas) {
          console.log('NetworkBackground - Canvas not found');
          return;
        }

        setCanvasFullScreen();
        
        var mouse = {
          x: canvas.width / 2,
          y: canvas.height / 2,
          radius: settings.mouse_radius
        };

        var ctx = canvas.getContext('2d');
        if (!ctx) {
          console.log('NetworkBackground - Your browser does not support canvas');
        }
      
        initHandlers(canvas);
        initNodes(canvas, ctx, settings);
        redrawScene(canvas, ctx, mouse, settings);
    }

    render() {
        var { canvas_id } = this.state;
        return (
            <canvas style={canvas_style} id={canvas_id}></canvas>
        )
    }
}

export default NetworkBackground;