import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';
import { isUndefined, isEqual, isEmpty } from 'underscore';

import './style.sass';

class MeasureCardContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            header: props.header,
        };
    }

    static getDerivedStateFromProps(props, state) {
        const update = {};

        const { header } = props;

        if (!isUndefined(header) && !isEqual(header, state.header)) {
            update.header = header;
        }

        return Object.keys(update).length ? update : null;
    }

    render() {
        const { header } = this.state;
        let { children } = this.props;

        children = isEmpty(children) ? [children] : children;

        return (
            <div className="measure-card-container">
                {header}
                <Row noGutters>{children}</Row>
            </div>
        );
    }
}

MeasureCardContainer.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object, PropTypes.bool]).isRequired,
    header: PropTypes.string,
};

MeasureCardContainer.defaultProps = {
    header: '',
};

export default MeasureCardContainer;
