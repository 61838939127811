import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';

import DatePicker from 'react-datepicker';

import {
    Row,
    Col,
    ButtonGroup,
} from 'reactstrap';
import {
    BiReset,
} from 'react-icons/bi';

import MTimeButton from './time_button';

import 'react-datepicker/dist/react-datepicker.css';

import {
    dateToStartOfDay,
    dateToEndOfDay,
    dateToSecondEpoch,
    addDay,
} from '../../helpers/date_helper';

import './style.scss';

class MDatasetTimeSpan extends PureComponent {
    constructor(props) {
        super(props);

        const defaultStartDate = dateToStartOfDay(addDay(new Date(), -1));
        const defaultEndDate = dateToEndOfDay(new Date());

        this.state = {
            defaultStartDate,
            defaultEndDate,
            startDate: defaultStartDate,
            endDate: defaultEndDate,
        };

        this.publishOnChange = this.publishOnChange.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.handleClearDates = this.handleClearDates.bind(this);
    }

    componentDidMount() {
        this.publishOnChange();
    }

    publishOnChange(start, end) {
        const { onChange } = this.props;
        if (!_.isFunction(onChange)) return;

        const {
            defaultStartDate,
            defaultEndDate,
        } = this.state;

        const startDateEpoch = dateToSecondEpoch(start || defaultStartDate);
        const endDateEpoch = dateToSecondEpoch(end || defaultEndDate);

        onChange(startDateEpoch, endDateEpoch);
    }

    handleStartDateChange(newStartDate) {
        this.setState({
            startDate: dateToStartOfDay(newStartDate),
            endDate: undefined,
        });
    }

    handleEndDateChange(newEndDate) {
        const { startDate } = this.state;

        this.setState({
            endDate: dateToEndOfDay(newEndDate),
        });

        this.publishOnChange(startDate, newEndDate);
    }

    handleClearDates() {
        const {
            defaultStartDate,
            defaultEndDate,
            startDate,
            endDate,
        } = this.state;

        if (!_.isUndefined(startDate) && _.isUndefined(endDate)) {
            this.setState({
                startDate: undefined,
            });
            return;
        }

        this.setState({
            startDate: defaultStartDate,
            endDate: defaultEndDate,
        });

        this.publishOnChange();
    }

    render() {
        const {
            defaultEndDate,
            startDate,
            endDate,
        } = this.state;

        return (
            <Row>
                <Col xs={12} md={8} className="mdataset-time-span">
                    <Row>
                        <Col>
                            Rango de fecha
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ButtonGroup>
                                <DatePicker
                                    selected={startDate}
                                    onChange={this.handleStartDateChange}
                                    selectsStart
                                    withPortal
                                    fixedHeight
                                    startDate={startDate}
                                    endDate={endDate}
                                    dateFormat="d 'de' MMMM yyyy"
                                    maxDate={defaultEndDate}
                                    customInput={<MTimeButton className="first" text="Inicio" />}
                                    calendarClassName="measure-calendar"
                                />
                                <DatePicker
                                    selected={endDate}
                                    onChange={this.handleEndDateChange}
                                    selectsEnd
                                    withPortal
                                    fixedHeight
                                    startDate={startDate}
                                    endDate={endDate}
                                    disabled={
                                        !_.isDate(startDate)
                                    }
                                    dateFormat="d 'de' MMMM yyyy"
                                    minDate={startDate}
                                    maxDate={
                                        _.min([addDay(startDate, 6), defaultEndDate])
                                    }
                                    customInput={<MTimeButton className="last" text="Término" />}
                                    calendarClassName="measure-calendar"
                                />
                                <button
                                    type="button"
                                    className="mdataset-time-clear-button"
                                    onClick={this.handleClearDates}
                                    disabled={
                                        !_.isDate(startDate) && !_.isDate(endDate)
                                    }
                                >
                                    &nbsp;&nbsp;X&nbsp;&nbsp;
                                </button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

MDatasetTimeSpan.propTypes = {
    onChange: PropTypes.func.isRequired,
};

export default MDatasetTimeSpan;
