import { Measure } from '@measure-iot/api';

import { isEmpty, isUndefined } from 'underscore';

import * as Authenticator from '../helpers/authenticator';
import * as ReduxActions from './types';

import * as UserActions from '../reducers/users/actions';
import * as OrganizationActions from '../reducers/organizations/actions';
import * as HubActions from '../reducers/hubs/actions';
import * as DeviceActions from '../reducers/devices/actions';
import * as SensorActions from '../reducers/sensors/actions';
import * as ContactActions from '../reducers/contacts/actions';

const measureApi = new Measure(process.env.REACT_APP_API_HOST, '/v2');

const requireAuthToken = () => {
    const authToken = Authenticator.getAuthToken();

    if (isUndefined(authToken)) {
        throw new Error('Missing authentication token');
    }

    measureApi.setAuthToken(`Bearer ${authToken}`);
};

// ===================================
// AUTHENTICATION
// ===================================

export const triggerLoginWithCredentials = (username, password, cancelHandler) => {
    return async (dispatch) => {
        try {
            const user = await measureApi.authenticate(username, password, cancelHandler);
            const token = measureApi.headers.Authorization;
            Authenticator.setAuthToken(token);

            UserActions.dispatchCurrentUserUpdate(dispatch, user);
        } catch (error) {
            UserActions.dispatchCurrentUserUpdate(dispatch, null);

            return error;
        }

        return undefined;
    };
};

export const triggerLoginWithToken = (cancelHandler) => {
    return async (dispatch) => {
        try {
            requireAuthToken();

            const user = await measureApi.getMe(cancelHandler);

            UserActions.dispatchCurrentUserUpdate(dispatch, user);
        } catch (error) {
            UserActions.dispatchCurrentUserUpdate(dispatch, null);

            return error;
        }

        return undefined;
    };
};

export const triggerLogout = () => {
    return async (dispatch) => {
        Authenticator.removeAuthToken();

        dispatch({
            type: ReduxActions.APP_CLEAR_STORE,
            payload: undefined,
        });
    };
};

// ===================================
// USERS
// ===================================

export const triggerUserPasswordChange = (body, cancelHandler) => {
    requireAuthToken();

    return async () => {
        try {
            await measureApi.putUserPasswordChange(body, cancelHandler);
        } catch (error) {
            return error;
        }

        return undefined;
    };
};

export const triggerGetAllUsers = (organizationId, cancelHandler) => {
    requireAuthToken();

    return async (dispatch) => {
        try {
            const endpoint = `/users?organization=${organizationId}`;

            const users = await measureApi.getRaw(endpoint, cancelHandler);

            users.forEach((user) => {
                UserActions.dispatchUserUpdate(dispatch, user.id, user);
            });
        } catch (error) {
            return error;
        }

        return undefined;
    };
};

export const triggerPostUser = (body, cancelHandler) => {
    requireAuthToken();

    return async (dispatch) => {
        try {
            const user = await measureApi.postUser(body, cancelHandler);
            const { id: userId } = user;
            UserActions.dispatchUserUpdate(dispatch, userId, user);
        } catch (error) {
            return error;
        }

        return undefined;
    };
};

export const triggerPutUser = (userId, body, cancelHandler) => {
    requireAuthToken();

    return async (dispatch) => {
        try {
            const user = await measureApi.putUser(userId, body, cancelHandler);
            UserActions.dispatchUserUpdate(dispatch, userId, user);
        } catch (error) {
            return error;
        }

        return undefined;
    };
};

export const triggerDeleteUser = (userId, cancelHandler) => {
    requireAuthToken();

    return async (dispatch) => {
        try {
            await measureApi.deleteUser(userId, cancelHandler);
            UserActions.dispatchUserUpdate(dispatch, userId, undefined);
        } catch (error) {
            return error;
        }

        return undefined;
    };
};

// ===================================
// ORGANZATIONS
// ===================================

export const triggerGetAllOrganizations = (cancelHandler) => {
    requireAuthToken();

    return async (dispatch) => {
        try {
            const organizations = await measureApi.getOrganizations(cancelHandler);

            organizations.forEach((organization) => {
                OrganizationActions.dispatchOrganizationUpdate(dispatch, organization.id, organization);
            });
        } catch (error) {
            return error;
        }

        return undefined;
    };
};

export const triggerGetOrganization = (organizationId, cancelHandler) => {
    requireAuthToken();

    return async (dispatch) => {
        OrganizationActions.dispatchOrganizationUpdate(dispatch, organizationId, undefined);

        try {
            const organization = await measureApi.getOrganization(organizationId, cancelHandler);
            OrganizationActions.dispatchOrganizationUpdate(dispatch, organizationId, organization);
        } catch (error) {
            return error;
        }

        return undefined;
    };
};

export const triggerPostOrganization = (body, cancelHandler) => {
    requireAuthToken();

    return async (dispatch) => {
        try {
            const organization = await measureApi.postOrganization(body, cancelHandler);
            const { id: organizationId } = organization;
            OrganizationActions.dispatchOrganizationUpdate(dispatch, organizationId, organization);
        } catch (error) {
            return error;
        }

        return undefined;
    };
};

export const triggerPutOrganization = (organizationId, body, cancelHandler) => {
    requireAuthToken();

    return async (dispatch) => {
        try {
            const organization = await measureApi.putOrganization(organizationId, body, cancelHandler);
            OrganizationActions.dispatchOrganizationUpdate(dispatch, organizationId, organization);
        } catch (error) {
            return error;
        }

        return undefined;
    };
};

export const triggerDeleteOrganization = (organizationId, cancelHandler) => {
    requireAuthToken();

    return async (dispatch) => {
        try {
            await measureApi.deleteOrganization(organizationId, cancelHandler);
            OrganizationActions.dispatchOrganizationUpdate(dispatch, organizationId, undefined);
        } catch (error) {
            return error;
        }

        return undefined;
    };
};

// ===================================
// HUBS
// ===================================

export const triggerGetAllHubs = (organizationId, cancelHandler) => {
    requireAuthToken();

    return async (dispatch) => {
        try {
            const hubs = await measureApi.getHubs(organizationId, cancelHandler);

            hubs.forEach((hub) => {
                HubActions.dispatchHubUpdate(dispatch, hub.id, hub);
            });
        } catch (error) {
            return error;
        }

        return undefined;
    };
};

export const triggerGetHub = (hubId, cancelHandler) => {
    requireAuthToken();

    return async (dispatch) => {
        try {
            const hub = await measureApi.getHub(hubId, cancelHandler);
            HubActions.dispatchHubUpdate(dispatch, hubId, hub);
        } catch (error) {
            return error;
        }

        return undefined;
    };
};

export const triggerPostHub = (body, cancelHandler) => {
    requireAuthToken();

    return async (dispatch) => {
        try {
            const hub = await measureApi.postHub(body, cancelHandler);
            const { id: hubId } = hub;
            HubActions.dispatchHubUpdate(dispatch, hubId, hub);
        } catch (error) {
            return error;
        }

        return undefined;
    };
};

export const triggerPutHub = (hubId, body, cancelHandler) => {
    requireAuthToken();

    return async (dispatch) => {
        try {
            const hub = await measureApi.putHub(hubId, body, cancelHandler);
            HubActions.dispatchHubUpdate(dispatch, hubId, hub);
        } catch (error) {
            return error;
        }

        return undefined;
    };
};

export const triggerDeleteHub = (hubId, cancelHandler) => {
    requireAuthToken();

    return async (dispatch) => {
        try {
            await measureApi.deleteHub(hubId, cancelHandler);
            HubActions.dispatchHubUpdate(dispatch, hubId, undefined);
        } catch (error) {
            return error;
        }

        return undefined;
    };
};

// ===================================
// HUB REPORTS
// ===================================

export const triggerClearHubReports = () => {
    return HubActions.dispatchHubReportClearAll;
};

export const triggerGetHubReports = (hubId, cancelHandler) => {
    requireAuthToken();

    return async (dispatch) => {
        try {
            const reports = await measureApi.getHubReports(hubId, cancelHandler);

            reports.forEach((report) => {
                HubActions.dispatchHubReportUpdate(dispatch, report.id, report);
            });
        } catch (_) {
            // No data to modify in redux store
        }
    };
};

// ===================================
// HUB CONTACTS
// ===================================

export const triggerClearContacts = () => {
    return ContactActions.dispatchContactClearAll;
};

export const triggerGetUserContacts = (userId, cancelHandler) => {
    requireAuthToken();

    return async (dispatch) => {
        try {
            const contacts = await measureApi.getUserContacts(userId, cancelHandler);

            contacts.forEach((contact) => {
                ContactActions.dispatchContactUpdate(dispatch, contact.id, contact);
            });
        } catch (_) {
            // No data to modify in redux store
        }
    };
};

export const triggerGetHubContacts = (hubId, cancelHandler) => {
    requireAuthToken();

    return async (dispatch) => {
        try {
            const contacts = await measureApi.getHubContacts(hubId, cancelHandler);

            contacts.forEach((contact) => {
                ContactActions.dispatchContactUpdate(dispatch, contact.id, contact);
                ContactActions.dispatchContactSubscriptionUpdate(dispatch, contact.id, true);
            });
        } catch (_) {
            // No data to modify in redux store
        }
    };
};

export const triggerSubscribeHubContact = (hubId, contactId, cancelHandler) => {
    requireAuthToken();

    return async (dispatch) => {
        try {
            ContactActions.dispatchContactSubscriptionUpdate(dispatch, contactId, true);

            await measureApi.postUserContactsSubscribe(contactId, hubId, cancelHandler);
        } catch (_) {
            ContactActions.dispatchContactSubscriptionUpdate(dispatch, contactId, false);
        }
    };
};

export const triggerUnsubscribeHubContact = (hubId, contactId, cancelHandler) => {
    requireAuthToken();

    return async (dispatch) => {
        try {
            ContactActions.dispatchContactSubscriptionUpdate(dispatch, contactId, false);

            await measureApi.postUserContactsUnsubscribe(contactId, hubId, cancelHandler);
        } catch (_) {
            ContactActions.dispatchContactSubscriptionUpdate(dispatch, contactId, true);
        }
    };
};

// ===================================
// DEVICES
// ===================================

export const triggerGetAllDevices = (hubUuid, cancelHandler) => {
    requireAuthToken();

    return async (dispatch) => {
        try {
            const devices = await measureApi.getDevices(hubUuid, cancelHandler);

            devices.forEach((device) => {
                DeviceActions.dispatchDeviceUpdate(dispatch, device.id, device);
            });
        } catch (error) {
            return error;
        }

        return undefined;
    };
};

export const triggerGetDevice = (deviceId, cancelHandler) => {
    requireAuthToken();

    return async (dispatch) => {
        DeviceActions.dispatchDeviceUpdate(dispatch, deviceId, undefined);

        try {
            const device = await measureApi.getDevice(deviceId, cancelHandler);
            DeviceActions.dispatchDeviceUpdate(dispatch, deviceId, device);
        } catch (error) {
            return error;
        }

        return undefined;
    };
};

export const triggerPostDevice = (body, cancelHandler) => {
    requireAuthToken();

    return async (dispatch) => {
        try {
            const device = await measureApi.postDevice(body, cancelHandler);
            const { id: deviceId } = device;
            DeviceActions.dispatchDeviceUpdate(dispatch, deviceId, device);
        } catch (error) {
            return error;
        }

        return undefined;
    };
};

export const triggerPutDevice = (deviceId, body, cancelHandler) => {
    requireAuthToken();

    return async (dispatch) => {
        try {
            const device = await measureApi.putDevice(deviceId, body, cancelHandler);
            DeviceActions.dispatchDeviceUpdate(dispatch, deviceId, device);
        } catch (error) {
            return error;
        }

        return undefined;
    };
};

export const triggerDeleteDevice = (deviceId, cancelHandler) => {
    requireAuthToken();

    return async (dispatch) => {
        try {
            await measureApi.deleteDevice(deviceId, cancelHandler);
            DeviceActions.dispatchDeviceUpdate(dispatch, deviceId, undefined);
        } catch (error) {
            return error;
        }

        return undefined;
    };
};

// ===================================
// DEVICES EXTRAS
// ===================================

export const triggerGetDeviceSignature = (deviceId, cancelHandler) => {
    requireAuthToken();

    return async (dispatch) => {
        try {
            const signature = await measureApi.getDeviceSignature(deviceId, cancelHandler);
            DeviceActions.dispatchDeviceSignatureUpdate(dispatch, deviceId, signature);
        } catch (error) {
            return error;
        }

        return undefined;
    };
};

// ===================================
// DEVICES DATASETS
// ===================================

export const triggerGetDeviceDataset = (deviceId, startDate, endDate, cancelHandler) => {
    requireAuthToken();

    return async (dispatch) => {
        DeviceActions.dispatchDeviceDatasetUpdate(dispatch, deviceId, undefined);

        try {
            const dataset = await measureApi.getDeviceReadings(deviceId, startDate, endDate, cancelHandler);
            DeviceActions.dispatchDeviceDatasetUpdate(dispatch, deviceId, dataset);
        } catch (error) {
            DeviceActions.dispatchDeviceDatasetUpdate(dispatch, deviceId, null);
        }
    };
};

export const triggerGetDeviceLastMessage = (deviceUuid, cancelHandler) => {
    requireAuthToken();

    return async (dispatch = () => {}) => {
        const reading = await measureApi.getDeviceLastReading(deviceUuid, cancelHandler);

        dispatch({
            type: ReduxActions.ENTITY_SET_DEVICE_LAST_MESSAGE,
            payload: {
                deviceId: deviceUuid,
                reading,
            },
        });

        return reading;
    };
};

export const triggerSetDatasetTimeSpan = (startEpoch, endEpoch) => {
    return async (dispatch = () => {}) => {
        DeviceActions.dispatchDeviceDatasetTimeSpanUpdate(dispatch, startEpoch, endEpoch);
    };
};

// ===================================
// SENSORS
// ===================================

export const triggerGetAllSensors = (deviceId, cancelHandler) => {
    requireAuthToken();

    return async (dispatch) => {
        try {
            const sensors = await measureApi.getSensors(deviceId, cancelHandler);

            sensors.forEach((sensor) => {
                SensorActions.dispatchSensorUpdate(dispatch, sensor.id, sensor);
            });
        } catch (_) {
            // No data to modify in redux store
        }
    };
};

export const triggerGetSensor = (sensorId, cancelHandler) => {
    return async (dispatch) => {
        try {
            const sensor = await measureApi.getSensor(sensorId, cancelHandler);
            SensorActions.dispatchSensorUpdate(dispatch, sensorId, sensor);
        } catch (error) {
            return error;
        }

        return undefined;
    };
};

export const triggerPostSensor = (body, cancelHandler) => {
    requireAuthToken();

    return async (dispatch) => {
        try {
            const sensor = await measureApi.postSensor(body, cancelHandler);
            const { id: sensorId } = sensor;
            SensorActions.dispatchSensorUpdate(dispatch, sensorId, sensor);
        } catch (error) {
            return error;
        }

        return undefined;
    };
};

export const triggerPutSensor = (sensorId, body, cancelHandler) => {
    requireAuthToken();

    return async (dispatch) => {
        try {
            const sensor = await measureApi.putSensor(sensorId, body, cancelHandler);
            SensorActions.dispatchSensorUpdate(dispatch, sensorId, sensor);
        } catch (error) {
            return error;
        }

        return undefined;
    };
};

export const triggerDeleteSensor = (sensorId, cancelHandler) => {
    requireAuthToken();

    return async (dispatch) => {
        try {
            await measureApi.deleteSensor(sensorId, cancelHandler);
            SensorActions.dispatchSensorUpdate(dispatch, sensorId, undefined);
        } catch (_) {
            // No data to modify in redux store
        }
    };
};

// ===================================
// SENSORS CALIBRATIONS
// ===================================

export const triggerPostSensorCalibration = (body, cancelHandler) => {
    requireAuthToken();

    return async () => {
        try {
            await measureApi.postSensorCalibration(body, cancelHandler);
        } catch (error) {
            return error;
        }

        return undefined;
    };
};

export const triggerPutSensorCalibration = (id, body, cancelHandler) => {
    requireAuthToken();

    return async () => {
        try {
            await measureApi.putSensorCalibration(id, body, cancelHandler);
        } catch (error) {
            return error;
        }

        return undefined;
    };
};

export const triggerDeleteSensorCalibration = (id, cancelHandler) => {
    requireAuthToken();

    return async () => {
        try {
            await measureApi.deleteSensorCalibration(id, cancelHandler);
        } catch (error) {
            return error;
        }

        return undefined;
    };
};

// ===================================
// CONTACT FORM
// ===================================

export const triggerPostContactForm = (contactInfo, cancelHandler) => {
    const endpoint = '/mailer/contact';

    if (isEmpty(contactInfo)) {
        throw new Error('Data is malformed');
    }
    const body = {
        contactInfo,
    };
    return async () => {
        return measureApi.postRaw(endpoint, body, cancelHandler).catch(() => {
            throw new Error('Failed notifying measure');
        });
    };
};

// ===================================
// ADMIN
// ===================================


