import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class MeasureVerticalSpacer extends PureComponent {
    render() {
        const { height, className } = this.props;

        return (
            <div style={{ height }} className={className} />
        );
    }
}

MeasureVerticalSpacer.propTypes = {
    height: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    className: PropTypes.string,
};

MeasureVerticalSpacer.defaultProps = {
    height: '4rem',
    className: '',
};

export default MeasureVerticalSpacer;
