import React from 'react';
import PropTypes from 'prop-types';
import {
    Row,
    Col,
} from 'reactstrap';

import MSideMenu from '../mside_menu';

const MLayout = (props) => {
    const {
        children,
    } = props;

    return (
        <Row noGutters>
            <Col xs={12} md={2}>
                <MSideMenu />
            </Col>
            <Col xs={12} md={10} style={{ padding: '1rem' }}>
                {children}
            </Col>
        </Row>
    );
};

MLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default MLayout;
