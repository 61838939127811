import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import { connect } from 'react-redux';
import { MeasureRequestHandler } from '@measure-iot/api';

import MeasurePropTypes from '../../../propTypes';
import * as Actions from '../../../actions';
import unwrapper from '../../../helpers/unwrapper';

import MCard from '../mcard_base';
import MSwitch from '../../mforms/mswitch';
import MSelect from '../../mforms/mselect';

class MDeviceConfig extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            gpsStateEnabled: unwrapper(props, 'device.hardware.desired.gps.enabled'),
            deviceStatus: unwrapper(props, 'device.status'),
            deviceSleepTime: `${unwrapper(props, 'device.hardware.desired.sleepTime')}`,
        };

        this.cancelToken = new MeasureRequestHandler();

        this.triggerGetDevice = this.triggerGetDevice.bind(this);
        this.tiggerUpdateGpsStatus = this.tiggerUpdateGpsStatus.bind(this);
        this.triggerUpdateDeviceStatus = this.triggerUpdateDeviceStatus.bind(this);
        this.triggerUpdateDeviceSleepTime = this.triggerUpdateDeviceSleepTime.bind(this);
    }

    async triggerGetDevice() {
        const { deviceId, triggerGetDevice } = this.props;

        await triggerGetDevice(deviceId, this.cancelHandler);
    }

    async tiggerUpdateGpsStatus(event) {
        const { checked } = event.target;

        this.setState({
            gpsStateEnabled: checked,
        });

        this.triggerPutDevice({ gpsEnabled: checked });
    }

    async triggerUpdateDeviceStatus(event) {
        const { id } = event.target;

        this.setState({
            deviceStatus: id,
        });

        this.triggerPutDevice({ status: id });
    }

    async triggerUpdateDeviceSleepTime(event) {
        const { id } = event.target;

        this.setState({
            deviceSleepTime: id,
        });

        this.triggerPutDevice({ sleepTime: parseInt(id, 10) });
    }

    async triggerPutDevice(data) {
        const { deviceId, triggerPutDevice } = this.props;

        triggerPutDevice(deviceId, data, this.cancelToken);
    }

    componentWillUnmount() {
        this.cancelToken.cancel();
    }

    renderGpsSwitch() {
        const { gpsStateEnabled } = this.state;

        return (
            <MSwitch
                id="gps-switch"
                label="Localización GPS"
                checked={gpsStateEnabled}
                onChange={this.tiggerUpdateGpsStatus}
            />
        );
    }

    renderDeviceStatus() {
        const { deviceStatus } = this.state;

        return (
            <MSelect
                label="Telemetría"
                checkedOption={deviceStatus}
                options={[
                    { id: 'enabled', text: 'Activado' },
                    { id: 'quarantined', text: 'Cuarentena' },
                    { id: 'disabled', text: 'Desactivado' },
                ]}
                onChange={this.triggerUpdateDeviceStatus}
            />
        );
    }

    renderDeviceSleepTime() {
        const { deviceSleepTime } = this.state;

        return (
            <MSelect
                label="Tiempo entre mediciones"
                checkedOption={deviceSleepTime}
                options={[
                    { id: '300', text: '5 mins' },
                    { id: '1800', text: '30 mins' },
                    { id: '7200', text: '2 hrs' },
                ]}
                onChange={this.triggerUpdateDeviceSleepTime}
            />
        );
    }

    render() {
        return (
            <MCard title="Configuración" expandable>
                La nueva configuración se actualizará en el dispositivo la siguiente vez que éste envíe telemetría
                por lo que puede demorar según el tiempo entre mediciones.<br /><br />

                { this.renderDeviceStatus() }

                { this.renderGpsSwitch() }

                { this.renderDeviceSleepTime() }
            </MCard>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const { deviceId } = ownProps;
    const { devices } = state.devices;

    const newProps = {};

    if (_.isObject(devices) && !_.isEmpty(devices)) {
        newProps.device = devices[deviceId];
    }

    return newProps;
};

MDeviceConfig.propTypes = {
    deviceId: PropTypes.string.isRequired,

    // Redux
    device: MeasurePropTypes.DEVICE,

    // Actions
    triggerGetDevice: PropTypes.func.isRequired,
    triggerPutDevice: PropTypes.func.isRequired,
};

MDeviceConfig.defaultProps = {
    device: undefined,
};

export default connect(mapStateToProps, Actions)(MDeviceConfig);
