/* eslint-disable react/jsx-indent */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import { connect } from 'react-redux';
import { Row, Col, Badge } from 'reactstrap';
import { MeasureRequestHandler } from '@measure-iot/api';

import * as Actions from '../../actions';
import MeasurePropTypes from '../../propTypes';

import MCard from '../../mcomponents/mcards/mcard_base';
import MLoader from '../../mcomponents/mloader';

import MForm from '../../mcomponents/mform';
import PASSWORD_FIELDS from '../../mcomponents/mform/fields/user_password_change';

// eslint-disable-next-line react/prefer-stateless-function
class MProfilePage extends Component {
    constructor(props) {
        super(props);

        this.triggerPasswordUpdate = this.triggerPasswordUpdate.bind(this);
        this.cancelToken = new MeasureRequestHandler();
    }

    componentDidMount() {
        this.loadOrganization();
    }

    componentDidUpdate(prevProps) {
        const { currentUser } = this.props;

        if (prevProps.currentUser !== currentUser) {
            this.loadOrganization();
        }
    }

    loadOrganization() {
        const { triggerGetOrganization, currentUser } = this.props;

        if (_.isEmpty(currentUser)) return;

        triggerGetOrganization(currentUser.organizationId, this.cancelToken);
    }

    async triggerPasswordUpdate(event) {
        const { triggerUserPasswordChange } = this.props;

        const { oldPassword, password } = event;

        const body = {
            oldPassword,
            password,
        };

        const error = await triggerUserPasswordChange(body, this.cancelToken);

        if (_.isUndefined(error)) {
            return undefined;
        }

        if (error.message === "Failed updating the User's password") {
            return {
                message: 'La contraseña actual es incorrecta',
            };
        }

        if (error.message === '"password" must be minimum 8 chars and must contain one uppercase, one lowercase and one number') {
            return {
                message: 'La contraseña debe ser de largo mayor a 8 y contener una mayúscula, una minúscula y un número.',
            };
        }

        return error;
    }

    static renderLoader() {
        return (
            <Col className="text-center">
                <MLoader size="md" centered />
            </Col>
        );
    }

    render() {
        const { currentUser, currentOrganization } = this.props;

        if (_.isUndefined(currentUser) || _.isUndefined(currentOrganization)) {
            return MProfilePage.renderLoader();
        }

        const fullName = `${currentUser.name} ${currentUser.lastname}`;

        return (
            <Row>
                <Col xs={12}>
                    <MCard title="Perfil" subtitle="Tu usuario" highlight>
                        <Row>
                            <Col xs={12} md={6}>
                                <Row>
                                    <Col xs={12} className="text-center">
                                        <MCard>
                                            <h4><Badge color="primary" pill>{fullName}</Badge></h4>
                                        </MCard>
                                        <br />
                                    </Col>
                                    <Col xs={12} className="text-center">
                                        <MCard>
                                            <h4><Badge color="primary" pill>{currentUser.email}</Badge></h4>
                                        </MCard>
                                        <br />
                                    </Col>
                                    <Col xs={12} className="text-center">
                                        <MCard>
                                            <h4><Badge color="primary" pill>{currentOrganization.name}</Badge></h4>
                                        </MCard>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} md={6}>
                                <MCard title="Cambiar Contraseña">
                                    <MForm
                                        fields={PASSWORD_FIELDS}
                                        onSubmit={this.triggerPasswordUpdate}
                                        submitButtonText="Actualizar"
                                    />
                                </MCard>
                            </Col>
                        </Row>
                    </MCard>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = (state) => {
    const { currentUser } = state.users;
    const { organizations } = state.organizations;

    const newProps = {};

    newProps.currentUser = currentUser;

    if (_.isObject(organizations) && !_.isEmpty(organizations)) {
        const { organizationId } = currentUser;
        newProps.currentOrganization = organizations[organizationId];
    }

    return newProps;
};

MProfilePage.propTypes = {
    currentUser: MeasurePropTypes.USER.isRequired,
    currentOrganization: MeasurePropTypes.ORGANIZATION,

    triggerGetOrganization: PropTypes.func.isRequired,
    triggerUserPasswordChange: PropTypes.func.isRequired,
};

MProfilePage.defaultProps = {
    currentOrganization: undefined,
};

export default connect(mapStateToProps, Actions)(MProfilePage);
