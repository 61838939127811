import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import { connect } from 'react-redux';
import { MeasureRequestHandler } from '@measure-iot/api';
import { Col, Row } from 'reactstrap';

import MeasurePropTypes from '../../../propTypes';
import * as Actions from '../../../actions';

import MLoader from '../../mloader';
import MCard from '../mcard_base';
import MDataLineChart from '../../mdata_charts/line_chart';
import MDataMap from '../../mdata_charts/map';
import MBinaryChart from '../../mdata_charts/binary_chart';
import MHourmeter from '../../mdata_charts/hourmeter';
import MDatasetSelector from '../../mdata_charts/dataset_selector';

class MSensorCard extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            selectedReading: undefined,
        };

        this.cancelToken = new MeasureRequestHandler();
        this.handleReadingSelected = this.handleReadingSelected.bind(this);
    }

    handleReadingSelected(reading) {
        this.setState({
            selectedReading: reading,
        });
    }

    static renderLoader() {
        return (
            <Col className="text-center" style={{ marginTop: '45vh' }}>
                <MLoader size="md" centered />
            </Col>
        );
    }

    static renderEmptyState() {
        return (
            <Col>
                <div className="mhubs-page-container-title">No encontramos lo que estás buscando</div>
                <div className="mhubs-page-container-subtitle">Si el problema persiste avísanos</div>
            </Col>
        );
    }

    render() {
        const { sensor, dataset } = this.props;
        const { selectedReading } = this.state;

        if (_.isUndefined(sensor)) return MSensorCard.renderLoader();
        if (_.isNull(sensor)) return MSensorCard.renderEmptyState();

        const {
            id,
            name,
            additionalInfo,
            dataVariable,
        } = sensor;

        const {
            name: sensorType,
            unit,
        } = dataVariable;

        let body = null;

        const bodyHeight = 300;

        if (sensorType === 'POSITION') {
            body = (
                <Row noGutters>
                    <Col xs={12} md={9}>
                        <MDataMap
                            dataset={dataset}
                            currentReading={selectedReading}
                            height={bodyHeight}
                        />
                    </Col>
                    <Col xs={12} md={3}>
                        <MDatasetSelector
                            dataset={dataset}
                            height={bodyHeight}
                            currentReading={selectedReading}
                            onClick={this.handleReadingSelected}
                        />
                    </Col>
                </Row>
            );
        } else if (sensorType === 'HOURMETER') {
            body = (
                <MHourmeter
                    dataset={dataset}
                    dataVariable={dataVariable}
                    unit={unit}
                    height={bodyHeight}
                />
            );
        } else if (sensorType === 'BINARY') {
            body = (
                <MBinaryChart
                    dataset={dataset}
                    unit={unit}
                    height={bodyHeight}
                    displayValue={1}
                />
            );
        } else {
            body = (
                <MDataLineChart
                    dataset={dataset}
                    unit={unit}
                    height={bodyHeight}
                />
            );
        }

        return (
            <Col xs={12} md={6}>
                <MCard
                    key={id}
                    title={name}
                    subtitle="Sensor"
                >
                    { additionalInfo }
                    { body }
                </MCard>
            </Col>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const { sensorId } = ownProps;
    const { sensors } = state.sensors;

    const newProps = {};

    if (_.isObject(sensors) && !_.isEmpty(sensors)) {
        newProps.sensor = sensors[sensorId];
    }

    return newProps;
};

MSensorCard.propTypes = {
    sensorId: PropTypes.string.isRequired,

    dataset: PropTypes.any,

    // Redux
    sensor: MeasurePropTypes.SENSOR,
};

MSensorCard.defaultProps = {
    sensor: undefined,
};

export default connect(mapStateToProps, Actions)(MSensorCard);
