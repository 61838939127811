import { isArray, isUndefined, isEmpty, last, isObject } from 'underscore';

import * as ReduxActions from '../actions/types';

/*
 * This function inserts into the old payload the new elements
 * if an old object with the same key is present it will get
 * replaced with the new one
 */
const mergeObjectArraysByKey = (oldPayload, payload, key) => {
    if (isEmpty(key)) {
        throw new Error('Key cannot be empty');
    }

    if (isUndefined(payload) || !isArray(payload)) {
        return payload;
    }

    if (!isArray(oldPayload)) {
        return payload;
    }

    const mappedOldPayload = oldPayload.reduce((total, element) => {
        const elementKey = element[key];
        return {
            ...total,
            [elementKey]: element,
        };
    }, {});

    const mappedPayload = payload.reduce((total, element) => {
        const elementKey = element[key];
        return {
            ...total,
            [elementKey]: element,
        };
    }, mappedOldPayload);

    return Object.values(mappedPayload);
};

const removeAllObjectsWithKey = (oldPayload, key, keyValue) => {
    if (!isArray(oldPayload) || isEmpty(key) || isEmpty(keyValue)) {
        return oldPayload;
    }

    return oldPayload.filter((element) => {
        return element[key] !== keyValue;
    });
};

const removeAllObjectsWithId = (oldPayload, id) => {
    return removeAllObjectsWithKey(oldPayload, 'id', id);
};

const getElementBy = (oldPayload, key, keyValue) => {
    if (!isArray(oldPayload) || isEmpty(key) || isEmpty(keyValue)) {
        return null;
    }

    return oldPayload.find((element) => {
        return element[key] === keyValue;
    });
};

const getElementById = (oldPayload, id) => {
    return getElementBy(oldPayload, 'id', id);
};

const defaultState = {
    currentOrganization: undefined,
    organizations: undefined,

    currentHub: undefined,
    hubs: undefined,

    devices: undefined,

    currentSensor: undefined,
    sensors: undefined,

    currentContact: undefined,
    contacts: undefined,
};

const Reducer = (oldState = defaultState, dispatchedAction) => {
    const { type, payload } = dispatchedAction;

    const newState = { ...oldState };

    switch (type) {

    case ReduxActions.ENTITY_SET_HUB_REPORTS: {
        const { hubId, reports } = payload;

        const ownerHub = getElementById(oldState.hubs, hubId);
        if (isEmpty(ownerHub) || !isArray(reports)) break;

        const sortedReports = reports.sort((a, b) => {
            if (a.startDate < b.startDate) return 1;
            if (a.startDate > b.startDate) return -1;
            return 0;
        });

        ownerHub.reports = mergeObjectArraysByKey(ownerHub.reports, sortedReports, 'id');

        if (isArray(ownerHub.reports) && !isEmpty(ownerHub.reports)) {
            last(ownerHub.reports).isLast = true;
        }

        newState.hubs = mergeObjectArraysByKey(oldState.hubs, [ownerHub], 'id');

        break;
    }

    case ReduxActions.ENTITY_SET_DEVICE_LAST_MESSAGE: {
        const { deviceId, reading } = payload;

        const ownerDevice = getElementById(oldState.devices, deviceId);
        if (isEmpty(ownerDevice) || isEmpty(reading)) break;

        if (isArray(ownerDevice.dataset) && !isEmpty(ownerDevice.dataset)) {
            ownerDevice.dataset.forEach((data) => { data.isLast = false; });
        }

        reading.isLast = true;
        ownerDevice.dataset = mergeObjectArraysByKey(ownerDevice.dataset, [reading], 'epochBucket');

        newState.devices = mergeObjectArraysByKey(oldState.devices, [ownerDevice], 'id');

        break;
    }

    case ReduxActions.ENTITY_SET_DEVICE_HARDWARE: {
        const { deviceId, properties } = payload;

        const ownerDevice = getElementById(oldState.devices, deviceId);
        if (isEmpty(ownerDevice) || !isObject(properties)) break;

        ownerDevice.properties = properties;

        newState.devices = mergeObjectArraysByKey(oldState.devices, [ownerDevice], 'id');

        break;
    }

    case ReduxActions.ENTITY_SET_CONTACTS:
        newState.contacts = payload;
        break;

    default:
        break;
    }

    return newState;
};

export default Reducer;
