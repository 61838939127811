import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import { Link } from 'react-router-dom';

import MeasureLogo from '../mlogo';
import MLoader from '../mloader';

import './style.scss';

const mobileLandscape = 767;

class MeasureSideMenu extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: window.innerWidth > mobileLandscape,
        };

        this.handleMenuToggle = this.handleMenuToggle.bind(this);
        this.handleWindowResize = this.handleWindowResize.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleWindowResize);
        this.handleWindowResize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    }

    handleWindowResize() {
        this.setState({
            isOpen: window.innerWidth > mobileLandscape,
        });
    }

    handleMenuToggle() {
        const { isOpen } = this.state;

        const isMobile = window.innerWidth < mobileLandscape;
        if (!isMobile) return;

        this.setState({
            isOpen: !isOpen,
        });
    }

    renderLinks() {
        const { sections } = this.props;

        const linkElements = [];

        sections.forEach((linkSection) => {
            if (_.isEmpty(linkSection)) return;

            const { title, isLoading, links } = linkSection;

            if (_.isEmpty(links) && !isLoading) return;

            linkElements.push(<li key={title} className="mside-menu-body-list-title">{title}</li>);

            if (isLoading) {
                linkElements.push(<MLoader key={`loader-${title}`} size="xs" />);
                return;
            }

            links.forEach((link) => {
                const {
                    element,
                    url,
                    onClick,
                    icon,
                    text,
                } = link;

                if (!_.isEmpty(element)) {
                    linkElements.push(
                        <li key={element} className="mside-menu-body-list-element">
                            { element }
                        </li>,
                    );
                    return;
                }

                linkElements.push(
                    <li key={text} className="mside-menu-body-list-element">
                        <Link to={url} onClick={onClick}>
                            {icon} {text}
                        </Link>
                    </li>,
                );
            });
        });

        return linkElements;
    }

    render() {
        const { isOpen } = this.state;

        const links = this.renderLinks();

        return (
            <div className="mside-menu">
                <div className="mside-menu-mobile-header">
                    <span className="mside-menu-mobile-logo">
                        <MeasureLogo height="100%" color="blueCloud" />
                    </span>
                    <button
                        type="button"
                        className={`mside-menu-mobile-button ${isOpen ? 'active' : ''}`}
                        onClick={this.handleMenuToggle}
                    >
                        <span />
                        <span />
                        <span />
                    </button>
                </div>
                {
                    isOpen && (
                        <div className="mside-menu-body">
                            <div className="mside-menu-body-logo">
                                <MeasureLogo width="100%" color="blueCloud" />
                            </div>
                            <div className="mside-menu-body-content">
                                <ul className="mside-menu-body-list">
                                    { links }
                                </ul>
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }
}

MeasureSideMenu.propTypes = {
    sections: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        isLoading: PropTypes.bool,
        links: PropTypes.arrayOf(
            PropTypes.shape({
                url: PropTypes.string,
                text: PropTypes.string,
                icon: PropTypes.node,
                onClick: PropTypes.func,
                element: PropTypes.node,
            }),
        ),
    })),
};

MeasureSideMenu.defaultProps = {
    sections: [],
};

export default MeasureSideMenu;
