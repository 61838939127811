import React from 'react';
import PropTypes from 'prop-types';

import MeasureLoaderSvg from '../../assets/img/measure_loader.svg';

import './style.scss';

const MLoader = (props) => {
    const { centered, size } = props;

    let selectedSize;
    switch (size) {
    case 'xs':
        selectedSize = { height: 30, width: 30, fontSize: 25 };
        break;
    case 'xl':
        selectedSize = { height: '20vh', width: '20vh', fontSize: '15vh' };
        break;
    default:
        selectedSize = { height: 60, width: 60, fontSize: 50 };
    }

    const { height, width } = selectedSize;

    const className = centered ? 'mloader text-center' : 'mloader';

    return (
        <div className={className} style={{ height }}>
            <object
                type="image/svg+xml"
                data={MeasureLoaderSvg}
                style={{ height, width }}
            >
                measure-loader
            </object>
        </div>
    );
};

MLoader.propTypes = {
    centered: PropTypes.bool,
    size: PropTypes.oneOf([
        'xs',
        'md',
        'xl',
    ]),
    height: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    width: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};

MLoader.defaultProps = {
    centered: true,
    height: 60,
    width: 60,
    size: 'md',
};

export default MLoader;
