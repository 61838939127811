import React from 'react';
import PropTypes from 'prop-types';

import MLoader from '../mloader';
import MCardEmptyState from '../mcards/mcard_base/empty_state';

const MDataLoader = (props) => {
    const { height } = props;
    return (
        <MCardEmptyState
            height={height}
            icon={<MLoader />}
            subtitle="Cargando..."
        />
    );
};

MDataLoader.propTypes = {
    height: PropTypes.number,
};

MDataLoader.defaultProps = {
    height: 300,
};

export default MDataLoader;
