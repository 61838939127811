import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
    isEmpty,
    isNull,
} from 'underscore';

import MeasureCard from '../../general/measure_card';

import './MetricInteger.sass';

class Metric extends PureComponent {
    constructor(props) {
        super(props);

        this.classId = `${Math.floor(Math.random() * 1000)}`;
        this.metricValueContainerId = `metric-value-container-${this.classId}`;
        this.metricValueId = `metric-value-${this.classId}`;
        this.metricUnitId = `metric-unit-${this.classId}`;
    }

    componentDidMount() {
        // window.addEventListener('resize', this.handleWindowResize);
        this.handleWindowResize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    }

    handleWindowResize() {
        const container = document.getElementById(this.metricValueContainerId);
        const height = container.clientHeight;

        const value = document.getElementById(this.metricValueId);
        if (isNull(value)) return;

        value.style.fontSize = `${height / 2}px`;
        value.style.lineHeight = `${height}px`;

        const unit = document.getElementById(this.metricUnitId);
        if (isNull(unit)) return;

        unit.style.fontSize = `${height / 6}px`;
        unit.style.verticalAlign = 'top';
    }

    renderValue() {
        const { value, unit } = this.props;

        const metricUnit = isEmpty(value) || isEmpty(unit) ? null : <small id={this.metricUnitId} className="metric-unit">&nbsp;{unit}</small>;
        const metricValue = isEmpty(value) ? '-' : value;

        return (
            <div id={this.metricValueContainerId} className="metric-value-container">
                <h1 id={this.metricValueId} className="metric-value">
                    {metricValue}{metricUnit}
                </h1>
            </div>
        );
    }

    render() {
        const { title, subtitle, highlight, active } = this.props;

        return (
            <MeasureCard title={title} subtitle={subtitle} highlight={highlight} active={active}>
                {
                    this.renderValue()
                }
            </MeasureCard>
        );
    }
}


Metric.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    highlight: PropTypes.oneOf([
        true,
        false,
        'warning',
        'error',
    ]),
    active: PropTypes.bool,
};

Metric.defaultProps = {
    title: undefined,
    subtitle: undefined,
    highlight: false,
    active: false,
};

export default Metric;
