import React, { PureComponent } from 'react';
import { Container } from 'reactstrap';
import { Controller, Scene } from 'react-scrollmagic';

import SectionFirst from './section_first';
import SectionImage from './image_section';
import SectionImageMobile from './image_section_mobile';
import SectionDigitalTransformation from './section_stages';
import SectionContactUs from './section_contact';

import './style.sass';

const isMobile = window.innerWidth < 767;

class MeasureLanding extends PureComponent {
    render() {
        return (
            <Container fluid className="section-container" style={{ backgroundColor: '#fafafa' }}>
                <Controller globalSceneOptions={{ triggerHook: 'onLeave' }}>
                    <Scene pin>
                        <div className="section section-expand">
                            <SectionFirst id="first-section" nextButtonId="#second-section" />
                        </div>
                    </Scene>

                    <Scene pin duration={window.innerHeight * (isMobile ? 4 : 3.7)}>
                        <div className="section section-expand">
                            {
                                isMobile ? <SectionImageMobile id="image-section" /> : <SectionImage id="image-section" />
                            }
                        </div>
                    </Scene>

                    <Scene pin>
                        <div className="section">
                            <SectionDigitalTransformation id="third-section" />
                        </div>
                    </Scene>

                    <Scene pin>
                        <div className="section section-auto section-expand">
                            <SectionContactUs />
                        </div>
                    </Scene>
                </Controller>
            </Container>
        );
    }
}

export default MeasureLanding;
