import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import { connect } from 'react-redux';
import { MeasureRequestHandler } from '@measure-iot/api';
import { Row, Col } from 'reactstrap';
import {
    RiVirusFill,
    RiFolder2Fill,
} from 'react-icons/ri';

import MeasurePropTypes from '../../../propTypes';
import * as Actions from '../../../actions';

import MLoader from '../../mloader';
import MCard from '../mcard_base';
import MSensorCard from '../msensor';
import MDataEmptyState from '../../mdata_charts/body_empty_state';
import MDeviceConfig from '../mdevice_config';

class MDeviceCard extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isExpanded: false,
        };

        this.cancelToken = new MeasureRequestHandler();

        this.onDeviceCardExpand = this.onDeviceCardExpand.bind(this);
    }

    componentDidUpdate(prevProps) {
        const { startEpoch, endEpoch } = this.props;
        const { isExpanded } = this.state;

        if ((startEpoch !== prevProps.startEpoch || endEpoch !== prevProps.endEpoch) && isExpanded) {
            this.triggerRequestDeviceMessages();
        }
    }

    async onDeviceCardExpand(isExpanded) {
        this.setState({
            isExpanded,
        });

        if (!isExpanded) return;

        await this.triggerRequestDeviceSensors();
        await this.triggerRequestDeviceMessages();
    }

    async triggerRequestDeviceSensors() {
        const { triggerGetAllSensors, deviceId } = this.props;

        await triggerGetAllSensors(deviceId, this.cancelToken);
    }

    async triggerRequestDeviceMessages() {
        const { device, startEpoch, endEpoch, triggerGetDeviceDataset } = this.props;

        await triggerGetDeviceDataset(device.id, startEpoch, endEpoch, this.cancelToken);
    }

    static renderLoader() {
        return (
            <Col className="text-center" style={{ marginTop: '45vh' }}>
                <MLoader size="md" centered />
            </Col>
        );
    }

    static renderSensors(sensors, datasets) {
        if (_.isUndefined(sensors)) return MDeviceCard.renderLoader();

        if (!_.isArray(sensors)) {
            return <MDataEmptyState />;
        }

        return sensors.map((sensor) => {
            const { id } = sensor;

            return <MSensorCard key={id} sensorId={id} dataset={datasets[id]} />;
        });
    }

    static mapIconSubtitle(status) {
        let icon = null;
        let subtitle = 'Dispositivo';

        if (status === 'quarantined') {
            icon = <RiVirusFill />;
            subtitle = '[CUARENTENA]';
        } else if (status === 'disabled') {
            icon = <RiFolder2Fill />;
            subtitle = '[DESACTIVADO]';
        }

        return { icon, subtitle };
    }

    render() {
        const { device, sensors, datasets } = this.props;
        const { isExpanded } = this.state;

        if (_.isUndefined(device)) return null;

        const { id, name, status } = device;

        const sensorElements = MDeviceCard.renderSensors(sensors, datasets);
        const { icon, subtitle } = MDeviceCard.mapIconSubtitle(status);

        return (
            <MCard
                key={id}
                title={name}
                subtitle={subtitle}
                icon={icon}
                active={status === 'enabled'}
                expanded={isExpanded}
                expandable
                onExpand={this.onDeviceCardExpand}
            >
                <Row>
                    { sensorElements }
                    <Col>
                        <MDeviceConfig deviceId={device.id} />
                    </Col>
                </Row>
            </MCard>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const { deviceId } = ownProps;
    const { devices, datasets, datasetsTimeSpan } = state.devices;
    const { sensors } = state.sensors;

    const newProps = {};

    if (_.isObject(devices) && !_.isEmpty(devices)) {
        newProps.device = devices[deviceId];
    }

    if (_.isObject(sensors) && !_.isEmpty(sensors)) {
        newProps.sensors = Object.values(sensors).filter((sensor) => {
            return sensor.deviceId === deviceId;
        });

        newProps.sensors.sort((lhs, rhs) => {
            return lhs.name.localeCompare(rhs.name, undefined, { numeric: true, sensitivity: 'base' });
        });
    }

    if (!_.isUndefined(datasetsTimeSpan)) {
        newProps.startEpoch = datasetsTimeSpan.startEpoch;
        newProps.endEpoch = datasetsTimeSpan.endEpoch;
    }

    const areSensorsValid = _.isArray(newProps.sensors) && !_.isEmpty(newProps.sensors);
    if (areSensorsValid) {
        newProps.datasets = {};

        const deviceDataset = datasets[deviceId];

        newProps.sensors.forEach((sensor) => {
            const { id } = sensor;

            if (_.isUndefined(deviceDataset) || _.isNull(deviceDataset)) {
                newProps.datasets[id] = deviceDataset;
                return;
            }

            newProps.datasets[id] = deviceDataset.map((message) => {
                const { epochBucket, readings } = message;

                const value = readings[id];

                return {
                    label: epochBucket,
                    rawLabel: epochBucket,
                    value: _.isNumber(value) ? Number(value.toFixed(2)) : value,
                };
            });
        });
    }

    return newProps;
};

MDeviceCard.propTypes = {
    deviceId: PropTypes.string.isRequired,

    // Redux
    device: MeasurePropTypes.DEVICE,
    sensors: MeasurePropTypes.SENSORS,
    datasets: PropTypes.object,
    startEpoch: PropTypes.number,
    endEpoch: PropTypes.number,

    // Actions
    triggerGetAllSensors: PropTypes.func.isRequired,
    triggerGetDeviceDataset: PropTypes.func.isRequired,
};

MDeviceCard.defaultProps = {
    device: undefined,
    sensors: undefined,
    datasets: {},
    startEpoch: undefined,
    endEpoch: undefined,
};

export default connect(mapStateToProps, Actions)(MDeviceCard);
