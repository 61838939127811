import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './style_slider_bar.sass';

class SlideBar extends PureComponent {
    render() {
        const { className, width, text } = this.props;
        return (
            <div className={`measure-slide-bar-container ${className}`}>
                { text && <p className="measure-slide-bar-text">{text}</p>}
                <div className="measure-slide-bar" style={{ width }} />
            </div>
        );
    }
}

SlideBar.propTypes = {
    width: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    className: PropTypes.string,
    text: PropTypes.string,
};

SlideBar.defaultProps = {
    width: '9rem',
    className: '',
    text: undefined,
};

export default SlideBar;
