import _ from 'underscore';
import TYPES from './types';

import { isValidUUID } from '../../helpers/regex_helper';

const defaultState = {
    users: {},
};

const Reducer = (oldState = defaultState, dispatchedAction) => {
    const { type, payload } = dispatchedAction;

    const newState = { ...oldState };

    switch (type) {
    case TYPES.SET: {
        const { userId, user } = payload;
        if (!isValidUUID(userId)) break;

        if (_.isUndefined(user)) {
            delete newState.users[userId];
            break;
        }

        newState.users[userId] = user;
        break;
    }

    case TYPES.SET_CURRENT: {
        const { user } = payload;

        newState.currentUser = user;
        break;
    }

    default:
        break;
    }

    return newState;
};

export default Reducer;
