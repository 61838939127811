import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'underscore';
import { MeasureRequestHandler } from '@measure-iot/api';

import MeasurePropTypes from '../../../propTypes';
import * as Actions from '../../../actions';

import MCard from '../mcard_base';
import MLoader from '../../mloader';
import MDataEmptyState from '../../mdata_charts/body_empty_state';

import JsonTextarea from '../../mjson_textarea';
import MForm from '../../mform';
import DEVICE_FIELDS from '../../mform/fields/device';
import DEVICE_PUT_FIELDS from '../../mform/fields/devicePut';
import unwrapper from '../../../helpers/unwrapper';

import './style.scss';

class MDeviceAdmin extends PureComponent {
    constructor(props) {
        super(props);

        this.cancelToken = new MeasureRequestHandler();
        this.handleActionButtonOnClick = this.handleActionButtonOnClick.bind(this);
        this.triggerPostDevice = this.triggerPostDevice.bind(this);
        this.triggerPutDevice = this.triggerPutDevice.bind(this);
        this.triggerDeleteDevice = this.triggerDeleteDevice.bind(this);
        this.triggerGetDeviceSignature = this.triggerGetDeviceSignature.bind(this);
    }

    componentDidMount() {
        this.triggerGetAllDevices();
    }

    componentDidUpdate(prevProps) {
        const { selectedHubId } = this.props;

        if (selectedHubId !== prevProps.selectedHubId) {
            this.triggerGetAllDevices();
        }
    }

    triggerGetAllDevices() {
        const { selectedHubId, triggerGetAllDevices } = this.props;

        triggerGetAllDevices(selectedHubId, this.cancelToken);
    }

    triggerPostDevice(json) {
        const { triggerPostDevice } = this.props;

        return triggerPostDevice(json, this.cancelToken);
    }

    triggerPutDevice(id, json) {
        const { triggerPutDevice } = this.props;

        return triggerPutDevice(id, json, this.cancelToken);
    }

    triggerDeleteDevice(device) {
        const { triggerDeleteDevice, onSelect } = this.props;

        const { id, name } = device;

        const accepted = window.confirm(`Estás por eliminar el dispositivo "${name}"\n\nEsta acción no puede deshacerse`);
        if (!accepted) return;

        triggerDeleteDevice(id, this.cancelToken);

        if (!_.isFunction(onSelect)) return;

        onSelect();
    }

    triggerGetDeviceSignature(device_id) {
        const { triggerGetDeviceSignature } = this.props;

        triggerGetDeviceSignature(device_id, this.cancelToken);
    }

    handleActionButtonOnClick(id) {
        const { onSelect } = this.props;

        if (!_.isFunction(onSelect)) return;

        onSelect(id);
    }

    static renderLoader() {
        return (
            <MLoader size="md" centered />
        );
    }

    renderDevices() {
        const { devices, selectedDeviceId } = this.props;

        if (_.isUndefined(devices)) return MDeviceAdmin.renderLoader();

        if (_.isEmpty(devices)) return <MDataEmptyState title="No hay dispositivos" />;

        return devices.map((device) => {
            const {
                id,
                mac,
                name,
                status,
                additionalInfo,
                hubId,
                hardware,
                signature,
            } = device;

            let signatureData = (
                <li>
                    Azure Signature<br />
                    <button
                        className="btn btn-primary btn-sm btn-block"
                        type="button"
                        onClick={() => { this.triggerGetDeviceSignature(id); }}
                    >
                        Generate
                    </button>
                    <br />
                </li>
            );

            if (!_.isUndefined(signature) && !_.isEmpty(signature.token)) {
                signatureData = (
                    <li>Azure Signature <JsonTextarea jsonPayload={signature.token} /></li>
                );
            }

            return (
                <MCard
                    key={id}
                    active={id === selectedDeviceId}
                    title={name}
                    subtitle="Dispositivo"
                    expandable
                    expanded={false}
                    actionButtonOnClick={() => { this.handleActionButtonOnClick(id); }}
                    actionButtonText="Sensores"
                >
                    <ul className="mdevice-admin-list">
                        <li>Id <JsonTextarea jsonPayload={id} /></li>
                        <li>MAC <JsonTextarea jsonPayload={mac} /></li>
                        { signatureData }

                        <MForm
                            fields={DEVICE_PUT_FIELDS}
                            defaults={{
                                id,
                                name,
                                status,
                                additional_info: additionalInfo,
                                hub_id: hubId,

                                sleep_time: unwrapper(hardware, 'desired.sleepTime'),
                                firmware_url: unwrapper(hardware, 'desired.firmware.url'),
                                firmware_version: unwrapper(hardware, 'desired.firmware.version'),
                                gps_enabled: unwrapper(hardware, 'desired.gps.enabled'),
                                gps_timeout_ms: unwrapper(hardware, 'desired.gps.timeoutMs'),
                            }}
                            onSubmit={(json) => { return this.triggerPutDevice(id, json); }}
                            submitButtonText="Guardar"
                        />
                    </ul>
                    <button
                        type="button"
                        className="btn btn-danger btn-block"
                        onClick={() => { this.triggerDeleteDevice(device); }}
                    >
                        Eliminar
                    </button>
                </MCard>
            );
        });
    }

    renderForm() {
        const { selectedHubId } = this.props;

        return (
            <MCard title="Nuevo Dispositivo" expandable expanded={false}>
                <MForm
                    fields={DEVICE_FIELDS}
                    defaults={{
                        hub_id: selectedHubId,
                    }}
                    hiddenFields={[
                        'hub_id',
                    ]}
                    onSubmit={this.triggerPostDevice}
                    submitButtonText="Crear"
                />
            </MCard>
        );
    }

    render() {
        return (
            <MCard title="Dispositivos">
                { this.renderForm() }

                <br />

                { this.renderDevices() }
            </MCard>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const { selectedHubId } = ownProps;
    const { devices } = state.devices;

    const newProps = {};

    if (_.isObject(devices) && !_.isEmpty(devices)) {
        newProps.devices = Object.values(devices).filter((device) => {
            return device.hubId === selectedHubId;
        });

        newProps.devices.sort((lhs, rhs) => {
            return lhs.name.localeCompare(rhs.name, undefined, { numeric: true, sensitivity: 'base' });
        });
    }

    return newProps;
};

MDeviceAdmin.propTypes = {
    selectedHubId: PropTypes.string.isRequired,
    selectedDeviceId: PropTypes.string,
    onSelect: PropTypes.func,

    // Redux
    devices: MeasurePropTypes.DEVICES,

    // Actions
    triggerGetAllDevices: PropTypes.func.isRequired,
    triggerPostDevice: PropTypes.func.isRequired,
    triggerPutDevice: PropTypes.func.isRequired,
    triggerDeleteDevice: PropTypes.func.isRequired,
};

MDeviceAdmin.defaultProps = {
    selectedDeviceId: undefined,
    onSelect: undefined,
    devices: undefined,
};

export default connect(mapStateToProps, Actions)(MDeviceAdmin);
