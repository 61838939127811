import { isEmpty } from "underscore";

const newListener = 'optimizedScroll';

export const X_DIRECTION = 'x';
export const Y_DIRECTION = 'y';

class ScrollHelper {
    static overrideScrollEventHandler(originalListener = 'scroll', target = window) {
        let running = false;

        target.addEventListener(originalListener, () => {
            if (running) return;
            running = true;

            requestAnimationFrame(() => {
                target.dispatchEvent(new CustomEvent(newListener));
                running = false;
            });
        });
    }

    static mapHandlerValue(currentPosition, scrollStart, scrollEnd, handlerStartValue, handlerEndValue) {
        const referenceRange = handlerEndValue - handlerStartValue;
        const rawRange = scrollEnd - scrollStart;

        return (((currentPosition - scrollStart) * referenceRange) / rawRange) + handlerStartValue;
    }

    static addScrollListener(handler, options = {}) {
        const updateScrollListener = () => {
            const handlerItem = options.item ? options.item : document.getElementById(options.itemId);

            ScrollHelper.updateScrollHandler(
                handler,
                {
                    ...options,
                    item: handlerItem,
                },
            );
        };

        // Set element with initial state
        updateScrollListener();
        window.addEventListener(newListener, updateScrollListener);

        return updateScrollListener;
    }

    static removeScrollListeners(handlers = []) {
        if (handlers === null) return;

        handlers.forEach((handler) => {
            ScrollHelper.removeScrollListener(handler);
        });
    }

    static removeScrollListener(handler) {
        window.removeEventListener(newListener, handler);
    }

    static updateScrollHandler(handler, options) {
        const {
            // item over which the handler value will be applied against
            item,
            // handler value before reaching "scrollStartPosition" position
            handlerStartValue,
            // handler value after reaching "scrollEndPosition" position
            handlerEndValue,
            // handler starts going from "handlerStartValue" to "handlerEndValue"
            scrollStartPosition,
            // handler stops updating value
            scrollEndPosition,
            // direction used to track scroll
            direction,
            // you can pass "static" transformations if needed
            additionalTransform,
        } = options;

        const currentPosition = ScrollHelper.getCurrentScrollPosition(direction);
        const scrollEvents = ScrollHelper.getScrollEvents(
            currentPosition,
            scrollStartPosition,
            scrollEndPosition,
        );

        if (scrollEvents.beforeScroll) {
            handler(item, handlerStartValue, additionalTransform);

            return;
        }

        if (scrollEvents.duringScroll) {
            const handlerValue = ScrollHelper.mapHandlerValue(
                currentPosition,
                scrollStartPosition,
                scrollEndPosition,
                handlerStartValue,
                handlerEndValue,
            );
            handler(item, handlerValue, additionalTransform);

            return;
        }

        // Default: scrollEvents.afterScroll
        handler(item, handlerEndValue, additionalTransform);
    }

    static videoScrollHandler(item, value) {
        item.currentTime = value;
    }

    static translateXScrollHandler(item, value, additionalTransform) {
        if (isEmpty(item)) return;
        item.style.transform = `${additionalTransform} translateX(${value}px)`;
    }

    static translateYScrollHandler(item, value, additionalTransform) {
        if (isEmpty(item)) return;
        item.style.transform = `${additionalTransform} translateY(${value}px)`;
    }

    static scaleScrollHandler(item, value, additionalTransform) {
        if (isEmpty(item)) return;
        item.style.transform = `${additionalTransform} scale(${value})`;
    }

    static opacityScrollHandler(item, value) {
        if (isEmpty(item)) return;
        item.style.opacity = `${value}`;
    }

    static stickyScrollHandler(item, value) {
        if (isEmpty(item)) return;
        item.style.opacity = `${value}`;
    }

    static getScrollEvents(currentPosition, scrollStartPosition, scrollEndPosition) {
        const beforeScroll = currentPosition < scrollStartPosition;
        const duringScroll = scrollStartPosition <= currentPosition && currentPosition <= scrollEndPosition;
        const afterScroll = scrollEndPosition < currentPosition;

        return {
            beforeScroll,
            duringScroll,
            afterScroll,
        };
    }

    static getCurrentScrollPosition(direction = Y_DIRECTION, handler = window) {
        if (direction === Y_DIRECTION) {
            return handler.pageYOffset;
        }

        if (direction === X_DIRECTION) {
            return handler.pageXOffset;
        }

        throw new Error('Invalid direction provided');
    }

    static getPositionProperties(item, direction = Y_DIRECTION) {
        if (isEmpty(item)) return {};
        let start = 0;
        let end = 0;
        const height = item.offsetHeight;
        const width = item.offsetWidth;

        if (direction === Y_DIRECTION) {
            start = item.offsetTop;
            end = start + height;
        } else if (direction === X_DIRECTION) {
            start = item.offsetLeft;
            end = start + width;
        } else {
            throw new Error('Invalid direction provided');
        }

        return {
            start,
            end,
            height,
            width,
        };
    }
}

ScrollHelper.overrideScrollEventHandler();

export default ScrollHelper;
