import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from 'recharts';

import CustomTooltip from './tooltip';
import MDataEmptyState from '../body_empty_state';
import MBodyLoader from '../body_loader';

import './style.scss';

import SensorDatasetParser from '../../../helpers/sensor_dataset_parser';

const MLineChart = (props) => {
    const {
        dataset,
        xAxisDomanin,
        yAxisDomain,
        unit,
        labelFormatter,
        height,
    } = props;

    if (_.isUndefined(dataset)) return <MBodyLoader height={height} />;

    if (!_.isArray(dataset) || _.isEmpty(dataset)) return <MDataEmptyState height={height} />;

    const axisStroke = '#2d2d2d';
    let lineStroke = '#0d4988';
    if (document.documentElement.getAttribute('data-theme') === 'dark') {
        lineStroke = '#4BD763';
    }

    return (
        <ResponsiveContainer width="99%" height={height}>
            <LineChart data={dataset}>
                <Tooltip
                    content={<CustomTooltip labelFormatter={labelFormatter} />}
                    unit={unit}
                />
                <CartesianGrid
                    stroke="#E0EFF1"
                    vertical={false}
                    strokeDasharray="5"
                />
                <Line
                    type="monotone"
                    dataKey="value"
                    stroke={lineStroke}
                    strokeWidth={2}
                    dot={false}
                />
            </LineChart>
        </ResponsiveContainer>
    );
};

MLineChart.propTypes = {
    unit: PropTypes.string,
    dataset: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.number,
            rawLabel: PropTypes.number,
        }),
    ),
    xAxisDomanin: PropTypes.arrayOf(
        PropTypes.number,
    ),
    yAxisDomain: PropTypes.arrayOf(
        PropTypes.number,
    ),
    labelFormatter: PropTypes.func,
    height: PropTypes.number,
};

MLineChart.defaultProps = {
    unit: '',
    dataset: undefined,
    xAxisDomanin: [],
    yAxisDomain: [],
    labelFormatter: SensorDatasetParser.labelFormatter,
    height: 300,
};

export default MLineChart;
