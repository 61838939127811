import TYPES from './types';

export const dispatchCurrentUserUpdate = (dispatch, data) => {
    dispatch({
        type: TYPES.SET_CURRENT,
        payload: {
            user: data,
        },
    });
};

export const dispatchUserUpdate = (dispatch, id, data) => {
    dispatch({
        type: TYPES.SET,
        payload: {
            userId: id,
            user: data,
        },
    });
};
