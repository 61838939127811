import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import { Map, ZoomControl } from 'pigeon-maps';
// import { maptiler } from 'pigeon-maps/providers';

import MeasureMarker from './mmarker';
import MDataEmptyState from '../body_empty_state';
import MBodyLoader from '../body_loader';

const MAPTILER_ACCESS_TOKEN = process.env.REACT_APP_MAP_API_KEY;

const maptiler = function maptiler(apiKey, map) {
    return (x, y, z, dpr) => {
        return `https://api.maptiler.com/maps/${map}/256/${z}/${x}/${y}@2x.jpg?key=${apiKey}`;
    };
};

const maptilerProvider = maptiler(MAPTILER_ACCESS_TOKEN, 'hybrid');

class MapContainer extends PureComponent {
    render() {
        const {
            dataset: rawDataset,
            currentReading,
            height,
            onClick,
        } = this.props;

        if (_.isUndefined(rawDataset)) return <MBodyLoader height={height} />;
        const dataset = (rawDataset || []).filter((reading) => { return !_.isUndefined(reading.value); });

        if (!_.isArray(dataset) || _.isEmpty(dataset)) return <MDataEmptyState height={height} />;
        const lastReading = _.last(dataset);

        const { value } = currentReading || lastReading;
        const position = [value.lat, value.lng];

        return (
            <div
                style={{ borderRadius: '0.5rem', overflow: 'hidden' }}
            >
                <Map
                    provider={maptilerProvider}
                    center={position}
                    zoom={13}
                    height={height}
                    attribution={false}
                >
                    <ZoomControl />
                    <MeasureMarker
                        anchor={position}
                        onClick={onClick}
                    />
                </Map>
            </div>
        );
    }
}

MapContainer.propTypes = {
    height: PropTypes.number,
    dataset: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.shape({
                lng: PropTypes.number,
                lat: PropTypes.number,
                alt: PropTypes.number,
            }),
        ]),
    })),
    onClick: PropTypes.func,
};

MapContainer.defaultProps = {
    height: 300,
    dataset: undefined,
    onClick: undefined,
};

export default MapContainer;
