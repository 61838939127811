import { isEmpty } from 'underscore';

import { INPUT_TYPES } from '../index';

const FIELDS = [
    {
        jsonKey: 'name',
        label: 'Hub Name',
        type: INPUT_TYPES.text,
        onChangeErrorMessage: 'El nombre no puede estar vacío.',
        onChangeValidation: (value) => {
            return !isEmpty(value);
        },
        onBlurErrorMessage: 'El nombre no puede estar vacío.',
        onBlurValidation: (value) => {
            return !isEmpty(value);
        },
        onSubmitErrorMessage: 'El nombre no puede estar vacío.',
        onSubmitValidation: (value) => {
            return !isEmpty(value);
        },
    },
    {
        jsonKey: 'additional_info',
        label: 'Hub Aditional Info',
        type: INPUT_TYPES.textarea,
    },
    {
        jsonKey: 'organization_id',
        label: 'Organization ID',
        type: INPUT_TYPES.text,
        onChangeErrorMessage: 'Invalid data',
        onChangeValidation: (value) => {
            return !isEmpty(value);
        },
        onBlurErrorMessage: 'OB Invalid data',
        onBlurValidation: (value) => {
            return !isEmpty(value);
        },
        onSubmitErrorMessage: 'OS Invalid data',
        onSubmitValidation: (value) => {
            return !isEmpty(value);
        },
    },
];

export default FIELDS;
