import _ from 'underscore';
import TYPES from './types';

import { isValidUUID } from '../../helpers/regex_helper';

const defaultState = {
    contacts: {},
};

const Reducer = (oldState = defaultState, dispatchedAction) => {
    const { type, payload } = dispatchedAction;

    const newState = { ...oldState };

    switch (type) {
    case TYPES.SET: {
        const { contactId, contact } = payload;
        if (!isValidUUID(contactId)) break;

        if (_.isUndefined(contact)) {
            delete newState.contacts[contactId];
            break;
        }

        newState.contacts[contactId] = contact;

        break;
    }

    case TYPES.UPDATE_SUBSCRIPTION: {
        const { contactId, subscribed } = payload;
        if (!isValidUUID(contactId)) break;

        if (!_.isEmpty(newState.contacts[contactId])) {
            newState.contacts[contactId].subscribed = subscribed;
        }

        break;
    }

    case TYPES.CLEAR_ALL: {
        newState.contacts = {};
        break;
    }

    default:
        break;
    }

    return newState;
};

export default Reducer;
