import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import { connect } from 'react-redux';
import { MeasureRequestHandler } from '@measure-iot/api';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import {
    Col,
    Row,
    Button,
} from 'reactstrap';

import * as Actions from '../../../actions';
import MeasurePropTypes from '../../../propTypes';

import MCard from '../mcard_base';

class MHubReports extends PureComponent {
    constructor(props) {
        super(props);

        this.cancelToken = new MeasureRequestHandler();
    }

    componentDidMount() {
        this.triggerGetHubReports();
    }

    async triggerGetHubReports() {
        const {
            hubId,
            triggerClearHubReports,
            triggerGetHubReports,
        } = this.props;

        await triggerClearHubReports();
        await triggerGetHubReports(hubId, this.cancelToken);
    }

    renderReportsTableBody() {
        const { reports } = this.props;

        return reports.map((report) => {
            const {
                id,
                startDate,
                endDate,
                url,
            } = report;

            const start = new Date(startDate * 1000);
            const end = new Date(endDate * 1000);

            return (
                <tr key={id}>
                    <td className="contact-name">{format(start, "d 'de' MMMM yyyy", { locale: es })}</td>
                    <td className="contact-name">{format(end, "d 'de' MMMM yyyy", { locale: es })}</td>
                    <td>
                        {url && (
                            <Button size="sm" color="primary" id={id} href={url} target="blank" outline>
                                Ver Reporte
                            </Button>
                        )}
                    </td>
                </tr>
            );
        });
    }

    render() {
        return (
            <MCard title="Reportes" subtitle="generados mes a mes" expanded={false} expandable>
                <table>
                    <thead>
                        <tr>
                            <th>Fecha inicio</th>
                            <th>Fecha término</th>
                            <th>Link</th>
                        </tr>
                    </thead>
                    <tbody>
                        { this.renderReportsTableBody() }
                    </tbody>
                </table>
            </MCard>
        );
    }
}

const mapStateToProps = (state) => {
    const { currentUser } = state.users;
    const { reports } = state.hubs;

    const newProps = {};

    newProps.userId = currentUser.id;

    if (_.isObject(reports) && !_.isEmpty(reports)) {
        newProps.reports = Object.values(reports);
    }

    return newProps;
};

MHubReports.propTypes = {
    hubId: PropTypes.string.isRequired,

    // Redux
    reports: MeasurePropTypes.HUB_REPORTS,

    // Actions
    triggerClearHubReports: PropTypes.func.isRequired,
    triggerGetHubReports: PropTypes.func.isRequired,
};

MHubReports.defaultProps = {
    reports: [],
};

export default connect(mapStateToProps, Actions)(MHubReports);
