import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const MButton = (props) => {
    const {
        text,
        onClick,
    } = props;

    return (
        <button
            type="button"
            className="mbutton"
            onClick={onClick}
        >
            {text}
        </button>
    );
};

MButton.propTypes = {
    text: PropTypes.string,
    onClick: PropTypes.func,
};

MButton.defaultProps = {
    text: undefined,
    onClick: undefined,
};

export default MButton;
