import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import { Col } from 'reactstrap';

import MBodyLoader from '../body_loader';

import './style.scss';

import SensorDatasetParser from '../../../helpers/sensor_dataset_parser';

class MDatasetSelector extends PureComponent {
    constructor(props) {
        super(props);

        this.handleReadingSelected = this.handleReadingSelected.bind(this);
    }

    handleReadingSelected(event) {
        const { onClick, dataset } = this.props;
        const { id } = event.target;

        const reading = dataset.find((data) => { return data.label === parseInt(id, 10); });

        if (_.isFunction(onClick)) {
            onClick(reading);
        }
    }

    renderDataset(dataset, currentReading) {
        const { label: currentLabel } = (currentReading || {});

        return dataset.map((data) => {
            const { label } = data;

            return (
                <button
                    key={label}
                    type="button"
                    id={label}
                    onClick={this.handleReadingSelected}
                    onMouseEnter={this.handleReadingSelected}
                    className={label === currentLabel ? 'active' : ''}
                >
                    { SensorDatasetParser.labelFormatter(data.label) }
                </button>
            );
        });
    }

    renderShareButton(selectedReading) {
        const share_url = `http://maps.google.com/maps?&z=10&q=${selectedReading?.value?.lat}+${selectedReading?.value?.lng}`;
        
        return (
            <a
                className="btn btn-success btn-sm btn-block share-button"
                href={share_url}
                target="blank"
            >
                Compartir
            </a>
        );
    }

    render() {
        const {
            dataset: rawDataset,
            currentReading,
            height,
        } = this.props;

        if (_.isUndefined(rawDataset)) return <MBodyLoader height={height} />;
        const dataset = (rawDataset || []).filter((reading) => { return !_.isUndefined(reading.value); });
        dataset.sort((a, b) => { return b.label - a.label; });

        if (!_.isArray(dataset) || _.isEmpty(dataset)) return null;

        const reading = currentReading || _.first(dataset);

        return (
            <div
                className="mdataset-selector"
            >
                <div
                    className="mdataset-buttons"
                    style={{ maxHeight: height }}
                >
                    { this.renderShareButton(reading) }
                    { this.renderDataset(dataset, reading) }
                </div>
            </div>
        );
    }
}

MDatasetSelector.propTypes = {
    height: PropTypes.number,
    dataset: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.shape({
                lng: PropTypes.number,
                lat: PropTypes.number,
                alt: PropTypes.number,
            }),
        ]),
    })),
    currentReading: PropTypes.shape({
        label: PropTypes.number,
    }),
    onClick: PropTypes.func,
};

MDatasetSelector.defaultProps = {
    height: 300,
    dataset: undefined,
    currentReading: undefined,
    onClick: undefined,
};

export default MDatasetSelector;
