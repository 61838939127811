import _ from 'underscore';

import { INPUT_TYPES } from '../index';

const FIELDS = [
    {
        jsonKey: 'name',
        label: 'Device Name',
        type: INPUT_TYPES.text,
        onChangeErrorMessage: 'Invalid data',
        onChangeValidation: (value) => {
            return !_.isEmpty(value);
        },
        onBlurErrorMessage: 'OB Invalid data',
        onBlurValidation: (value) => {
            return !_.isEmpty(value);
        },
        onSubmitErrorMessage: 'OS Invalid data',
        onSubmitValidation: (value) => {
            return !_.isEmpty(value);
        },
    },
    {
        jsonKey: 'status',
        label: 'Device Status',
        type: INPUT_TYPES.select,
        options: [
            {
                label: 'Enabled',
                value: 'enabled',
            },
            {
                label: 'Quarantined',
                value: 'quarantined',
            },
            {
                label: 'Disabled',
                value: 'disabled',
            },
        ],
        onChangeErrorMessage: 'Invalid data',
        onChangeValidation: (value) => {
            return !_.isEmpty(value);
        },
        onBlurErrorMessage: 'OB Invalid data',
        onBlurValidation: (value) => {
            return !_.isEmpty(value);
        },
        onSubmitErrorMessage: 'OS Invalid data',
        onSubmitValidation: (value) => {
            return !_.isEmpty(value);
        },
    },
    {
        jsonKey: 'additional_info',
        label: 'Device Aditional Info',
        type: INPUT_TYPES.textarea,
    },
    {
        jsonKey: 'hub_id',
        label: 'Hub ID',
        type: INPUT_TYPES.text,
        onChangeErrorMessage: 'Invalid data',
        onChangeValidation: (value) => {
            return !_.isEmpty(value);
        },
        onBlurErrorMessage: 'OB Invalid data',
        onBlurValidation: (value) => {
            return !_.isEmpty(value);
        },
        onSubmitErrorMessage: 'OS Invalid data',
        onSubmitValidation: (value) => {
            return !_.isEmpty(value);
        },
    },
    {
        label: 'Hardware',
    },
    {
        jsonKey: 'sleep_time',
        label: 'SleepTime',
        type: INPUT_TYPES.number,
    },
    {
        jsonKey: 'firmware_url',
        label: 'Firmware URL',
        type: INPUT_TYPES.text,
    },
    {
        jsonKey: 'firmware_version',
        label: 'Firmware Version',
        type: INPUT_TYPES.text,
    },
    {
        jsonKey: 'gps_enabled',
        label: 'GPS',
        type: INPUT_TYPES.select,
        options: [
            {
                label: 'Disabled',
                value: false,
            },
            {
                label: 'Enabled',
                value: true,
            },
        ],
        onChangeTransform: (value) => {
            return value === 'true';
        },
    },
    {
        jsonKey: 'gps_timeout_ms',
        label: 'GPS Timeout (MS)',
        type: INPUT_TYPES.number,
    },
];

export default FIELDS;
