import React from 'react';
import PropTypes from 'prop-types';
import { GiSpyglass } from 'react-icons/gi';

import MCardEmptyState from '../mcards/mcard_base/empty_state';

const MDataEmptyState = (props) => {
    const {
        height,
        title,
        subtitle,
        icon,
    } = props;
    return (
        <MCardEmptyState
            title={title}
            icon={icon}
            subtitle={subtitle}
            height={height}
        />
    );
};

MDataEmptyState.propTypes = {
    height: PropTypes.number,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    icon: PropTypes.node,
};

MDataEmptyState.defaultProps = {
    height: 300,
    title: 'No hay datos para mostrar',
    subtitle: '¡Avísanos si crees que se trata de un error!',
    icon: <GiSpyglass />,
};

export default MDataEmptyState;
