import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';

import './style.scss';

const CustomTooltip = (props) => {
    const { active } = props;

    if (!active) return null;

    const {
        payload,
        unit,
        labelFormatter,
    } = props;

    if (!_.isArray(payload)) return null;

    return (
        <div className="mchart-tooltip">
            {
                payload.map((dataPoint) => {
                    const { stroke } = dataPoint;
                    const { value, label } = dataPoint.payload;

                    return (
                        <div className="mchart-tooltip-body" key={`${value}${label}`}>
                            <p className="label">{`${labelFormatter(label)}`}</p>
                            <p className="value">
                                <span className="circle" style={{ backgroundColor: stroke }} /><span className="text">{`${value} ${unit}`}</span>
                            </p>
                        </div>
                    );
                })
            }
        </div>
    );
};

CustomTooltip.propTypes = {
    active: PropTypes.bool,
    payload: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.number,
        }),
    ),
    unit: PropTypes.string,
    labelFormatter: PropTypes.func,
};

CustomTooltip.defaultProps = {
    active: false,
    payload: [],
    unit: '',
    labelFormatter: (value) => { return value; },
};

export default CustomTooltip;
