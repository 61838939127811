import { isEmpty } from 'underscore';

import { INPUT_TYPES } from '../index';

const FIELDS = [
    {
        jsonKey: 'name',
        label: 'Organization Name',
        placeholder: 'Measure',
        type: INPUT_TYPES.text,
        onChangeErrorMessage: 'No puede estar vacío',
        onChangeValidation: (value) => {
            return !isEmpty(value);
        },
        onBlurErrorMessage: 'No puede estar vacío',
        onBlurValidation: (value) => {
            return !isEmpty(value);
        },
        onSubmitErrorMessage: 'No puede estar vacío',
        onSubmitValidation: (value) => {
            return !isEmpty(value);
        },
    },
];

export default FIELDS;
