import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
    Row,
    Col,
} from 'reactstrap';

import './style.scss';
import _ from 'underscore';

class MCardsHorizontalContainer extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            top: 0,
            left: 0,
            x: 0,
            y: 0,
            grabbing: false,
        };

        this.onMouseMoveHandler = this.onMouseMoveHandler.bind(this);
        this.onMouseDownHandler = this.onMouseDownHandler.bind(this);
        this.onMouseUpHandler = this.onMouseUpHandler.bind(this);
    }

    onMouseMoveHandler(event) {
        const { id } = this.props;
        const {
            x,
            y,
            top,
            left,
            grabbing,
        } = this.state;

        if (!grabbing) return;

        const dx = event.clientX - x;
        const dy = event.clientY - y;

        const container = document.getElementById(id);

        container.scrollTop = top - dy;
        container.scrollLeft = left - dx;
    }

    onMouseDownHandler(event) {
        const { id } = this.props;
        const container = document.getElementById(id);

        container.style.cursor = 'grabbing';
        container.style.userSelect = 'none';

        this.setState({
            top: container.scrollTop,
            left: container.scrollLeft,
            x: event.clientX,
            y: event.clientY,
            grabbing: true,
        });
    }

    onMouseUpHandler() {
        const { id } = this.props;
        const container = document.getElementById(id);

        container.style.cursor = 'grab';
        container.style.removeProperty('user-select');

        this.setState({
            grabbing: false,
        });
    }

    render() {
        const {
            id,
            children,
            height,
        } = this.props;

        return (
            <Row
                id={id}
                className="mcards-horizontal-container"
                style={{ height }}
                onMouseDown={this.onMouseDownHandler}
                onMouseUp={this.onMouseUpHandler}
                onMouseMove={this.onMouseMoveHandler}
            >
                {
                    React.Children.map(children, (child) => {
                        if (_.isNull(child)) return null;

                        const newChild = React.cloneElement(child);

                        return (
                            <Col xs={10} md={5}>
                                { newChild }
                            </Col>
                        );
                    })
                }
            </Row>
        );
    }
}

MCardsHorizontalContainer.propTypes = {
    id: PropTypes.string,
    children: PropTypes.node.isRequired,
    height: PropTypes.string,
};

MCardsHorizontalContainer.defaultProps = {
    id: 'mcontainer',
    height: '85vh',
};

export default MCardsHorizontalContainer;
