import React from 'react';
import PropTypes from 'prop-types';
import BlueLogo from '../../assets/img/logo_blue.svg';
import WhiteLogo from '../../assets/img/logo_white.svg';
import BlueCloudLogo from '../../assets/img/logo_blue_cloud.svg';

const MLogo = (props) => {
    const {
        color,
        ignoreTheme,
        width,
        height,
        className,
    } = props;

    let selectedLogo = BlueLogo;
    switch (color) {
    case 'white':
        selectedLogo = WhiteLogo;
        break;
    case 'blueCloud':
        selectedLogo = BlueCloudLogo;
        break;
    default:
        selectedLogo = BlueLogo;
    }

    const imageAlt = 'Measure IoT - Automatizamos la recolección e inteligencia de los datos generados en tus instalaciones.';

    if (!ignoreTheme && document.documentElement.getAttribute('data-theme') === 'dark') {
        selectedLogo = WhiteLogo;
    }

    return <img className={className} src={selectedLogo} alt={imageAlt} style={{ width, height }} />;
};

MLogo.propTypes = {
    color: PropTypes.oneOf([
        'blue',
        'white',
        'blueCloud',
    ]),
    ignoreTheme: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    className: PropTypes.string,
};

MLogo.defaultProps = {
    color: 'blue',
    ignoreTheme: false,
    width: 'auto',
    height: 'auto',
    className: '',
};

export default MLogo;
