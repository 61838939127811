import React from 'react';
import PropTypes from 'prop-types';

import MapPin from '../../../assets/img/map_pin.svg';

const MeasureMarker = (props) => {
    const {
        left,
        top,
        style,
        onClick,
    } = props;

    const width = 40;
    const height = 40;

    const markerStyle = {
        position: 'absolute',
        left: left - (height / 2),
        top: top - width,
        width,
        height,
        cursor: 'pointer',
        ...style,
    };

    return (
        <button
            type="button"
            onClick={onClick}
        >
            <img
                src={MapPin}
                alt="measure-map-pin"
                style={markerStyle}
            />
        </button>
    );
};

MeasureMarker.propTypes = {
    left: PropTypes.number,
    top: PropTypes.number,

    // eslint-disable-next-line react/forbid-prop-types
    style: PropTypes.object,
    onClick: PropTypes.func,
};

MeasureMarker.defaultProps = {
    left: 0,
    top: 0,
    style: {},
    onClick: undefined,
};

export default MeasureMarker;
