import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

import * as Authenticator from '../../../helpers/authenticator';

import MeasureVerticalSpacer from '../../general/vertical_spacer';

import './style.sass';
import Line from '../../../assets/img/404.svg';

const MeasureNotFound = () => {
    const renderButtons = () => {
        const isAuthenticated = Authenticator.isAuthenticated();

        if (isAuthenticated) {
            return (
                <div className="not-found-button-container">
                    <Button className="not-found-button" outline color="primary" tag={Link} to="/home">
                        Inicio
                    </Button>
                    <Button className="not-found-button" outline color="primary" tag={Link} to="/">
                        Dashboard
                    </Button>
                </div>
            );
        }

        return (
            <div className="not-found-button-container">
                <Button className="not-found-button" outline color="primary" tag={Link} to="/">
                    Inicio
                </Button>
                <Button className="not-found-button" outline color="primary" tag={Link} to="/login">
                    Login
                </Button>
            </div>
        );
    };

    return (
        <Row>
            <Col>
                <MeasureVerticalSpacer height="5vh" />

                <div className="text-container">
                    <div className="middle-text">
                        <p className="title">¡WOW!</p>
                        <p className="subtitle">PARECE QUE TE PERDISTE</p>
                    </div>
                </div>
                <img src={Line} className="measure-line" alt="measure-line" />

                <div className="links-container">
                    <div className="not-found-message text-center">
                        <p>¿Quizás alguno de estos te sirva?</p>
                        <MeasureVerticalSpacer height="5vh" />
                    </div>

                    <div className="not-found-button-container">
                        {
                            renderButtons()
                        }
                    </div>
                    <div className="not-found-message-small text-center">
                        <MeasureVerticalSpacer height="10vh" />
                        <p>Si crees que se trata de un error envíanos un mail a <strong><a href="mailto:contacto@measure.cl">contacto@measure.cl</a></strong></p>
                    </div>
                    <MeasureVerticalSpacer height="15vh" />
                </div>
            </Col>
        </Row>
    );
};

export default MeasureNotFound;
