import PropTypes from 'prop-types';

const ORGANIZATION = PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
});

const ORGANIZATIONS = PropTypes.arrayOf(ORGANIZATION);

const HUB_REPORT = PropTypes.shape({
    id: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    hubId: PropTypes.string,
    hash: PropTypes.string,
    url: PropTypes.string,
});

const HUB_REPORTS = PropTypes.arrayOf(HUB_REPORT);

const USER_CONTACT = PropTypes.shape({
    id: PropTypes.string,
    channel: PropTypes.string,
    username: PropTypes.string,
});

const USER_CONTACTS = PropTypes.arrayOf(USER_CONTACT);

const HUB = PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    additionalInfo: PropTypes.string,
    organizationId: PropTypes.string,
    contacts: USER_CONTACTS,
});

const HUBS = PropTypes.arrayOf(HUB);

const DEVICE = PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    mac: PropTypes.string,
    status: PropTypes.string,
    statusUpdatedAt: PropTypes.string,
    additionalInfo: PropTypes.string,
    hubId: PropTypes.string,
    hardware: PropTypes.shape({
        desired: PropTypes.shape({

        }),
        reported: PropTypes.shape({

        }),
    }),
});

const DEVICES = PropTypes.arrayOf(DEVICE);

const SENSOR_DATA_VARIABLE = PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
    unit: PropTypes.string,
    name: PropTypes.string,
});

const SENSOR = PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    additionalInfo: PropTypes.string,
    gateId: PropTypes.string,
    gateType: PropTypes.string,
    status: PropTypes.string,
    deviceId: PropTypes.string,
    dataVariable: SENSOR_DATA_VARIABLE,
});

const SENSORS = PropTypes.arrayOf(SENSOR);

const USER = PropTypes.shape({
    id: PropTypes.string,
    organizationId: PropTypes.string,
    name: PropTypes.string,
    lastname: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.shape({
        name: PropTypes.string,
    }),
});

const USERS = PropTypes.arrayOf(USER);

const MeasurePropTypes = {
    USERS,
    USER,
    USER_CONTACT,
    USER_CONTACTS,
    ORGANIZATIONS,
    ORGANIZATION,
    HUBS,
    HUB,
    HUB_REPORT,
    HUB_REPORTS,
    DEVICES,
    DEVICE,
    SENSORS,
    SENSOR,
    SENSOR_DATA_VARIABLE,
};

export default MeasurePropTypes;
