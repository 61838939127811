import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'underscore';
import { MeasureRequestHandler } from '@measure-iot/api';

import MeasurePropTypes from '../../../propTypes';
import * as Actions from '../../../actions';

import MCard from '../mcard_base';
import MLoader from '../../mloader';
import MDataEmptyState from '../../mdata_charts/body_empty_state';

import JsonTextarea from '../../mjson_textarea';
import MForm from '../../mform';
import ORGANIZATION_FIELDS from '../../mform/fields/organization';

import './style.scss';

class MOrganizationAdmin extends PureComponent {
    constructor(props) {
        super(props);

        this.cancelToken = new MeasureRequestHandler();
        this.handleOnExpand = this.handleOnExpand.bind(this);
        this.handleOnButtonTapped = this.handleOnButtonTapped.bind(this);
        this.triggerPostOrganization = this.triggerPostOrganization.bind(this);
        this.triggerPutOrganization = this.triggerPutOrganization.bind(this);
    }

    componentDidMount() {
        this.triggerGetAllOrganizations();
    }

    triggerGetAllOrganizations() {
        const { triggerGetAllOrganizations } = this.props;

        triggerGetAllOrganizations(this.cancelToken);
    }

    triggerPostOrganization(json) {
        const { triggerPostOrganization } = this.props;

        return triggerPostOrganization(json, this.cancelToken);
    }

    triggerPutOrganization(id, json) {
        const {
            triggerPutOrganization,
        } = this.props;

        return triggerPutOrganization(id, json, this.cancelToken);
    }

    handleOnExpand(expanded, id) {
        const { onExpand } = this.props;

        if (!_.isFunction(onExpand) || !expanded) return;

        onExpand(id);
    }

    handleOnButtonTapped(event) {
        const { onButtonTapped } = this.props;
        const { id } = event.target;

        if (!_.isFunction(onButtonTapped)) return;

        onButtonTapped(id);
    }

    static renderLoader() {
        return (
            <MLoader size="md" centered />
        );
    }

    renderDetailButtons() {
        return (
            <div>
                <hr />
                Ver mas:
                <button
                    type="button"
                    className="btn btn-link btn-sm"
                    id="users"
                    onClick={this.handleOnButtonTapped}
                >
                    Usuarios
                </button>|

                <button
                    type="button"
                    className="btn btn-link btn-sm"
                    id="hubs"
                    onClick={this.handleOnButtonTapped}
                >
                    Hubs
                </button>
            </div>
        );
    }

    renderOrganizations() {
        const { organizations, selectedOrganizationId } = this.props;

        if (_.isUndefined(organizations)) return MOrganizationAdmin.renderLoader();

        if (_.isEmpty(organizations)) return <MDataEmptyState />;

        return organizations.map((organization) => {
            const { id, name } = organization;

            return (
                <MCard
                    key={id}
                    active={id === selectedOrganizationId}
                    title={name}
                    subtitle="Organización"
                    expandable
                    expanded={false}
                    onExpand={(expanded) => { this.handleOnExpand(expanded, id); }}
                >
                    <ul className="morganization-admin-list">
                        <li>Id <JsonTextarea jsonPayload={id} /></li>

                        <MForm
                            fields={ORGANIZATION_FIELDS}
                            defaults={{
                                id,
                                name,
                            }}
                            submitButtonText="Guardar"
                            backButtonText="Volver"
                            onSubmit={(json) => { return this.triggerPutOrganization(id, json); }}
                        />
                    </ul>
                    {
                        this.renderDetailButtons()
                    }
                </MCard>
            );
        });
    }

    renderForm() {
        return (
            <MCard title="Nueva Organización" expandable expanded={false}>
                <MForm
                    fields={ORGANIZATION_FIELDS}
                    submitButtonText="Crear"
                    backButtonText="Volver"
                    onSubmit={this.triggerPostOrganization}
                />
            </MCard>
        );
    }

    render() {
        return (
            <MCard title="Organizaciones">
                { this.renderForm() }

                <br />

                { this.renderOrganizations() }
            </MCard>
        );
    }
}

const mapStateToProps = (state) => {
    const { organizations } = state.organizations;

    const newProps = {};

    if (_.isObject(organizations) && !_.isEmpty(organizations)) {
        newProps.organizations = Object.values(organizations);

        newProps.organizations.sort((lhs, rhs) => {
            return lhs.name.localeCompare(rhs.name);
        });
    }

    return newProps;
};

MOrganizationAdmin.propTypes = {
    selectedOrganizationId: PropTypes.string,
    onExpand: PropTypes.func,
    onButtonTapped: PropTypes.func,

    // Redux
    organizations: MeasurePropTypes.ORGANIZATIONS,

    // Actions
    triggerGetAllOrganizations: PropTypes.func.isRequired,
    triggerPostOrganization: PropTypes.func.isRequired,
    triggerPutOrganization: PropTypes.func.isRequired,
};

MOrganizationAdmin.defaultProps = {
    selectedOrganizationId: undefined,
    onExpand: undefined,
    onButtonTapped: undefined,
    organizations: undefined,
};

export default connect(mapStateToProps, Actions)(MOrganizationAdmin);
