import {
    isEmpty,
    first,
    last,
    min,
    max,
} from 'underscore';
import { format } from 'date-fns';
import es from 'date-fns/locale/es';

class SensorDatasetParser {
    static labelFormatter(unixTime, dateFormat = "d 'de' MMMM p") {
        const date = new Date(unixTime * 1000);

        try {
            return format(date, dateFormat, { locale: es });
        } catch (error) {
            return null;
        }
    }

    static formatDataset(dataset) {
        if (isEmpty(dataset)) return [];

        const sortedDataset = dataset.sort((a, b) => { return a.label - b.label; });

        const parsedDataset = [];

        sortedDataset.forEach(({ label, value }) => {
            const labelDatetime = SensorDatasetParser.labelFormatter(label);

            parsedDataset.push(
                {
                    rawLabel: label,
                    rawValue: value,
                    label: labelDatetime,
                    value: Math.floor(value * 10) / 10,
                },
            );
        });

        return parsedDataset;
    }

    static extractDatasetStats(parsedDataset) {
        const firstReading = first(parsedDataset);
        const lastReading = last(parsedDataset);
        const minReading = min(parsedDataset, (reading) => { return reading.value; });
        const maxReading = max(parsedDataset, (reading) => { return reading.value; });

        return {
            firstReading,
            lastReading,
            minReading,
            maxReading,
        };
    }

    static aggregateHours(dataset, threshold) {
        if (isEmpty(dataset)) return [];

        let aggregatedValue = 0;

        for (let index = 1; index < dataset.length; index++) {
            const previousRow = dataset[index - 1];
            const currentRow = dataset[index];

            if (previousRow.value > threshold) {
                aggregatedValue += currentRow.label - previousRow.label;
            }
        }

        return aggregatedValue;
    }

    static countTurnOn(dataset, threshold) {
        if (isEmpty(dataset)) return 0;

        let counter = 0;

        for (let index = 1; index < dataset.length; index++) {
            const previousRow = dataset[index - 1];
            const currentRow = dataset[index];

            if (previousRow.value + threshold < currentRow.value) {
                counter += 1;
            }
        }

        return counter;
    }
}

export default SensorDatasetParser;
