import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const MSelect = (props) => {
    const {
        label,
        checkedOption,
        options,
        onChange,
    } = props;

    const renderOptions = () => {
        return options.map((option) => {
            const { id, text } = option;

            const checked = checkedOption === id;

            return (
                <label
                    key={id}
                    htmlFor={id}
                    className={checked ? 'checked' : ''}
                >
                    <input
                        type="radio"
                        id={id}
                        name="selector"
                        checked={checked}
                        onChange={onChange}
                    />
                    { text }
                </label>
            );
        });
    };

    return (
        <form>
            <div className="radio-group-label">{ label }</div>
            <div className="radio-group">
                { renderOptions() }
            </div>
        </form>
    );
};

MSelect.propTypes = {
    checkedOption: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        text: PropTypes.string,
        checked: PropTypes.boolean,
    })),
    onChange: PropTypes.func,
};

MSelect.defaultProps = {
    options: [],
    onChange: undefined,
};

export default MSelect;
