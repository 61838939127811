import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Form,
    FormGroup,
    FormFeedback,
    Label,
    Input,
} from 'reactstrap';
import {
    isUndefined,
} from 'underscore';

import MeasureVerticalSpacer from '../general/vertical_spacer';
import MeasureLoader from '../general/loader';
import MeasureRegex from '../../helpers/regex_helper';

class MeasureFormLogIn extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: {
                value: undefined,
                isValid: false,
                error: '',
            },
            password: {
                value: undefined,
                isValid: false,
                error: '',
            },
            formSubmitted: false,
            handleSubmit: props.handleSubmit,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.setPassword = this.setPassword.bind(this);
        this.setEmail = this.setEmail.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();

        const { email, password, handleSubmit } = this.state;

        if (email.isValid && password.isValid) {
            this.setState({
                formSubmitted: true,
            });

            const credentials = {
                email: email.value,
                password: password.value,
            };

            handleSubmit(credentials);
        }
    }

    setEmail(event) {
        const newVal = event.target.value;
        const isValid = MeasureRegex.isValidMail(newVal);

        let error = '';
        if (!isValid) {
            error = 'Eso no es un email...';
        }

        this.setState({
            email: {
                value: newVal,
                isValid,
                error,
            },
        });
    }

    setPassword(event) {
        const newVal = event.target.value || '';
        const isValid = newVal.length >= 8;

        let error = '';
        if (!isValid) {
            error = 'La contraseña es muy corta';
        }

        this.setState({
            password: {
                value: newVal,
                isValid,
                error,
            },
        });
    }

    renderEmailField() {
        const { email, formSubmitted } = this.state;

        return (
            <FormGroup>
                <Label for="loginEmail">Email</Label>
                <Input
                    type="email"
                    name="loginEmail"
                    id="loginEmail"
                    placeholder="dhoward@measure.cl"
                    onKeyUp={this.setEmail}
                    onChange={this.setEmail}
                    onInput={this.setEmail}
                    disabled={formSubmitted}
                    valid={email.isValid}
                    invalid={!email.isValid && !isUndefined(email.value)}
                />
                <FormFeedback>{email.error}</FormFeedback>
            </FormGroup>
        );
    }

    renderPasswordField() {
        const { password, formSubmitted } = this.state;

        return (
            <FormGroup>
                <Label for="loginPassword">Contraseña</Label>
                <Input
                    type="password"
                    name="loginPassword"
                    id="loginPassword"
                    placeholder="••••••••"
                    onKeyUp={this.setPassword}
                    onChange={this.setPassword}
                    onInput={this.setPassword}
                    disabled={formSubmitted}
                    valid={password.isValid}
                    invalid={!password.isValid && !isUndefined(password.value)}
                />
                <FormFeedback>{password.error}</FormFeedback>
            </FormGroup>
        );
    }

    renderSubmitButton() {
        const { email, password } = this.state;

        const submitDisabled = !email.isValid || !password.isValid;
        if (submitDisabled) return null;

        return (
            <div>
                <MeasureVerticalSpacer height="2rem" />
                <Button color="primary" block>Submit</Button>
            </div>
        );
    }

    render() {
        const { formSubmitted } = this.state;

        if (formSubmitted) {
            return (
                <MeasureLoader size="md" centered />
            );
        }

        return (
            <Form onSubmit={this.handleSubmit} autoComplete="off">
                {
                    this.renderEmailField()
                }
                {
                    this.renderPasswordField()
                }
                {
                    this.renderSubmitButton()
                }
            </Form>
        );
    }
}

MeasureFormLogIn.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
};

export default MeasureFormLogIn;
