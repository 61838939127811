import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'underscore';
import { MeasureRequestHandler } from '@measure-iot/api';

import MeasurePropTypes from '../../../propTypes';
import * as Actions from '../../../actions';

import MCard from '../mcard_base';
import MLoader from '../../mloader';
import MDataEmptyState from '../../mdata_charts/body_empty_state';

import JsonTextarea from '../../mjson_textarea';
import MForm from '../../mform';
import SENSOR_FIELDS from '../../mform/fields/sensor';
import SENSOR_PUT_FIELDS from '../../mform/fields/sensorPut';
import unwrapper from '../../../helpers/unwrapper';

import './style.scss';

class MSensorAdmin extends PureComponent {
    constructor(props) {
        super(props);

        this.cancelToken = new MeasureRequestHandler();
        this.handleActionButtonOnClick = this.handleActionButtonOnClick.bind(this);
        this.triggerPostSensor = this.triggerPostSensor.bind(this);
        this.triggerPutSensor = this.triggerPutSensor.bind(this);
        this.triggerDeleteSensor = this.triggerDeleteSensor.bind(this);
    }

    componentDidMount() {
        this.triggerGetAllSensors();
    }

    componentDidUpdate(prevProps) {
        const { selectedDeviceId } = this.props;

        if (selectedDeviceId !== prevProps.selectedDeviceId) {
            this.triggerGetAllSensors();
        }
    }

    triggerGetAllSensors() {
        const { selectedDeviceId, triggerGetAllSensors } = this.props;

        triggerGetAllSensors(selectedDeviceId, this.cancelToken);
    }

    triggerPostSensor(json) {
        const { triggerPostSensor } = this.props;

        return triggerPostSensor(json, this.cancelToken);
    }

    triggerPutSensor(id, json) {
        const { triggerPutSensor } = this.props;

        return triggerPutSensor(id, json, this.cancelToken);
    }

    triggerDeleteSensor(sensor) {
        const { triggerDeleteSensor, onSelect } = this.props;

        const { id, name } = sensor;

        const accepted = window.confirm(`Estás por eliminar el sensor "${name}"\n\nEsta acción no puede deshacerse`);
        if (!accepted) return;

        triggerDeleteSensor(id, this.cancelToken);

        if (!_.isFunction(onSelect)) return;

        onSelect();
    }

    handleActionButtonOnClick(id) {
        const { onSelect } = this.props;

        if (!_.isFunction(onSelect)) return;

        onSelect(id);
    }

    static renderLoader() {
        return (
            <MLoader size="md" centered />
        );
    }

    renderSensors() {
        const { sensors, selectedSensorId } = this.props;

        if (_.isUndefined(sensors)) return MSensorAdmin.renderLoader();

        if (_.isEmpty(sensors)) return <MDataEmptyState title="No hay sensores" />;

        return sensors.map((sensor) => {
            const {
                id,
                name,
                additionalInfo,
                deviceId,
                gateId,
                gateType,
                status,
                dataVariable,
                thresholds,
            } = sensor;

            return (
                <MCard
                    key={id}
                    active={id === selectedSensorId}
                    title={name}
                    subtitle="Sensor"
                    expandable
                    expanded={false}
                    actionButtonOnClick={() => { this.handleActionButtonOnClick(id); }}
                    actionButtonText="Calibraciones"
                >
                    <ul className="msensor-admin-list">
                        <li>Id <JsonTextarea jsonPayload={id} /></li>

                        <MForm
                            fields={SENSOR_PUT_FIELDS}
                            defaults={{
                                id,
                                name,
                                additional_info: additionalInfo,
                                status,
                                gate_id: gateId,
                                gate_type: gateType,
                                device_id: deviceId,
                                'data_variable->min': unwrapper(dataVariable, 'min'),
                                'data_variable->max': dataVariable.max,
                                'data_variable->unit': dataVariable.unit,
                                'data_variable->name': dataVariable.name,
                                'thresholds->minValue': unwrapper(thresholds, 'minValue'),
                                'thresholds->maxValue': unwrapper(thresholds, 'maxValue'),
                            }}
                            onSubmit={(json) => { return this.triggerPutSensor(id, json); }}
                            submitButtonText="Guardar"
                        />
                    </ul>

                    <button
                        type="button"
                        className="btn btn-danger btn-block"
                        onClick={() => { this.triggerDeleteSensor(sensor); }}
                    >
                        Eliminar
                    </button>
                </MCard>
            );
        });
    }

    renderForm() {
        const { selectedDeviceId } = this.props;

        return (
            <MCard title="Nuevo Sensor" expandable expanded={false}>
                <MForm
                    fields={SENSOR_FIELDS}
                    defaults={{
                        device_id: selectedDeviceId,
                    }}
                    hiddenFields={[
                        'device_id',
                    ]}
                    onSubmit={this.triggerPostSensor}
                    submitButtonText="Crear"
                />
            </MCard>
        );
    }

    render() {
        return (
            <MCard title="Sensores">
                { this.renderForm() }

                <br />

                { this.renderSensors() }
            </MCard>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const { selectedDeviceId } = ownProps;
    const { sensors } = state.sensors;

    const newProps = {};

    if (_.isObject(sensors) && !_.isEmpty(sensors)) {
        newProps.sensors = Object.values(sensors).filter((sensor) => {
            return sensor.deviceId === selectedDeviceId;
        });

        newProps.sensors.sort((lhs, rhs) => {
            return lhs.name.localeCompare(rhs.name);
        });
    }

    return newProps;
};

MSensorAdmin.propTypes = {
    selectedDeviceId: PropTypes.string.isRequired,
    selectedSensorId: PropTypes.string,
    onSelect: PropTypes.func,

    // Redux
    sensors: MeasurePropTypes.SENSORS,

    // Actions
    triggerGetAllSensors: PropTypes.func.isRequired,
    triggerPostSensor: PropTypes.func.isRequired,
    triggerPutSensor: PropTypes.func.isRequired,
    triggerDeleteSensor: PropTypes.func.isRequired,
};

MSensorAdmin.defaultProps = {
    selectedSensorId: undefined,
    onSelect: undefined,
    sensors: undefined,
};

export default connect(mapStateToProps, Actions)(MSensorAdmin);
