/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
/* eslint-disable no-control-regex */

import _ from 'underscore';

const test = (value, regex) => {
    return !_.isUndefined(value) && regex.test(value);
};

export const isValidUUID = (value) => {
    const uuidRegex = new RegExp(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i);

    return test(value, uuidRegex);
};

class MeasureRegex {
    static isMail(value) {
        const regex = new RegExp(/@|\./);

        return test(value, regex);
    }

    static isPhone(value) {
        const regex = new RegExp(/^\+?(\s?\d)+$/);

        return test(value, regex);
    }

    static isValidMail(value) {
        const regex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

        return test(value, regex);
    }

    static isValidPhone(value) {
        const regex = new RegExp(/^(^\+*\d{2}\s?)?\d{1}\s?\d{4}\s?\d{4}$/);

        return test(value, regex);
    }

    static isValidPassword(value) {
        const regex = new RegExp(/^[^\sáéíóú]{8,20}$/gi);

        return test(value, regex);
    }

    static test(value, regex) {
        return !_.isUndefined(value) && regex.test(value);
    }
}

export default MeasureRegex;
