const TYPES = {
    SET: 'devices.set',
    SET_SIGNATURE: 'devices.datasets.setSignature',
    DATASETS: {
        SET: 'devices.datsets.set',
    },
    SET_DATASETS_TIME_SPAN: 'devices.datasets.setDatasetsTimeSpan',
};

export default TYPES;
