import Cookies from 'universal-cookie';
import {
    isEmpty,
    isString,
} from 'underscore';

const tokenCookieName = '_id:mdt';

export const getAuthToken = () => {
    const cookie = new Cookies();
    const authToken = cookie.get(tokenCookieName);

    if (isEmpty(authToken) || !isString(authToken)) return undefined;

    return authToken;
};

export const setAuthToken = (authToken, maxAge = process.env.REACT_APP_AUTH_TOKEN_TTL, path = '/') => {
    const cookie = new Cookies();

    cookie.set(tokenCookieName, authToken, {
        path,
        maxAge,
    });
};

export const removeAuthToken = () => {
    const cookie = new Cookies();
    cookie.remove(tokenCookieName);
};

export const isAuthenticated = () => {
    const token = getAuthToken();

    return !isEmpty(token);
}