const TYPES = {
    SET: 'hubs.set',
    SET_REPORT: 'hubs.setReport',
    REPORTS: {
        SET: 'hubs.report.set',
        CLEAR_ALL: 'hubs.report.clearAll',
    },
};

export default TYPES;
