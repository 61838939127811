const THEME_MODES = {
    dark: 'dark',
    light: 'light',
};

const getGlobalTheme = () => {
    return localStorage.getItem('data-theme') || THEME_MODES.light;
};

const setGlobalTheme = (newTheme) => {
    localStorage.setItem('data-theme', newTheme);
    document.documentElement.setAttribute('data-theme', newTheme);
};

export default {
    THEME_MODES,
    getGlobalTheme,
    setGlobalTheme,
};
