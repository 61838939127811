import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const MCardEmptyState = (props) => {
    const {
        title,
        icon,
        subtitle,
        height,
    } = props;

    const titleElement = title && (
        <div className="mcard-empty-state-title">
            { title }
        </div>
    );

    const iconElement = icon && (
        <div className="mcard-empty-state-icon">
            { icon }
        </div>
    );

    const subtitleElement = subtitle && (
        <div className="mcard-empty-state-subtitle">
            { subtitle }
        </div>
    );

    return (
        <div style={{ height }}>
            <div className="mcard-empty-state">
                { titleElement }
                { iconElement }
                { subtitleElement }
            </div>
        </div>
    );
};

MCardEmptyState.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.node,
    subtitle: PropTypes.string,
    height: PropTypes.number,
};

MCardEmptyState.defaultProps = {
    title: undefined,
    icon: undefined,
    subtitle: undefined,
    height: 300,
};

export default MCardEmptyState;
