import _ from 'underscore';
import TYPES from './types';

import { isValidUUID } from '../../helpers/regex_helper';

const defaultState = {
    devices: {},
    datasets: {},
    datasetsTimeSpan: {
        startEpoch: undefined,
        endEpoch: undefined,
    },
};

const Reducer = (oldState = defaultState, dispatchedAction) => {
    const { type, payload } = dispatchedAction;

    const newState = { ...oldState };

    switch (type) {
    case TYPES.SET: {
        const { deviceId, device } = payload;
        if (!isValidUUID(deviceId)) break;

        if (_.isUndefined(device)) {
            delete newState.devices[deviceId];
            break;
        }

        newState.devices[deviceId] = device;
        break;
    }

    case TYPES.DATASETS.SET: {
        const { deviceId, dataset } = payload;
        if (!isValidUUID(deviceId)) break;

        if (_.isUndefined(dataset)) {
            delete newState.datasets[deviceId];
            break;
        }

        newState.datasets[deviceId] = dataset;
        break;
    }

    case TYPES.SET_SIGNATURE: {
        const { deviceId, signature } = payload;
        if (!isValidUUID(deviceId)) break;

        if (_.isEmpty(signature)) break;

        newState.devices[deviceId].signature = signature;
        break;
    }

    case TYPES.SET_DATASETS_TIME_SPAN: {
        const { startEpoch, endEpoch } = payload;

        if (!_.isNumber(startEpoch)) break;
        if (!_.isNumber(endEpoch)) break;
        if (endEpoch < startEpoch) break;
        if (startEpoch < 0) break;
        if (endEpoch < 0) break;

        newState.datasetsTimeSpan.startEpoch = startEpoch;
        newState.datasetsTimeSpan.endEpoch = endEpoch;
        break;
    }

    default:
        break;
    }

    return newState;
};

export default Reducer;
