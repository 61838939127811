import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MeasureRequestHandler } from '@measure-iot/api';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    Redirect,
} from 'react-router-dom';
import _ from 'underscore';

import * as Actions from './actions';

import * as Authenticator from './helpers/authenticator';
import ThemeHelper from './helpers/theme_helper';

import ProtectedRoute from './components/middleware/protected_route';

import MLayout from './mcomponents/mlayout';
import MeasureLanding from './components/pages/landing';
import MDashboardPage from './mpages/dashboard';
import LoginPage from './components/pages/login';
import LogoutPage from './components/pages/login/logout';
import MHubPage from './mpages/hubs';
import MProfilePage from './mpages/user_profile';
import MeasureNotFound from './components/pages/not_found';
import MeasureWallboard from './components/pages/wallboard';
import MAdminPage from './mpages/admin';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/base_style.scss';

class App extends Component {
    constructor(props) {
        super(props);

        this.handleInitialLogin();

        const currentTheme = ThemeHelper.getGlobalTheme();
        ThemeHelper.setGlobalTheme(currentTheme);

        this.cancelToken = new MeasureRequestHandler();
        this.handleLogin = this.handleLogin.bind(this);
    }

    componentWillUnmount() {
        this.cancelToken.cancel();
    }

    async handleInitialLogin() {
        const { triggerLoginWithToken, triggerLogout } = this.props;

        const error = await triggerLoginWithToken(this.cancelToken);

        if (error) {
            await triggerLogout(this.cancelToken);
        }
    }

    async handleLogin(email, password) {
        const { triggerLoginWithCredentials } = this.props;

        return triggerLoginWithCredentials(email, password, this.cancelToken);
    }

    render() {
        const { loggedIn } = this.props;

        return (
            <Router>
                <Switch>
                    <Route exact path={loggedIn ? '/home' : '/'}>
                        <MeasureLanding />
                    </Route>
                    <Route exact path="/login">
                        <LoginPage handleSubmit={this.handleLogin} />
                    </Route>
                    <ProtectedRoute exact path="/logout">
                        <LogoutPage />
                    </ProtectedRoute>
                    <ProtectedRoute path="/hubs/:hubId">
                        <MLayout>
                            <RouteHubs />
                        </MLayout>
                    </ProtectedRoute>
                    <ProtectedRoute exact path="/account">
                        <MLayout>
                            <MProfilePage />
                        </MLayout>
                    </ProtectedRoute>
                    <ProtectedRoute exact path="/">
                        <MLayout>
                            <MDashboardPage />
                        </MLayout>
                    </ProtectedRoute>
                    <ProtectedRoute exact path="/tv">
                        <MeasureWallboard />
                    </ProtectedRoute>
                    <ProtectedRoute exact path="/admin" requiredRoles={['admin']}>
                        <MLayout>
                            <MAdminPage />
                        </MLayout>
                    </ProtectedRoute>
                    <Route path="/not-found">
                        <MeasureNotFound />
                    </Route>
                    <Route path="*">
                        <Redirect to={{ pathname: '/not-found' }} />
                    </Route>
                </Switch>
            </Router>
        );
    }
}

const RouteHubs = () => {
    const { hubId } = useParams();

    if (_.isEmpty(hubId)) {
        return <Redirect to={{ pathname: '/' }} />;
    }

    return <MHubPage hubId={hubId} />;
};

const mapStateToProps = (state) => {
    const { currentUser } = state.users;

    const newProps = {};

    newProps.loggedIn = !_.isEmpty(currentUser) || Authenticator.isAuthenticated();

    return newProps;
};

App.propTypes = {
    loggedIn: PropTypes.bool.isRequired,
    triggerLoginWithToken: PropTypes.func.isRequired,
    triggerLoginWithCredentials: PropTypes.func.isRequired,
    triggerLogout: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, Actions)(App);
