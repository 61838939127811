/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import 'dotenv/config';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';

import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

import App from './App';

registerLocale('es', es);
setDefaultLocale('es');

const store = createStore(rootReducer, applyMiddleware(thunk));
window.store = store;
ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>, document.getElementById('root'),
);
