import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
    Form,
    FormGroup,
    Label,
    Input,
    FormFeedback,
    FormText,
    Button,
    Alert,
} from 'reactstrap';
import {
    TiDelete,
} from 'react-icons/ti';
import _ from 'underscore';

import './style.scss';

export const INPUT_TYPES = {
    text: 'text',
    email: 'email',
    select: 'select',
    file: 'file',
    radio: 'radio',
    checkbox: 'checkbox',
    textarea: 'textarea',
    button: 'button',
    reset: 'reset',
    submit: 'submit',
    date: 'date',
    datetimeLocal: 'datetime-local',
    hidden: 'hidden',
    image: 'image',
    month: 'month',
    number: 'number',
    range: 'range',
    search: 'search',
    tel: 'tel',
    url: 'url',
    week: 'week',
    password: 'password',
    datetime: 'datetime',
    time: 'time',
    color: 'color',
};

const NEST_SEPARATOR = '->';
const DEFAULT_ERROR_MESSAGE = 'Encontramos un error';

const mapKeyToJson = (jsonKey, value) => {
    const json = {};
    const [current, ...keys] = jsonKey.split(NEST_SEPARATOR);

    let nestedJson = value;

    if (keys.length > 0) {
        nestedJson = mapKeyToJson(
            keys.join(NEST_SEPARATOR),
            value,
        );
    }

    return {
        ...json,
        [current]: nestedJson,
    };
};

const mergeObjects = (first, second) => {
    const firstKeys = Object.keys(first);
    const secondKeys = Object.keys(second).filter((key) => {
        return !firstKeys.includes(key);
    });

    const keys = [
        ...firstKeys,
        ...secondKeys,
    ];

    return keys.reduce((previous, key) => {
        const firstValue = first[key];
        const secondValue = second[key];

        if (_.isObject(firstValue) && _.isObject(secondValue)) {
            const merged = mergeObjects(firstValue, secondValue);
            return { ...previous, [key]: merged };
        }

        if (_.isObject(firstValue)) {
            return { ...previous, [key]: firstValue };
        }

        if (_.isObject(secondValue)) {
            return { ...previous, [key]: secondValue };
        }

        if (_.isUndefined(secondValue)) {
            return { ...previous, [key]: firstValue };
        }

        return { ...previous, [key]: secondValue };
    }, {});
};

class MForm extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            formValues: props.defaults,
            formErrors: {},
            submitError: undefined,
            submitMessage: undefined,
            submitButtonText: props.submitButtonText,
        };

        this.handleFieldOnChange = this.handleFieldOnChange.bind(this);
        this.handleFieldOnBlur = this.handleFieldOnBlur.bind(this);
        this.handleOnSubmit = this.handleOnSubmit.bind(this);
        this.handleFormClear = this.handleFormClear.bind(this);
        this.handleFieldClear = this.handleFieldClear.bind(this);
    }

    handleFieldOnChange(event) {
        const { id: jsonKey, value } = event.target;
        const field = this.getField(jsonKey);

        if (_.isEmpty(field)) return;

        const {
            type,
            onChangeTransform,
            onChangeValidation,
            onChangeErrorMessage,
        } = field;

        let newValue = this.castFieldValue(type, value);
        if (_.isFunction(onChangeTransform)) {
            newValue = onChangeTransform(newValue);
        }

        this.setFieldValue(jsonKey, newValue);
        const isValid = _.isFunction(onChangeValidation) ? onChangeValidation(newValue) : true;

        if (isValid) {
            this.setFieldError(jsonKey, undefined);
        } else {
            this.setFieldError(jsonKey, onChangeErrorMessage || DEFAULT_ERROR_MESSAGE);
        }
    }

    handleFieldOnBlur(event) {
        const { id: jsonKey, value } = event.target;
        const field = this.getField(jsonKey);

        if (_.isEmpty(field)) return;
        const {
            type,
            onBlurValidation,
            onBlurErrorMessage,
        } = field;

        const newValue = this.castFieldValue(type, value);
        const isValid = _.isFunction(onBlurValidation) ? onBlurValidation(newValue) : true;

        if (isValid) {
            this.setFieldError(jsonKey, undefined);
        } else {
            this.setFieldError(jsonKey, onBlurErrorMessage || DEFAULT_ERROR_MESSAGE);
        }
    }

    async handleOnSubmit(event) {
        event.preventDefault();
        const { fields, onSubmit } = this.props;

        this.setState({ submitError: undefined, submitMessage: undefined });

        const formErrors = {};
        let json = {};

        fields.forEach((field) => {
            if (this.isLabel(field)) return;

            const {
                jsonKey,
                onSubmitValidation,
                onSubmitErrorMessage,
            } = field;

            const value = this.getFieldValue(jsonKey);
            const isHidden = this.isHidden(field);
            const isChanged = this.isChanged(field);
            const isValid = _.isFunction(onSubmitValidation) ? onSubmitValidation(value) : true;

            if (isValid && (isChanged || isHidden)) {
                json = mergeObjects(
                    json,
                    mapKeyToJson(jsonKey, value),
                );
            } else if (!isValid) {
                formErrors[jsonKey] = onSubmitErrorMessage || DEFAULT_ERROR_MESSAGE;
            }
        });

        const errorCount = Object.keys(formErrors).length;
        this.setState({ formErrors });

        if (errorCount > 0) {
            const submitError = `Encontramos ${errorCount} ${errorCount < 2 ? 'error' : 'errores'}`;
            this.setState({ submitError });

            return;
        }

        if (_.isEmpty(json)) {
            const submitMessage = 'No hiciste ningún cambio';
            this.setState({ submitMessage });

            return;
        }

        this.setState({ submitButtonText: 'Enviando...' });
        const error = await onSubmit(json);

        if (_.isUndefined(error)) {
            this.setState({ submitMessage: 'Éxito!' });
        } else {
            this.setState({ submitError: error.message, submitMessage: undefined, submitButtonText: 'Reenviar' });
        }
    }

    handleFormClear() {
        const { defaults, submitButtonText } = this.props;

        this.setState({
            formValues: defaults,
            formErrors: {},
            submitError: undefined,
            submitMessage: undefined,
            submitButtonText,
        });
    }

    handleFieldClear(jsonKey) {
        this.setFieldValue(jsonKey, null);
        this.setFieldError(jsonKey, undefined);
    }

    getField(jsonKey) {
        const { fields } = this.props;

        return fields.find((field) => {
            return field.jsonKey === jsonKey;
        });
    }

    getFieldValue(jsonKey) {
        const { formValues } = this.state;

        const value = formValues[jsonKey];

        if (_.isNull(value) || !_.isUndefined(value)) return value;

        const field = this.getField(jsonKey);
        const { type, options } = field;

        if (type !== INPUT_TYPES.select) return null;

        return _.first(options).value;
    }

    getDefaultValue(jsonKey) {
        const { defaults } = this.props;

        return defaults[jsonKey];
    }

    setFieldValue(jsonKey, value) {
        const { formValues } = this.state;

        this.setState({
            formValues: {
                ...formValues,
                [jsonKey]: value === '' ? null : value,
            },
        });
    }

    getFieldError(jsonKey) {
        const { formErrors } = this.state;

        return formErrors[jsonKey];
    }

    setFieldError(jsonKey, error) {
        const { formErrors } = this.state;

        this.setState({
            formErrors: {
                ...formErrors,
                [jsonKey]: error,
            },
        });
    }

    castFieldValue(type, value) {
        if (type === INPUT_TYPES.number || type === INPUT_TYPES.range) {
            return Number(value);
        }

        return value;
    }

    isLabel(field) {
        const { type } = field;

        return _.isEmpty(type);
    }

    isHidden(field) {
        const { hiddenFields } = this.props;
        const { jsonKey, type } = field;

        if (hiddenFields.includes(jsonKey)) return true;

        return type === INPUT_TYPES.hidden;
    }

    isChanged(field) {
        const { jsonKey } = field;

        const value = this.getFieldValue(jsonKey);
        const defaultValue = this.getDefaultValue(jsonKey);

        return value !== defaultValue;
    }

    renderLabelField(field) {
        const { label } = field;

        return (
            <h5
                key={label}
            >
                {label}
            </h5>
        );
    }

    renderHiddenField(field, value = '') {
        const { jsonKey } = field;

        return (
            <Input
                key={jsonKey}
                id={jsonKey}
                name={jsonKey}
                type="hidden"
                value={value}
            />
        );
    }

    renderSelectFieldOptions(field) {
        const { type, options } = field;

        if (type !== INPUT_TYPES.select || _.isEmpty(options)) return undefined;

        return options.map((option) => {
            const { label, value } = option;

            return <option key={value} value={value}>{label}</option>;
        });
    }

    renderField(field, value = '', error) {
        const {
            jsonKey,
            type,
            label,
            help,
            placeholder,
            disabled,
        } = field;

        const indentSize = jsonKey.split(NEST_SEPARATOR).length - 1;
        const style = { paddingLeft: `${indentSize}rem` };

        const renderedOptions = this.renderSelectFieldOptions(field);

        let fieldBottomText;
        if (!_.isEmpty(error)) {
            fieldBottomText = <FormFeedback valid={false}>{error}</FormFeedback>;
        } else if (!_.isEmpty(help)) {
            fieldBottomText = <FormText>{help}</FormText>;
        }

        return (
            <FormGroup
                key={jsonKey}
                style={style}
            >
                <Label
                    for={jsonKey}
                >
                    {label}
                </Label>
                <div className="mform-field-wrapper">
                    <Input
                        id={jsonKey}
                        type={type}
                        name={jsonKey}
                        value={value === null ? '' : value}
                        placeholder={placeholder || 'null'}
                        onChange={this.handleFieldOnChange}
                        onBlur={this.handleFieldOnBlur}
                        invalid={!_.isEmpty(error)}
                        disabled={disabled}
                    >
                        { renderedOptions }
                    </Input>
                    <button
                        type="button"
                        onClick={() => {
                            this.handleFieldClear(jsonKey);
                        }}
                    >
                        <TiDelete />
                    </button>
                </div>
                { fieldBottomText }
            </FormGroup>
        );
    }

    mapField(field) {
        if (_.isEmpty(field)) return null;

        if (this.isLabel(field)) {
            return this.renderLabelField(field);
        }

        const { jsonKey } = field;
        const value = this.getFieldValue(jsonKey);

        if (this.isHidden(field)) {
            return this.renderHiddenField(field, value);
        }

        const error = this.getFieldError(jsonKey);
        return this.renderField(field, value, error);
    }

    renderFields() {
        const { fields } = this.props;

        return fields.map((field) => {
            return this.mapField(field);
        });
    }

    renderSubmitButton() {
        const { submitButtonText } = this.state;

        return (
            <Button
                type="submit"
                color="primary"
                outline
                className="pull-right"
            >
                { submitButtonText }
            </Button>
        );
    }

    renderSubmitError() {
        const { submitError } = this.state;

        if (!_.isEmpty(submitError)) {
            return (
                <Alert color="danger">
                    { submitError }
                </Alert>
            );
        }

        return null;
    }

    renderSubmitMessage() {
        const { submitMessage } = this.state;

        if (_.isEmpty(submitMessage)) return null;

        return (
            <div>
                <Alert color="success">
                    { submitMessage }
                </Alert>
                <Button
                    type="button"
                    color="primary"
                    outline
                    className="pull-right"
                    onClick={this.handleFormClear}
                >
                    Volver
                </Button>
            </div>
        );
    }

    render() {
        const { formId } = this.props;

        const submitMessage = this.renderSubmitMessage();
        if (!_.isEmpty(submitMessage)) {
            return submitMessage;
        }

        return (
            <Form
                id={formId}
                onSubmit={this.handleOnSubmit}
                className="clearfix"
            >
                { this.renderFields() }
                { this.renderSubmitError() }
                { this.renderSubmitButton() }
            </Form>
        );
    }
}

MForm.propTypes = {
    formId: PropTypes.string,
    submitButtonText: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,

    fields: PropTypes.arrayOf(PropTypes.shape({
        jsonKey: PropTypes.string,
        type: PropTypes.oneOf(Object.values(INPUT_TYPES)),
        label: PropTypes.string,
        help: PropTypes.string,
        placeholder: PropTypes.string,
        disabled: PropTypes.bool,

        options: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.bool,
            ]),
        })),

        onChangeTransform: PropTypes.func,

        onChangeErrorMessage: PropTypes.string,
        onChangeValidation: PropTypes.func,

        onBlurErrorMessage: PropTypes.string,
        onBlurValidation: PropTypes.func,

        onSubmitErrorMessage: PropTypes.string,
        onSubmitValidation: PropTypes.func,
    })).isRequired,
    hiddenFields: PropTypes.arrayOf(
        PropTypes.string,
    ),
    defaults: PropTypes.shape({
        [PropTypes.string]: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool,
        ]),
    }),
};

MForm.defaultProps = {
    formId: undefined,
    submitButtonText: 'Submit',
    defaults: {},
    hiddenFields: [],
};

export default MForm;
