import MeasureRegex from '../../../helpers/regex_helper';
import { INPUT_TYPES } from '../index';

const FIELDS = [
    {
        jsonKey: 'oldPassword',
        label: 'Contraseña Actual',
        placeholder: '••••••••',
        type: INPUT_TYPES.password,
        onChangeErrorMessage: 'Debe tener entre 8 y 20 caracteres.',
        onChangeValidation: (value) => {
            return MeasureRegex.isValidPassword(value);
        },
        onBlurErrorMessage: 'Debe tener entre 8 y 20 caracteres.',
        onBlurValidation: (value) => {
            return MeasureRegex.isValidPassword(value);
        },
        onSubmitErrorMessage: 'Debe tener entre 8 y 20 caracteres.',
        onSubmitValidation: (value) => {
            return MeasureRegex.isValidPassword(value);
        },
    },
    {
        jsonKey: 'password',
        label: 'Nueva Contraseña',
        placeholder: '••••••••',
        type: INPUT_TYPES.password,
        onChangeErrorMessage: 'Debe tener entre 8 y 20 caracteres, sin espacios ni tildes.',
        onChangeValidation: (value) => {
            return MeasureRegex.isValidPassword(value) && document.getElementById('oldPassword').value !== value;
        },
        onBlurErrorMessage: 'Debe tener entre 8 y 20 caracteres, sin espacios ni tildes.',
        onBlurValidation: (value) => {
            return MeasureRegex.isValidPassword(value) && document.getElementById('oldPassword').value !== value;
        },
        onSubmitErrorMessage: 'Debe tener entre 8 y 20 caracteres, sin espacios ni tildes.',
        onSubmitValidation: (value) => {
            return MeasureRegex.isValidPassword(value) && document.getElementById('oldPassword').value !== value;
        },
    },
];

export default FIELDS;
