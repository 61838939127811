import React from 'react';
import PropTypes from 'prop-types';

const HeaderButton = (props) => {
    const {
        className,
        onClick,
        children,
    } = props;

    return (
        <button
            className={className}
            type="button"
            onClick={onClick}
        >
            {children}
        </button>

    );
};

HeaderButton.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    onClick: PropTypes.func.isRequired,
};

HeaderButton.defaultProps = {
    className: undefined,
    children: undefined,
};

export default HeaderButton;
