import { combineReducers } from 'redux';

import * as ReduxActions from '../actions/types';

import EntitiesReducer from './entities_reducer';
import UserReducer from './users/reducer';
import OrganizationReducer from './organizations/reducer';
import HubReducer from './hubs/reducer';
import DevicesReducer from './devices/reducer';
import SensorsReducer from './sensors/reducer';
import ContactsReducer from './contacts/reducer';

const appReducer = combineReducers({
    entities: EntitiesReducer,
    users: UserReducer,
    organizations: OrganizationReducer,
    hubs: HubReducer,
    devices: DevicesReducer,
    sensors: SensorsReducer,
    contacts: ContactsReducer,
});

const rootReducer = (state, dispatchedAction) => {
    let newState = state;

    if (dispatchedAction.type === ReduxActions.APP_CLEAR_STORE) {
        newState = undefined;
    }

    return appReducer(newState, dispatchedAction);
};

export default rootReducer;
