import _ from 'underscore';
import TYPES from './types';

import { isValidUUID } from '../../helpers/regex_helper';

const defaultState = {
    sensors: {},
};

const Reducer = (oldState = defaultState, dispatchedAction) => {
    const { type, payload } = dispatchedAction;

    const newState = { ...oldState };

    switch (type) {
    case TYPES.SET: {
        const { sensorId, sensor } = payload;
        if (!isValidUUID(sensorId)) break;

        if (_.isUndefined(sensor)) {
            delete newState.sensors[sensorId];
            break;
        }

        newState.sensors[sensorId] = sensor;
        break;
    }

    default: {
        break;
    }
    }

    return newState;
};

export default Reducer;
