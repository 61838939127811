import _ from 'underscore';
import TYPES from './types';

import { isValidUUID } from '../../helpers/regex_helper';

const defaultState = {
    hubs: {},
    reports: {},
};

const Reducer = (oldState = defaultState, dispatchedAction) => {
    const { type, payload } = dispatchedAction;

    const newState = { ...oldState };

    switch (type) {
    case TYPES.SET: {
        const { hubId, hub } = payload;
        if (!isValidUUID(hubId)) break;

        if (_.isUndefined(hub)) {
            delete newState.hubs[hubId];
            break;
        }

        newState.hubs[hubId] = hub;
        break;
    }

    case TYPES.REPORTS.SET: {
        const { reportId, report } = payload;
        if (!isValidUUID(reportId)) break;

        if (_.isUndefined(report)) {
            delete newState.reports[reportId];
            break;
        }

        newState.reports[reportId] = report;
        break;
    }

    case TYPES.REPORTS.CLEAR_ALL: {
        newState.reports = {};
        break;
    }

    default:
        break;
    }

    return newState;
};

export default Reducer;
