const unwrapper = (data, path) => {
    if (data === undefined) return undefined;
    if (data === null) return null;

    const components = path.split('.');

    const key = components.shift();
    const value = data[key];

    if (value === undefined) {
        return undefined;
    }

    if (typeof value === 'object' && value !== null && components.length > 0) {
        return unwrapper(value, components.join('.'));
    }

    if (components.length === 0) {
        return value;
    }

    return undefined;
};

export default unwrapper;
