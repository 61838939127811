import React, { PureComponent } from 'react';
import {
    Link,
} from 'react-router-dom';
import {
    Row,
    Col,
    Button,
} from 'reactstrap';
import {
    TachometerIcon,
    ArrowRightIcon,
} from 'react-line-awesome';

import MeasureLogo from '../../general/measure_logo';

import './style_navbar.sass';

class Navbar extends PureComponent {
    render() {
        return (
            <Row className="mea-navbar">
                <Col>
                    <MeasureLogo />
                </Col>
                <Col className="text-right">
                    <Link to="/login" className="mea-navbar-button mea-hidden-xs">
                        Mis sensores&nbsp;<ArrowRightIcon />
                    </Link>
                    <Button color="primary" outline tag={Link} to="/login" className="d-md-none mea-navbar-button">
                        <TachometerIcon />
                    </Button>
                </Col>
            </Row>
        );
    }
}

export default Navbar;
