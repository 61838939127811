import TYPES from './types';

export const dispatchContactUpdate = (dispatch, contactId, data) => {
    dispatch({
        type: TYPES.SET,
        payload: {
            contactId,
            contact: data,
        },
    });
};

export const dispatchContactSubscriptionUpdate = (dispatch, contactId, subscribed) => {
    dispatch({
        type: TYPES.UPDATE_SUBSCRIPTION,
        payload: {
            contactId,
            subscribed,
        },
    });
};

export const dispatchContactClearAll = (dispatch) => {
    dispatch({
        type: TYPES.CLEAR_ALL,
    });
};
